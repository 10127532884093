/* eslint-disable */

import type { SignUpLang } from "../types/SignUpLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function SignUpLangDef(): SignUpLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AccountantSignUp_ThisAccountExistsAlready: "Det här kontot existerar redan",
    AccountantSignUp_UserExists_Preamble: "Du har redan ett konto hos Bokio. Försökte du komma till det eller vill du skapa din redovisningsbyrå med existerande konto?",
    AddYourCompanyToBokio: "Lägg till ditt företag i Bokio",
    ApproveTerms: "Godkänn villkor",
    CashMethod: "Kontantmetoden",
    ChoosePasswordScreen_Preamble: "Välj nu ett starkt lösenord till ditt nya konto:",
    ChoosePasswordScreen_Title: "Tack {0}, din e-post är verifierad!",
    CompanyNameOrOrgNumber: "Företagsnamn eller org. nummer",
    ConfirmEmailScreen_ActivationLinkText: "Aktivera konto",
    ConfirmEmailScreen_CheckYourMail: "Kolla din mejl!",
    ConfirmEmailScreen_CheckYourMailInstructions: "Vi har skickat ett mail till {0}. Öppna det och klicka på länken {1} för att bekräfta din e-post och gå vidare.",
    ConfirmEmailScreen_HaventGotConfirmationMail: "Har du inte fått något mail?",
    ConfirmEmailScreen_OrEnterTheFourDigitCodeInTheSameMail: "Alternativt ange den fyrsiffriga koden i samma mail:",
    ConfirmEmailScreen_SendAgainOrContactSupport: "{Skicka igen}",
    CreateCompany_TestCompany_InlineLabel: "(Testbolag)",
    CustomizeBokioForYourCompany: "Anpassa Bokio för ditt företag",
    CustomizeCompanySettingsScreen_NeedHelpAnswer: "Behöver du hjälp att svara?",
    CustomizeCompanySettingsScreen_NeedHelpAnswer_Link2: "https://www.bokio.se/hjalp/komma-igang/bokforing/hur-vet-jag-vilket-rakenskapsar-jag-har/",
    CustomizeCompanySettingsScreen_NotSureWhichBookkeepingMethod: "Osäker på vilken bokföringsmetod du använder?",
    CustomizeCompanySettingsScreen_NotSureWhichBookkeepingMethod_Link2: "https://www.bokio.se/hjalp/komma-igang/bokforing/hur-vet-jag-vilken-bokforingsmetod-redovisningsmetod-jag-har/",
    CustomizeCompanySettingsScreen_NotSureWhichVATPeriod: "Osäker på vilken momsperiod du har?",
    CustomizeCompanySettingsScreen_NotSureWhichVATPeriod_Link2: "https://www.bokio.se/hjalp/komma-igang/bokforing/hur-vet-jag-hur-ofta-jag-ska-deklarera-moms/",
    CustomizeCompanySettingsScreen_Preamble: "Du hittar företagsuppgifterna på {verksamt.se}. Du kan ändra det senare om du behöver.",
    CustomizeCompanySettingsScreen_PreambleLink: "https://www.verksamt.se/minasidor/-/minasidor/foretag/{0}",
    CustomizeCompanySettingsScreen_SelectBookkeepingMethod: "Välj bokföringsmetod",
    CustomizeCompanySettingsScreen_SelectVatPeriod: "Välj momsperiod",
    Error_EmailAlreadyConfirmed: "E-postadressen har redan aktiverats.",
    Error_IncorrectConfirmationCode: "Vi kunde inte använda koden. Vänligen kontrollera att koden stämmer och att den inte använts tidigare.",
    Error_PasswordAlreadySet: "Lösenordet har redan angivits",
    Error_UserMustBeLoggedIn: "Du måste vara inloggad",
    ErrorPage_ClickHereToRestart_Action: "Klicka här för att försöka igen",
    ErrorPage_PageTitle: "Hoppsan!",
    ErrorPage_Preamble: "Vi ber om ursäkt, något oväntat har inträffat. Vi har loggat felet och jobbar på att förbättra oss!",
    FakeCompanyName: "The test company",
    FiscalYearValidation_InTheFuture_Warning: "Startdatum måste vara före dagens datum",
    FiscalYearValidation_InThePast_Warning: "Slutdatum måste vara efter dagens datum",
    FiscalYearValidation_NotSet: "Räkenskapsåret är inte inställt",
    ForgotPassword_Preamble: "Ingen fara, det händer för alla ibland. Ange din e-post så skickar vi en länk för att återställa.",
    ForgotPassword_SendLink: "Skicka länken",
    ForgotPassword_Title: "Glömt lösenord?",
    GovernmentCompanyInfoUrl: "https://verksamt.se",
    HasInvitedYouToBokio: "{inviter} har bjudit in dig till Bokio. Aktivera ditt konto genom att fylla i namn och lösenord.",
    HowOftenDoYouReportVAT: "Hur ofta redovisar du moms?",
    Idle_LoginAgainText: "Du har blivit utloggad, logga in igen.",
    ImportCompanyFromSEPage_HelpText: "Importera en SE fil med din bokföring. {Klicka här för att läsa mer om importering av SE filer}",
    ImportCompanyFromSEPage_ReconsiliationAccruals_CheckBoxText: "Jag har färdigställt all bokföring inkl. avstämningar, eventuella periodiseringar och avskrivningar.",
    ImportCompanyFromSEPage_ReconsiliationAccruals_HelpLinkText: "{Behöver du hjälp att svara?}",
    ImportCompanyFromSEPage_Title: "Importera din tidigare bokföring",
    InitialFiscalYearInput_CompanyHasMadeAtLeastOneFinancialStatement: "Ja, företaget har gjort minst ett bokslut",
    InitialFiscalYearInput_CompanyIsNewlyFounded: "Nej, företaget är nystartat",
    InitialFiscalYearInput_Label: "Har ditt företag gjort sitt första bokslut?",
    InvoiceMethod: "Fakturametoden",
    Login_BankIdButton: "Logga in med BankID",
    Login_BankIdDescription: "Logga in med BankID för att gå vidare",
    Login_BankIdLoginAlreadyConfigured: "BankID är redan konfigurerat",
    Login_BankIDSessionAlreadyInProgress: "En BankID-inloggning pågår redan",
    Login_BankIDUserAborted: "Inloggningen avbröts",
    Login_ConnectAgency: "Anslut befintligt konto till vår lösning för redovisningsbyråer",
    Login_EmailDescription: "Logga in till Bokio genom att ange din e-postadress",
    Login_EmailDescriptionAgency: "Logga in för att börja använda Bokio för redovisningsbyråer och redovisningskonsulter.",
    Login_LoginHeading: "Logga in till ditt konto",
    Login_LoginWithBankId: "Logga in med BankID",
    Login_MirLoginNotConfigured: "BankID inte konfigurerat",
    Login_NotAValidPersonalNumber: "Personnumret är inte giltigt",
    Login_NotHaveAnAccount: "Har du inget konto? {Skapa konto}",
    Login_PasswordDescription: "Ange ditt lösenord för att gå vidare",
    Login_PersonalNumberNotAttachedToAccount: "Personnummret matchar inte kontot",
    Login_Refresh_Title: "Förnya din inloggning",
    Login_Text: "Logga in med e-post och lösenord.",
    Login_Title: "Välkommen!",
    Login_TOTP_Text: "Ange en verifikationskod för att slutföra inloggningen.",
    Login_UnableToAuthenticateWithBankID: "Det gick inte att logga in med BankID. Vänligen kontrollera personnummret och försök strax igen.\nKontakta support om problemet kvarstår!",
    Monthly: "Månadsvis",
    NavigateWarning: "Är du säker på att du vill lämna sidan? Du kanske inte kan slutföra ditt skapande av konto.",
    NoCompaniesForUserReason: "För att börja använda Bokio måste du först skapa ett företag i systemet - ett riktigt eller ett fiktivt för test.",
    OneTimePerYear: "En gång per år",
    PasswordField_Placeholder: "Minst {0} tecken",
    PasswordValidation_Accepted: "Godkänt",
    PasswordValidation_NotSafe_Warning: "Lösenordet hittades i en dataläcka från en annan hemsida. För att hålla ditt konto säkert föreslår vi att du väljer ett annat lösenord.",
    PasswordValidation_Perfect: "Perfekt!",
    PasswordValidation_TooShort: "För kort",
    PasswordValidation_Weak: "Svagt!",
    PickCompanyPage_Experiment_Continue: "Hämta företagsinformation",
    PickCompanyPage_Experiment_DontHaveAnyCompany: "Har du inte ett företag än? Skapa ett testbolag",
    PickCompanyPage_Experiment_FakeDetailsTitle: "Välj typ av testbolag",
    PickCompanyPage_Experiment_FakeDetailsTitlePreamle: "Vi kommer förifylla resten av informationen så att du kan komma igång och testa snabbare. Vill du anpassa testföretaget mer kan du senare göra det under Inställningar.",
    PickCompanyPage_Experiment_FindCompany: "Hitta företag",
    PickCompanyPage_Experiment_ManySearchResultsTips: "- Företagsnamnet kan vara felstavat och/eller matchar inte Bolagsverkets register. Testa att söka på ditt organisationsnummer istället.\n- Om företaget är nyregistrerat har uppgifterna troligen inte uppdaterats hos UC än. Vänligen vänta upp till 24 timmar innan du försöker igen. Om du fortfarande inte kan hitta ditt företag efter det, vänligen [kontakta support.](mailto:support@bokio.se)",
    PickCompanyPage_Experiment_SearchForOrgNumberOrName_Label: "Sök efter ditt företag",
    PickCompanyPage_Experiment_SignUpManual_Link: "fortsätta utan att hämta dina uppgifter",
    PickCompanyPage_Experiment_SignUpSearchTips_Heading: "Kunde du inte hitta ditt företag? Inga problem, du kan ",
    PickCompanyPage_Experiment_TipsCouldntFindYourCompany_Heading: "Hittar du inte ditt företag?",
    PickCompanyPage_Experiment_ZeroOrManyTriesSearchResultsTips: "- Företagsnamnet kan vara felstavat och/eller matchar inte Bolagsverkets register. Testa att söka på ditt organisationsnummer istället.\n- Om företaget är nyregistrerat har uppgifterna troligen inte uppdaterats hos UC än. Vänligen vänta upp till 24 timmar innan du försöker igen. Om du fortfarande inte kan hitta ditt företag efter det, vänligen [kontakta support.](mailto:support@bokio.se)",
    PickCompanyPage_Experiment_ZeroSearchResultsFillInManually: "Skriv in dina företagsuppgifter manuellt",
    PickCompanyPage_Experiment_ZeroSearchResultsTips_Heading: "Detta kan bero på:",
    PickCompanyPage_SearchForOrgNumberOrName_Label: "Organisationsnummer eller företagsnamn",
    PickCompanyPage_SearchForOrgNumberOrName_Placeholder: "XXXXXX-XXXX",
    PickCompanyStep_AddCompany: "Lägg till ditt företag",
    PickCompanyStep_Epilogue_Part_One: "Har du inte ett företag än? Använd ",
    PickCompanyStep_Epilogue_Part_Three_Link: "registrera ett företag på verksamt.se",
    PickCompanyStep_Epilogue_Part_Two_Link: "Bokios service för att skapa ett aktiebolag",
    PickCompanyStep_NoCompanyListed: "Hittar du inte ditt företag? Fyll i uppgifterna nedan för att fortsätta.",
    PickCompanyStep_OrganisationIdentifier: "Organisationsnummer eller företagsnamn",
    PickCompanyStep_WeWillFetch: "Vi hämtar dina företagsuppgifter från Bolagsverket.",
    PickType_Agency_CTA: "Skapa kundbolag",
    PickType_AgencyCompanyDescription: "Skapar företaget för en kund. Du kommer kunna bjuda in kunden i sista steget.",
    PickType_AgencyCompanyHeader: "Lägg till en kund",
    PickType_Type_Real_Description: "Detta kommer att skapa ditt företag i Bokio. Du kan senare bjuda in fler partners och anställda till företaget.",
    PickType_Type_Real_Title: "Jag vill skapa mitt riktiga företag i Bokio",
    PickType_Type_Test_Description: "Detta skapar ett testföretag där du kan testa Bokio utan att det påverkar några verkliga skatter eller liknande. När du känner dig nöjd kan du skapa ditt riktiga företag på samma inloggning.",
    PickType_Type_Test_Title: "Jag vill testa Bokio först",
    PickTypePage_PageTitle: "Vad vill du göra med detta företaget?",
    PreStep_ChooseAgency_Description: "Ditt nya kundföretag ska vara kopplat till denna byrå",
    PreStep_ChooseAgency_Placeholder: "Välj byrå",
    PreStep_ChooseAgency_Title: "Vilka av era byråer är detta till för?",
    PreStep_ChooseCompany_Title: "Vilket företag skulle du vilja skapa?",
    PreStep_CreateClient_Multiple_Description: "Skapa ett kundföretag och hantera din kunds bokföring som en del av din byrå i Bokio.",
    PreStep_CreateClient_SingleAgency_Description: "Skapa ett kundföretag och hantera din kunds bokföring som en del av {0} i Bokio.",
    PreStep_CreateClient_Title: "Kundföretag",
    PreStep_CreateRegularCompany_Description: "Skapa ditt eget företag och använd det för att driva ditt företag i Bokio.",
    PreStep_CreateRegularCompany_Title: "Mitt eget företag",
    Quarterly: "Kvartalsvis",
    ResendConfirmationEmailScreen_Action: "Skicka nytt mail!",
    ResendConfirmationEmailScreen_Title: "Verifiera din e-post",
    ResetPassword_ClickHereToResendEmail: "Skicka en ny länk",
    ResetPassword_Error_BrokenLink: "Trasig länk för lösenordsåterställning: återställningskod saknas",
    ResetPassword_Preamble: "Skriv in ditt nya lösenord för att komma igång igen!",
    ResetPassword_Preamble_TwoFactor: "Nu är du nästan klar!",
    ResetPassword_TheLinkYouClickedHasExpired: "Länken som du klickade på är inte längre giltig.",
    ResetPassword_Title: "Återställ lösenord",
    SearchForYourCompany: "Sök efter ditt företag",
    SessionTimedOut_ClickHereToLogin_Action: "Klicka här för att logga in",
    SessionTimedOut_Notice: "Hoppsan! Det verkar som om du har loggats ut.",
    SessionTimedOut_PageTitle: "Hoppsan!",
    SessionTimedOut_Preamble: "Det verkar som om du har loggats ut.",
    SetCompanyScreen_Text: "Du kan hämta det mesta automatiskt genom att söka på ditt företagsnamn",
    Signup_Preamble: "Du binder dig inte till något.",
    Signup_Title: "Skapa konto för att komma igång med Sveriges enklaste bokföringsprogram",
    SignUpAccountant_StartStep_LogInToExistingAccount: "Om du vill skapa en byrå i Bokio med hjälp av ditt befintliga konto, {klicka här}.",
    SignUpAccountant_StartStep_Title: "Skapa gratis konto som redovisningskonsult",
    SignupRejectedLimitedBeta: "We're very sorry, but Bokio is currently not generally available in your region.",
    StartDate: "Startdatum",
    StartScreen_AlreadyHaveAnAccount: "Har du redan ett konto? {Logga in}",
    StartScreen_ApproveTerms_Label: "Jag godkänner de {allmänna villkoren} och {personuppgiftsbiträdesavtalet}",
    StartScreen_Text: "Signa upp dig idag, du binder dig inte till något.",
    StartScreen_Text_Agency: "Du binder dig inte till något.",
    StartScreen_Title: "Skapa gratis konto hos Bokio",
    Step_ChoosePassword: "Välj lösenord",
    Step_Confirm: "Bekräfta e-post",
    Step_SetCompany: "Skapa företag",
    Step_Start: "Skapa konto",
    StepNotice_ChoosePassword: "Lösenord är redan valt för {email}. Gå till {step}",
    StepNotice_Confirm: "{email} är redan verifierad. Gå till {step}",
    StepNotice_Start: "Du är förmodligen utloggad. Gå till {step}",
    Todo_CompanyOnboarding_BeforeYouStart: "Innan du börjar:",
    Todo_CompanyOnboarding_CheckVatPeriod: "Dubbelkolla så att din momsperiod, bokföringsmetod och räkenskapsår stämmer",
    Todo_CompanyOnboarding_CreateNewInvoice: "Skapa ny faktura",
    Todo_CompanyOnboarding_FirstBill: "Ladda upp din första räkning",
    Todo_CompanyOnboarding_FirstEmployee: "Lägg till dig eller någon annan som anställd",
    Todo_CompanyOnboarding_FirstInvoice: "Skicka din första faktura",
    Todo_CompanyOnboarding_FirstPayroll: "Gör din första lönekörning",
    Todo_CompanyOnboarding_FirstTransaction: "Bokför din första transaktion",
    Todo_CompanyOnboarding_GoToEmployees: "Gå till anställda",
    Todo_CompanyOnboarding_GoToFiscalSettings: "Gå till räkenskapsår",
    Todo_CompanyOnboarding_GoToImportBankTransaction: "Gå till importera från bank",
    Todo_CompanyOnboarding_GoToImportBookkeeping: "Gå till importera tidigare bokföring",
    Todo_CompanyOnboarding_GoToIncomingBalances: "Gå till ingående balanser",
    Todo_CompanyOnboarding_GoToLayoutAndLogo: "Gå till layout, logotyp och färg",
    Todo_CompanyOnboarding_GoToStaffAndSalaries: "Gå till personal och löner",
    Todo_CompanyOnboarding_Guide: "Guide:",
    Todo_CompanyOnboarding_GuideBookkeepingLink: "https://www.bokio.se/hjalp/bokforing/att-bokfora-i-bokio/hur-bokfor-man-i-bokio/",
    Todo_CompanyOnboarding_GuideSalariesLink: "https://www.bokio.se/hjalp/lon/skapa-lon/hur-fungerar-bokio-lon/",
    Todo_CompanyOnboarding_GuideSalesLink: "https://www.bokio.se/hjalp/fakturera-kunder/skapa-faktura/hur-skapar-jag-en-faktura/",
    Todo_CompanyOnboarding_GuideSupplierInvoicesLink: "https://www.bokio.se/hjalp/leverantorsfakturor/rakningar/hur-fungerar-leverantorsfakturor/",
    Todo_CompanyOnboarding_ImportBookeeping: "Importera tidigare bokföring",
    Todo_CompanyOnboarding_ImportTransactions: "Importera dina transaktioner från din gamla bank",
    Todo_CompanyOnboarding_LearnAccounting: "Lär dig hur du bokför enkelt med Bokio",
    Todo_CompanyOnboarding_LearnSalaries: "Lär dig hur du jobbar med löner i Bokio",
    Todo_CompanyOnboarding_LearnSales: "Lär dig hur du kan jobba med försäljning i Bokio",
    Todo_CompanyOnboarding_LearnSuppliers: "Lär dig hur du jobbar med leverantörsfakturor och inköp i Bokio",
    Todo_CompanyOnboarding_LogoAndInvoiceLayout: "Ladda upp din logo och justera designen på din faktura",
    Todo_CompanyOnboarding_MatchIncomingBalances: "Kontrollera så att dina ingående balanser stämmer med tidigare bokföring",
    Todo_CompanyOnboarding_OpenGuide: "Läs guide",
    Todo_CompanyOnboarding_UploadReceipt: "Ladda upp kvitto",
    Todo_CompanyOnboarding_UploadSupplierInvoice: "Ladda upp leverantörsfaktura",
    UserExists_CreateAgencyButton_Action: "Skapa redovisningsbyrå",
    UserExists_CreateCompanyButton_Action: "Nej, jag vill skapa ett nytt bolag",
    UserExists_LoginButton_Action: "Ja, jag försökte logga in",
    UserExists_PageTitle: "Försökte du logga in?",
    UserExists_Preamble: "Vi märkte att du redan har ett konto hos Bokio,{&lt;br /&gt;}försökte du att komma till det?",
    Verify_LoginAgainText: "Vi vill bara säkerställa att du är du.",
    VerifyAccountScene_Accountant_Preamble: "Vi behöver bara bekräfta att det verkligen är du först innan vi skapar en ny redovisningsbyrå.",
    VerifyAccountScreen_Action: "Logga in och skapa ett nytt bolag",
    VerifyAccountScreen_Preamble: "Vi behöver bara bekräfta att det verkligen är du först innan vi skapar ett nytt bolag.",
    VerifyAccountScreen_Title: "Okej, inga problem!",
    VerifyCompany_Preamble: "Du kan alltid ändra detta senare under inställningar.",
    VerifyCompany_Title: "Bra, detta var vad vi hittade",
    VerifyCompanyPleaseProvideMoreInformation_Title: "Var vänlig fyll i lite mer information",
    WelcomeTo: "Välkommen till",
    WhatAccountingMethodAndVatSchemeDoYouUse: "What accounting method and VAT scheme does the company use?",
    WhatAccountingMethodDoYouUse: "Vilken redovisningsmetod har företaget?",
    WhatVatSchemeDoYouUse: "What VAT scheme does the company use?",
    WhenDoesYourFiscalYearEnd: "När slutar företagets räkenskapsår?",
  };
}