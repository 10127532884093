/* eslint-disable */

import type { SettingsLang } from "../types/SettingsLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function SettingsLangDef(): SettingsLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AccontorPartnerDescription: "Vill du kunna fokusera mer på ditt bolag och kärnverksamhet och känner att redovisning varken är roligt eller lätt.  Gör som hundratals andra Bokiokunder och lämna över hanteringen av din bokföring till oss och bli en Accountor Partner. ",
    AccountingFormChangeConfirmationTitle: "Bekräfta ändring av redovisningsmetod",
    AccountorPartnerFeatures: "Bokföring av alla inköp och försäljningar\nMomsdeklaration\nArbetsgivardeklaration\nEgen kontaktperson\nRedovisningsekonom sköter all löpande bokföring\nBokföring av lön\nBokslut inkl. bokföring av årets skatt och resultat\nÅrsredovisning (K1 eller K2) och inkomstdeklaration (NE-bilaga eller INK2)",
    AccountPlan_CustomAccounts_Instructions: "Du har konton som Bokio inte stöder. Koppla dem till ett befintligt konto i Bokio för att din bokföring ska bli korrekt. Det ska kopplas till ett konto som hamnar på motsvarande ställe i balans- eller resultaträkningen. För att lättare hitta dessa konton så kan du välja att visa endast egna konton istället för att visa alla konton.",
    AccountPlan_NonExistingAccounts_Action: "Skapa och koppla dessa konton",
    AccountPlan_NonExistingAccounts_Title: "Du har importerat {0} kontonummer som inte finns i Bokios kontoplan",
    AccountPlan_Title: "Kontoplan",
    AddFiscalYearModal_CreateUpcomming_Description: "Ditt nuvarande räkenskapsår går mot sitt slut. Kontrollera att inställningarna nedan även stämmer för nästa år eller var vänlig och justera dessa.",
    AddFiscalYearModal_CreateUpcomming_Header: "Bekräfta dina inställningar",
    AddFiscalYearModal_RedirectText: "För att fortsätta använda Bokio måste du ha ett räkenskapsår för det aktuella datumet.",
    AddFiscalYearModal_SwitchCompanies: "Byt till ett annat bolag",
    AddOns_Accounting_ChangeLegal_Desc: "Nybildning av aktiebolag  med registrering hos Bolags- och Skatteverket, analys och rådgivning.",
    AddOns_Accounting_ChangeLegal_Price: "Från 5 000 kr {exkl. moms}",
    AddOns_Accounting_ChangeLegal_ReadMore: "Läs mer om ombildning till AB",
    AddOns_Accounting_ChangeLegal_Title: "Ombildning till AB",
    AddOns_Accounting_Closure_Desc: "Vi hjälper dig med upprättande av bokslut, flertalet paket baserat på din företagstyp och önskemål.",
    AddOns_Accounting_Closure_Price: "Från 1 380 kr {exkl. moms}",
    AddOns_Accounting_Closure_ReadMore: "Läs mer om bokslut",
    AddOns_Accounting_Closure_Title: "Bokslut",
    AddOns_Accounting_Consul_Desc: "Vi hjälper dig med olika former av konsultation och rådgivning.",
    AddOns_Accounting_Consul_Price: "Från 1 500 kr/timme {exkl. moms}",
    AddOns_Accounting_Consul_PriceForPartner: "Från 1 000 kr/timme {exkl. moms}",
    AddOns_Accounting_Consul_ReadMore: "Läs mer konsultation",
    AddOns_Accounting_Consul_Title: "Konsultation",
    AddOns_Accounting_K10_Desc: "Uträkning av utdelningsutrymme samt upprättande av blankett.",
    AddOns_Accounting_K10_Price: "Från 3 500 kr {exkl. moms}",
    AddOns_Accounting_K10_ReadMore: "Läs mer om K10",
    AddOns_Accounting_K10_Title: "K10 och utdelningsberäkning",
    AddOns_Accounting_PageTitle: "Anlita en konsult",
    AddOns_Accounting_Partner_Price_EF: "Fr. 1099 kr/mån {exkl. moms}",
    AddOns_Business_PageDesc: "Vi står på småföretagarnas sida. Därför gör vi allt vi kan för att du som Bokioanvändare ska få bästa möjliga erbjudande på noga utvalda tjänster. Ta del av företagstjänster som ger dig en bättre möjlighet att effektivisera och utveckla din verksamhet här.",
    AddOns_Business_PageTitle: "Erbjudanden",
    AddOns_Feedback_Button: "Hör av dig till oss",
    AddOns_Feedback_Desc: "Några funderingar kring våra erbjudanden?\nSaknar du någon tjänst?",
    AddOns_Feedback_Modal_Message_Label: "Din feedback",
    AddOns_Feedback_Modal_Message_Placeholder: "Skriv din feedback här",
    AddOns_Feedback_Modal_Title: "Ge oss feedback",
    AddOns_Feedback_Title: "Ge oss feedback",
    AddOns_Offer_Ativo_Desc: "Fakturera dina kunder och få betalt direkt. Med Fakturaköp ökar du snabbt ditt företags likviditet.",
    AddOns_Offer_Ativo_Price: "2,75% av finansierat belopp",
    AddOns_Offer_Ativo_Title: "Fakturaköp – {Factoringgruppen}",
    AddOns_Offer_CircleK_Description: "Sänk era transportkostnader med Circle K. Sveriges största stationsnät med säkra kortlösningar",
    AddOns_Offer_CircleK_Price: "Rabatt på drivmedel och tillbehör",
    AddOns_Offer_CircleK_Title: "Drivmedel – {Circle K}",
    AddOns_Offer_Froda_Description: "Välj inte mellan smidigt och prisvärt. Välj Froda. Sveriges nöjdaste kunder enligt Trustpilot – 5 år i rad",
    AddOns_Offer_Froda_Price: "10% rabatt på lånekostnaden",
    AddOns_Offer_Froda_Title: "Företagslån – {Froda}",
    AddOns_Offer_Hertz_Description: "Sveriges största biluthyrningsföretag. Med en hyrbil från Hertz reser du både säkert och bekvämt",
    AddOns_Offer_Hertz_Price: "10% rabatt",
    AddOns_Offer_Hertz_Title: "Hyrbil – {Hertz Sverige}",
    AddOns_Offer_Lexly_Desc: "Har du full koll på dina avtal? Få rådgivning och hjälp med de avtal som ditt företag behöver.",
    AddOns_Offer_Lexly_Link: "https://lexly.se/bokio?utm_source=bokio\u0026utm_medium=referral\u0026utm_campaign=foretag",
    AddOns_Offer_Lexly_Price: "Kostnadsfri rådgivning och 15% på samtliga avtal",
    AddOns_Offer_Lexly_Title: "Juridisk rådgivning – {lexly}",
    AddOns_Offer_Lyreco_Description: "Nu har du möjlighet att handla kontorsmaterial och mycket mer med upp till 40% rabatt hos Lyreco",
    AddOns_Offer_Lyreco_Price: "Upp till 40% rabatt",
    AddOns_Offer_Lyreco_Title: "Förbrukningsmaterial och kontorsutrustning – {Lyreco}",
    AddOns_Offer_OKQ8_Description: "Få bra rabatter på drivmedel, laddning och tvätt samt tillgång till över 700 stationer.",
    AddOns_Offer_OKQ8_Price: "80 öre rabatt på Neste MY (HVO100)",
    AddOns_Offer_OKQ8_Title: "Drivmedel – {OKQ8}",
    AddOns_Offer_Pensure_Desc: "Få mer pension för pengarna med en digital, enkel och hållbar tjänstepension. Kostnadsfri rådgivning och extra förmånliga pensionslösningar för dig som Bokio-kund.",
    AddOns_Offer_Pensure_Price: "25% på första årets avgift",
    AddOns_Offer_Pensure_Title: "Tjänstepension och hälsoförsäkringar - {Pensure}",
    AddOns_Offer_PocketLaw_Description: "Tillgång till +50 avtalsmallar, digital vägledning, e-signering och säker lagring.",
    AddOns_Offer_PocketLaw_Price: "20% rabatt",
    AddOns_Offer_PocketLaw_Title: "Er digitala bolagsjurist – {PocketLaw}",
    AddOns_Offer_Tag_Active: "Aktiverad",
    AddOns_Offer_Wndy_Description: "Marknadens bästa digitala HR-avdelning. Från 0 kr/mån",
    AddOns_Offer_Wndy_Price: "20% rabatt på prispaket Pro och Premium",
    AddOns_Offer_Wndy_Title: "HR - {Wndy}",
    AddOns_Partner_Button: "Anmäl intresse",
    Agency: "Byrå",
    AgencyBox_Accountor: "Accountor har med sin expertis inom småföretag  samarbetat med Bokio sedan 2019 och hjälper redan hundratals Bokioanvändare. Behöver du hjälp med hela din redovisning eller stöd i vissa delar, såsom skatterådgivning, bolagstjänster och lön. 300 redovisnings- och lönekonsulter finns här för dig!",
    AgencyBox_Accountor_Long: "&lt;p&gt;&lt;b&gt;Hur kan vi hjälpa dig?&lt;/b&gt;&lt;/p&gt;I tjänsten &lt;b&gt;Accountor Partner&lt;/b&gt; kan vi hjälpa dig med hela din redovisning, du kan även få hjälp med Bokslut eller om du har övriga Rådgivningsfrågor. &lt;br /&gt;&lt;b&gt;Accountor Partner&lt;/b&gt;, detta ingår:&lt;ul&gt;&lt;li&gt;Bokföring av alla inköp och försäljningar&lt;/li&gt;&lt;li&gt;Momsdeklaration&lt;/li&gt;&lt;li&gt;Arbetsgivardeklaration&lt;/li&gt;&lt;li&gt;Egen Redovisningsekonom sköter all löpande bokföring&lt;/li&gt;&lt;li&gt;Bokföring av lön&lt;/li&gt;&lt;li&gt;Bokslut inkl. bokföring av årets skatt och resultat&lt;/li&gt;&lt;li&gt;Årsredovisning (K1 eller K2) och inkomstdeklaration (NE-bilaga eller INK2)&lt;/li&gt;&lt;/ul&gt;&lt;h3&gt;Bokslut:&lt;/h3&gt;&lt;p&gt;Aktiebolag eller enskild firma spelar ingen roll, vi hjälper dig med ditt bokslut, årsredovisning och inkomstdeklaration.&lt;/p&gt;&lt;h3&gt;Rådgivningstjänster:&lt;/h3&gt;Söker du i stället hjälp med vissa utvalda delar, nedan är exempel på vad vi kan hjälpa ditt företag med. Beskriv bara i rutan vad du önskar hjälp med.&lt;ul&gt;&lt;li&gt;Utredning och avstämning av ditt bankkonto eller skattekonto&lt;/li&gt;&lt;li&gt;Rådgivning kring specifik hantering av din verksamhet&lt;/li&gt;&lt;li&gt;Skatterådgivning&lt;/li&gt;&lt;li&gt;Bolagsfrågor&lt;/li&gt;&lt;li&gt;Ombildning från Enskild firma till Aktiebolag&lt;/li&gt;&lt;li&gt;Resultatplanering&lt;/li&gt;&lt;li&gt;K10&lt;/li&gt;&lt;/ul&gt;\n",
    AgencyBox_ButtonText: "Begär en offert",
    AgencyBox_DeskJockeys: "Deskjockeys är experter på att lösa dina bekymmer med skatter, bokföring och annat som får dig att ligga sömnlös. Vi gör en grundlig genomgång av dina problem med hjälp av auktoriserad revisor, skattejurist och människokännare och lämnar sedan ett förslag på lösning.\n\nVäljer du oss får du alltid ett fast pris!",
    AgencyBox_DeskJockeys_Long: "Deskjockeys är en modern och digitaliserad redovisningsbyrå och vi finns på flera ställen i Sverige. Vi vill vara ett stöd för dig, inte bara när det gäller ekonomin och redovisningen utan även på den administrativa sidan. Tillsammans identifierar vi era utmaningar och skapar en plan för att lösa dessa! \nFör dig som driver företag utan anställda har vi ett introduktionserbjudande som passar dig som vill göra en del själv, få hjälp med att komma vidare när du har kört fast och få hjälp med mer avancerade frågor. Vi jobbar enbart med fasta priser.\nDet du gör själv:&lt;ul&gt;&lt;li&gt;Fakturerar dina kunder&lt;/li&gt;&lt;li&gt;Fotar kvitton och skicka till systemet&lt;/li&gt;&lt;li&gt;Ansvarar för att betala bolagets leverantörsfakturor samt annat som ska utbetalas från bolagets bankkonto.&lt;/li&gt;&lt;/ul&gt;Det Deskjockeys gör:&lt;ul&gt;&lt;li&gt; Hjälper till med bokföring och rapportering&lt;/li&gt;&lt;li&gt;Momsredovisning&lt;/li&gt;&lt;li&gt;Lön&lt;/li&gt;&lt;li&gt;Inkomstdeklaration&lt;/li&gt;&lt;li&gt;Årsbokslut&lt;/li&gt;&lt;li&gt;Årsredovisning&lt;/li&gt;&lt;li&gt; Rådgivning gällande resultatdisposition och utdelning&lt;/li&gt;&lt;/ul&gt;Exempel på annan rådgivning som vi erbjuder:&lt;ul&gt;&lt;li&gt;Pensionsplanering\n&lt;/li&gt;&lt;li&gt;Skatteoptimering&lt;/li&gt;&lt;/ul&gt; Välkommen med din förfrågan.",
    AgencyBox_EasyCount_Long: "Easycount är en proaktiv digital redovisnings- och revisionsbyrå som arbetar med fastprislösningar för att göra det ännu enklare att vara företagare. Hos oss kan du få hjälp med mer eller mindre allt från att starta ditt företag fram till avveckling. Välj enkelt mellan en del eller helhetslösning oavsett det gäller bokföring, lön, bokslut eller support. Självklart erbjuder vi dig även relevant och värdeskapande rådgivning. Vi kan även erbjuda dig som växer möjlighet till revision.\n\nVi utmärker oss genom att vara snabbfotade, personliga, engagerade och har som absolut ledstjärna att vara en leverantör som gör skillnaden på sista raden. Välkommen att prova Easycount du med - till fast pris!",
    AgencyBox_EasyCount_Short: "Easycount är en proaktiv digital redovisnings- och revisionsbyrå som arbetar med fastprislösningar för att göra det ännu enklare att vara företagare. Hos oss kan du få hjälp med mer eller mindre allt från att starta ditt företag fram till avveckling. Välj enkelt mellan en del eller helhetslösning oavsett det gäller bokföring, lön, bokslut eller support. Självklart erbjuder vi dig även relevant och värdeskapande rådgivning. Vi kan även erbjuda dig som växer möjlighet till revision.",
    AgencyBox_OneEconomy_Long: "Det ska vara roligt att driva eget! Därför ger vi dig det stöd du behöver, såväl löpande som enstaka frågor, så att du kan fokusera på din kärnverksamhet.\n\n Vi på UCS One Business hjälper stora och små företag med allt inom ekonomi, lön, HR och affärssystem. Sedan 2021 har vi haft ett nära samarbete med Bokio. Tillsammans med dig som kund ser vi till att arbetet blir så enkelt och effektivt som möjligt. Det innebär att vi är flexibla och skräddarsyr lösningar för just ditt företag, så att du ska kunna göra det du gillar mest.\n\n",
    AgencyBox_OneEconomy_Short: "UCS One Business erbjuder allt stöd ditt företag behöver med flexibla uppdrag och paket, till bra priser. Med 100-tals hjälpta kunder och nära samarbete med Bokio sedan 2021 har vi blivit Bokio-experter, så att du med vår erfarenhet kan känna dig trygg. Vi värderar personlig kontakt och enkelhet. Det viktigaste är att det ska vara roligt att driva eget!",
    AgencyBox_Studac_Long: "Spara tid och pengar med Studac och få redovisningshjälp av en ekonomistudent till en bråkdel av den genomsnittliga kostnaden hos en traditionell redovisningsbyrå.\n\nStudac erbjuder rådgivningstjänster, skräddarsydda bokföringskurser samt helhetslösningar som hjälper dig att fokusera på din verksamhet medan vi hjälper till med redovisningen. Vi anpassar våra tjänster efter dina behov och gör en skräddarsydd lösning som passar din verksamhet.\n\nPå Studac är vi stolta över att erbjuda en unik möjlighet för studenter att få praktisk erfarenhet och handledning samtidigt som de hjälper andra företag. Vi tror att vår modell är en win-win-situation för alla: studenter får värdefull erfarenhet, företag får professionell hjälp till kostnadseffektiva priser.",
    AgencyBox_Studac_Short: "Studac erbjuder kostnadseffektiv redovisningshjälp av skickliga ekonomistudenter för små och medelstora företag. Vi anpassar våra tjänster helt efter dina behov och skapar en win-win-situation där studenter får värdefull erfarenhet och företag får professionell hjälp till bra priser.",
    AgencyBox_WinWin_Long: "Vi tror på dig som företagare och att du kan sköta stora delar av redovisningen själv i Bokio, men när det strular ska du ha någon att hålla i handen. Vi vänder oss främst till dig som driver aktiebolag med konsultverksamhet och vill ha koll på ekonomin i ditt företag. Vi erbjuder allt du behöver från skatterådgivning, boksluts -och deklarationshjälp till enklare support. Vi kör alltid fasta priser och digitala processer tillsammans med personligt engagemang. Kontakta oss om du vill jobba med en Framtidsbyrå.",
    AgencyBox_WinWin_Short: "Vi tror på dig som företagare och att du kan sköta stora delar av redovisningen själv i Bokio, men när det strular ska du ha någon att hålla i handen. Vi vänder oss främst till dig som driver aktiebolag med konsultverksamhet och vill ha koll på ekonomin i ditt företag. Vi erbjuder allt du behöver från skatterådgivning, boksluts -och deklarationshjälp till enklare support. Vi kör alltid fasta priser och digitala processer tillsammans med personligt engagemang. Kontakta oss om du vill jobba med en Framtidsbyrå.",
    AgencyInvite_NoInviteToShow_heading: "Ingen inbjudan att visa",
    AgencyInvite_NoInviteToShow_message: "Ange din redovisningskonsults mejladress",
    AgencyInvite_SuggestedPermissions_description: "Välj vilka behörigheter byrån ska ha. Du kan alltid ändra dem senare.",
    AgencyInvite_SuggestedPermissions_heading: "Föreslagna behörigheter",
    AreYouSure: "Är du säker?",
    AuditHistory_Download_ItemsInDownload: "Objekt {0} - {1}",
    AuditHistory_Download_ModalExplanation: "Här kan du ladda ner din behandlingshistorik. Varje fil innehåller maximalt {0} objekt av historiken.",
    AuditHistory_Download_ModalTitle: "Ladda ner behandlingshistorik",
    AuditHistory_Download_TableHeading: "Inkluderade objekt i behandlingshistorik",
    AuditHistory_EventType: "Ändring",
    AuditHistory_Ip: "IP-adress",
    AuditHistory_ItemName: "Ändring av",
    AuditHistory_Title: "Behandlingshistorik",
    AuditHistory_User: "Användare",
    AutomaticChecking_Description: "Automatisk bokföring av fakturahändelser",
    AutomaticCheckingSettings_Description: "Aktivera automatisk bokföring av fakturahändelser. Detta berör publicering av fakturor, registrering av betalningar och fakturaregleringar.",
    AutomaticCheckingSettings_Heading: "Automatisk bokföring",
    BackofficeNotification_Description: "När du aktiverar en kategori kommer du att få notiser när du loggar in på Bokio.&lt;br/&gt;Utöver det kan du också välja våra e-postnotiser.",
    BankId_Activate: "Aktivera BankID",
    BankId_Activate_ConfirmKnowsEffect: "Jag förstår att när jag har aktiverat BankID, kommer jag bara kunna logga in med BankID.",
    BankId_Activate_ConfirmOnlyOneWithAccess: "Endast jag har tillgång till {0}",
    BankId_Activate_ConfirmOwnerOfAccount: "Användarkontot för {0} tillhör mig.",
    BankId_Activate_CTA: "Aktivera",
    BankId_Activate_IncorrectIfOtherAccount: "Tänk på att endast aktivera BankID med ditt personnummer på ditt egna konto, eftersom det endast är du som kommer kunna logga in!",
    BankId_Activate_Title: "Aktivera BankID-autentisering",
    BankId_Activate_With2FA_Text: "För att aktivera BankID-autentisering, fyll i ditt lösenord och verifikationskoden (från din autenticator-app).",
    BankId_Activate_WithPasword_Text: "För att aktivera BankID-autentisering, fyll i ditt lösenord.",
    BankId_RequiredMessage: "Det här företaget har ett Bokio Företagskonto och därför måste alla användare logga in med BankID. Detta för att säkerställa att företagets bankinformation hanteras på ett säkert sätt. Vänligen aktivera BankID för att kunna komma åt detta företag.",
    BankId_Text: "Genom att aktivera BankID-autentisering blir ditt konto mycket säkrare. När du loggar in kommer du använda BankID i stället för din gamla inloggning.",
    BankId_Title: "BankID-autentisering",
    BankId_Title_Activated: "Ditt konto är skyddat med BankID",
    Billing: "Hantera prenumeration",
    Billing_CardPaymentTermsRefresher: "Prenumerationskostnaden betalas i förskott, medan rörliga kostnader betalas i efterskott och dessa betalas månadsvis. Vi kommer automatiskt förnya din prenumeration.",
    Billing_FailedPayments_DidNotRetryReason: "Försök igen för att testa denna betalningen",
    Billing_FailedPayments_DidNotTry: "Försökte inte",
    Billing_FailedPayments_InfoText: "Se till att du har tillräckligt med saldo på ditt betalkonto. Om du använder Bokio Företagskonto som betalmetod kan du [kontrollera ditt saldo](bokio:/companyid/bank/bankinformation) eller [aktivera Bokiokortet](bokio:/companyid/bank/cards).",
    Billing_FailedPayments_InfoText_NonBBA: "Se till att du har tillräckligt med saldo på ditt betalkonto.",
    Billing_FailedPayments_Require3DS: "Kräver 3DSecure autentisering",
    Billing_FailedPayments_RetryNow: "Försök betala igen",
    Billing_FailedPayments_SlowLoad: "Att försöka genomföra betalningarna kan ta upp till en minut. Tack för tålamodet.",
    Billing_FailedPayments_Title: "Dessa betalningar har misslyckats:",
    Billing_FailedPayments_TooSoonText: "Vi kunde inte försöka utföra betalningar just nu. Vänta minst 30 sekunder mellan försöken.",
    Billing_MyPlan: "Min plan",
    Billing_PageTitle: "Prenumeration och övriga kostnader",
    Billing_SeeReason: "Se orsak",
    Billing_Settings_UpdatePaymentCard: "Uppdatera betalkort",
    Billing_SubText_Text: "Se och hantera företagets prenumeration, betalningsmetod, kvitton och kostnader för tillvalda tjänster",
    BillingFailedPayments_RetryNow: "Försök betala igen",
    Campaign_Banner_GenericYearly50Percent_Badge: "Tidsbegränsat erbjudande",
    Campaign_Banner_GenericYearly50Percent_CTA: "Använd din rabatt",
    Campaign_Banner_GenericYearly50Percent_Heading: "Passa på! Uppgradera inom {0}",
    Campaign_Banner_GenericYearly50Percent_Text: "Behåll bokföringsmallar, proffsiga kundfakturor och mycket mer.\nUppgradera senast {0} och få 50% rabatt på din årsplan.",
    Campaign_Banner_TrialConversion_Badge: "Tidsbegränsat erbjudande",
    Campaign_Banner_TrialConversion_CTA: "Välj en betalplan",
    Campaign_Banner_TrialConversion_Heading: "Passa på! Uppgradera inom {0}",
    Campaign_Banner_TrialConversion_Text: "Behåll alla funktioner i Bokio när din provperiod avslutas.\nUppgradera senast **{0}** och få upp till 50% rabatt på våra planer. ",
    Campaign_Banner_TrialConversion2_Badge: "Tidsbegränsat erbjudande",
    Campaign_Banner_TrialConversion2_CTA: "Uppgradera",
    Campaign_Banner_TrialConversion2_Heading: "Uppgradera innan {0} och få 40% rabatt",
    Campaign_Banner_TrialConversion2_Text: "Din provperiod är snart slut. Uppgradera nu och få upp till 40% rabatt på Balans eller Business.",
    Campaign_Banner_TrialConversionYearly50_Badge: "Tidsbegränsat erbjudande",
    Campaign_Banner_TrialConversionYearly50_CTA: "Välj din plan",
    Campaign_Banner_TrialConversionYearly50_Heading: "Passa på! Uppgradera inom {0}",
    Campaign_Banner_TrialConversionYearly50_Text: "Behåll alla funktioner i Bokio när din provperiod avslutas. Uppgradera senast {0} och få 50% rabatt när du köper en årsplan. ",
    ChangeCompanySystem_TestToReal_Confirm_Label: "Jag har läst och förstått att mitt testföretag kommer konverteras till ett riktigt företag.",
    ChangeCompanySystem_TestToReal_Menu_Subtext: "Konvertera ditt testföretag till ett riktigt företag för att kunna använda alla tillgängliga funktioner",
    ChangeCompanySystem_TestToReal_Title: "Konvertera till ett riktigt företag",
    ChangeCompanySystem_TestToReal_Warning: "Var medveten om att **all** data kommer finnas med. Om mycket testdata behöver tas bort rekommenderar vi att ett nytt företag skapas i stället.",
    ChangeCompanySystem_TestToReal_Warning_Title: "Du är på väg att konvertera det här testföretaget till ett riktigt företag.",
    ChangePricePlanError_Text: "Försök igen eller kontakta [support@bokio.se](mailto:support@bokio.se)",
    ChangePricePlanError_Title: "Ett fel uppstod vid försöket att uppdatera prisplanen",
    ClientRequests_CancelRequest: "Avbryt förfrågning",
    ClientRequests_PendingRequest: "Avvaktande förfrågning",
    COA_ConnectedTo: "Kopierar inställningar från konto",
    COA_CopyAccount: "Kopiera konto",
    COA_CreateNewAccount: "Skapa nytt konto",
    coa_CustomAccountOverlappingBasePlan: "Kontot finns i Bokios kontodefinition",
    coa_CustomAccountsOverlappingBasePlan: "Du har skapat egna kontoegenskaper för ett konto som finns i Bokios kontoplan. Du kan jämföra egenskaperna genom att trycka på Bokios kontoegenskaper nedan.",
    coa_CustomAccountUnmapped: "Kontot saknar mappning mot Bokios kontodefinition",
    COA_filter_All: "Visa alla konton",
    COA_filter_Custom: "Visa endast egna konton",
    COA_NewModal_AccountDetails: "Kontoinformation",
    COA_NewModal_AccountLabel: "Nytt kontonummer",
    COA_NewModal_AccountProperties: "Kontoegenskaper",
    COA_NewModal_ConnectLabel: "Koppla detta kontot till",
    COA_NewModal_InvalidAccount: "Det finns inga tillgängliga egna konton för det valda kontot",
    COA_NewModal_NameLabel: "Kontonamn",
    COA_NewModal_Title: "Skapa nytt konto",
    COA_RenameAccount: "Byt namn",
    COA_ViewModal_AccountNumber: "Kontonummer",
    Company_PageTitle: "Kontaktuppgifter och företagstyp",
    CompanyColor_ChangeColor_action: "Ändra färg ",
    CompanyColor_Heading_SubText: "Färgen används för att anpassa PDF-filer för fakturor, offerter och löner med ditt företags färg",
    CompanyDataStorage_Option_No: "Nej, radera data",
    CompanyDataStorage_Option_Yes: "Ja, lagra data",
    CompanyDataStorage_Title: "Lagra din data i Bokio",
    CompanyInformation_heading: "Företagsinformation ",
    CompanyOwner_Error_NonOwnerTryingToAssignOwnership: "Endast ägaren till Bokioföretaget kan överföra ägandeskapet.",
    CompanyOwnerInvite_SuggestedPermissions_description: "Välj vilka behörigheter företags ägaren ska börja med.",
    CompanyVacationSettings_BalanceTracking: "Automatisk semesterbalans",
    CompanyVacationSettings_BalanceTracking_Description: "Genom att slå på automatisk semesterbalans kommer vi automatiskt hålla koll på dina anställdas semesterbalans. Vi kommer också automatiskt bokföra semesterskulder så att du får en bättre överblick över hur mycket du är skyldig dina anställda. [Läs mer om semesterbalans på vår hjälpsida](https://www.bokio.se/hjalp/lon/semester-sjukfranvaro/sla-pa-automatiska-semesterbalanser/)",
    CompanyVacationSettings_BalanceTrackingOff: "Automatisk semesterbalans av",
    CompanyVacationSettings_BalanceTrackingOn: "Automatisk semesterbalans på",
    CompanyVacationSettings_Title: "Semesterinställningar",
    CompanyVacationSettings_VacationYearStartDate: "Semesterårsskifte",
    CompanyVacationSettings_VacationYearStartDate_Description: "Semesterårsskiftet är när vi uppdaterar alla anställdas semesterbalansräkning. Det vanligaste semesteråret är från 1 april till 31 mars. Vissa företag har från 1 januari, men det är inte lika vanligt.",
    ConfigureEInvoice: "Konfigurera e-faktura",
    ConfirmCompanyDetailsModal_ConfirmButton: "Bekräfta företagsuppgifter",
    ConfirmCompanyDetailsModal_Preamble_NoOrgNumber: "Du har inte angett ett giltigt organisationsnummer. Du behöver bekräfta ditt organisationsnummer för att kunna fortsätta.",
    ConfirmCompanyDetailsModal_Preamble_NoSearchResults: "Vi kunde inte hämta in dina företagsuppgifter. Du behöver bekräfta ditt organisationsnummer för att kunna fortsätta.",
    ConfirmCompanyDetailsModal_Title: "Bekräfta dina företagsuppgifter",
    CurrentSubscription_ChangePlan_Info_Title: "Vid ändring av prisplan",
    CustomAccounts_Header_ConnectedTo: "Kopplad till",
    CustomAccounts_MapAccounts_Info: "När du importerar tidigare bokföring kan dina filer innehålla kontonummer som Bokio inte stöder. Du måste koppla dessa konton till konton som Bokio stöder. [Läs mer.]({0})",
    CustomAccounts_MustConnectToBasPlanAccount: "{0}: \"Kopplad till\" måste sättas till ett existerande konto i Bas15",
    CustomAccounts_NoCustomAccounts_Info: "Du har inte några konton att koppla!",
    DeleteCompany_AdditionalFeedback_label: "Feedback till Bokio",
    DeleteCompany_AdditionalFeedback_placeholder: "Vänligen ägna en stund på att ge oss feedback på hur vi kan bli bättre.",
    DeleteCompany_BeforeYouDelete_Error: "Innan du kan radera företaget behöver du lösa problemen nedanför.",
    DeleteCompany_Checkbox_IAgreeToLetBokioDelete: "Jag godkänner att Bokio raderar företaget  {0} ({1})",
    DeleteCompany_Checkbox_IUnderstandIWillNotAccess: "Jag förstår att jag inte kommer kunna komma åt mitt företag  {0} ({1}) i Bokio igen",
    DeleteCompany_ConfirmModal_Action: "Radera {0}",
    DeleteCompany_ConfirmModal_Description1: "Du är på väg att radera {0}, är du säker på att du vill fortsätta?",
    DeleteCompany_ConfirmModal_Description2: "Denna åtgärd kan inte ångras!",
    DeleteCompany_DeleteWarning: "Vänligen notera att det är inte möjligt att ångra borttagning av ditt företag!",
    DeleteCompany_FeedbackDelete_Heading: "Vad är anledningen till att du raderar företaget?",
    DeleteCompany_FeedbackDelete_Paragraph: "Vi är ledsna över att du lämnar men skulle vilja förstår varför du raderar företaget, så vi kan skapa en ännu bättre tjänst för någon av dina framtida företag. Vänligen ge oss feedback.",
    DeleteCompany_heading: "Ta bort företag",
    DeleteCompany_Section1_Heading1: "Vad innebär det att radera företaget i Bokio?",
    DeleteCompany_Section1_Paragraph1: "När du raderar företaget {0} så kommer ingen ha tillgång till företaget igen. All data i företaget kommer raderas.\n\nDitt personliga konto {1} kommer inte att bli borttaget, så du kommer fortfarande ha tillgång till andra företag i Bokio.",
    DeleteCompanyRestriction_ActiveSubscriptions_Header: "Ditt företag har aktiva kopplingar",
    DeleteCompanyRestriction_ActiveSubscriptions_Message: "Innan vi raderar detta företag så behöver du säga upp {0}. Vänligen kontakta support för hjälp.",
    DeleteCompanyRestriction_BokioBusinessAccountActive_Header: "Ditt företag har Bokio Företagskonto",
    DeleteCompanyRestriction_BokioBusinessAccountActive_Message: "Innan du tar bort ditt företag så behöver du avsluta ditt Bokio Företagskonto",
    DeleteCompanyRestriction_BokioBusinessAccountActive_OtherUser_Message: "Innan du tar bort ditt företag så behöver användaren som startade Bokio Företagskonto avsluta Bokio Företagskonto",
    DeleteCompanyRestriction_BokioBusinessAccountActiveOnboarding_Header: "Du har en pågående aktivering av Bokio Företagskonto",
    DeleteCompanyRestriction_BokioBusinessAccountActiveOnboarding_Message: "Innan du tar bort ditt företag så behöver du avsluta din aktivering av Bokio Företagskonto",
    DeleteCompanyRestriction_BokioPlan_Header: "Ditt företag har en betald plan eller ofakturerade kostnader",
    DeleteCompanyRestriction_BokioPlan_Message: "Innan vi raderar detta företag så behöver du säga upp din betalda plan. Du får inte heller ha några obetalda fakturor.\nHar du kostnader denna månaden som inte fakturerats måste du vänta tills dessa blivit fakturerade innan du kan radera bolaget.",
    DeleteCompanyRestriction_MoreUsersConnected_Header: "Ditt företag har fler användare anslutna",
    DeleteCompanyRestriction_MoreUsersConnected_Message: "Du måste ta bort alla andra användare innan du kan radera företaget.",
    DeleteCompanyRestriction_Services_EInvoices: "E-faktura",
    DeleteCompanyRestriction_Services_Factoring: "Fakturaköp",
    DeleteCompanyRestriction_Services_Plaid: "Plaid",
    DeleteCompanyRestriction_Services_PrioSupport: "Prioriterad support",
    DeleteCompanyRestriction_Services_Stripe: "Stripe",
    DeleteCompanyRestriction_UnpaidSentInvoices_Header: "Ditt företag har obetalda skickade fakturor",
    DeleteCompanyRestriction_UnpaidSentInvoices_Message: "Vi upptäckte att du har obetalda fakturor där betalningsmetoden är satt till Bokio Företagskonto. Du behöver antingen avbryta faktorurna eller vänta tills de har blivit betalda innan du kan radera ditt företag.",
    DeleteFiscalYearInfo_HasNonZeroIncomingBalance: "Detta räkenskapsår har ett inkommande saldo som inte är noll",
    DeleteFiscalYearInfo_HasVerifications: "Det finns verifikat i detta räkenskapsår",
    DeleteFiscalYearInfo_IsCurrentFiscalYear: "Du har bara ett räkenskapsår",
    DeleteFiscalYearInfo_IsNotFirstFiscalYear: "Det här är det aktuella räkenskapsåret",
    DeleteFiscalYearInfo_IsOnlyYear: "Det här är inte ditt första räkenskapsår",
    DeleteFiscalYearInfo_SubTitle: "Du kan inte ta bort detta räkenskapsår på grund av följande skäl:",
    DeleteFiscalYearInfo_Title: "Räkenskapsåret kan inte tas bort.",
    DeleteUserAccount_Checkbox_IUnderstandIWillNotAccess: "Jag förstår att jag inte kommer kunna komma åt mitt användarkonto igen",
    DeleteUserAccount_ConfirmModal_Description1: "Du är på väg att radera ditt användarkonto, är du säker på att du vill fortsätta?",
    DeleteUserAccount_ConfirmModal_Description2: "Denna åtgärd kan inte ångras!",
    DeleteUserAccount_DeleteAction: "Radera mitt konto",
    DeleteUserAccount_DeleteWarning: "Vänligen notera att vi inte kommer radera din personliga data kopplat till t.ex. tidigare lönespecifikationer eller service som du ingått ett separat avtal med.",
    DeleteUserAccount_heading: "Radera mitt användarkonto",
    DeleteUserAccount_Section_Heading: "Vad innebär det att radera kontot i Bokio?",
    DeleteUserAccount_Section_Paragraph: "När du raderar ditt användarkonto så kommer vi radera all personlig data som är kopplat till ditt konto. Detta inkluderar namn, email adress, telefonnummer och personnummer.",
    DeleteUserAccount_UnableToDelete_ConnectedPartner: "Du måste lämna dina partnerföretag innan användarkontot kan raderas.",
    DeleteUserAccount_UnableToDelete_MemberOfCompany: "Du måste lämna eller radera alla dina företag innan användarkontot kan raderas.",
    DisconnectCompany_FeedbackOption_AccountantTakeOver: "En redovisningskonsult kommer ta över min bokföring",
    DisconnectCompany_FeedbackOption_DoesNotSupportNeeds: "Bokio saknar funktioner som mitt företag behöver",
    DisconnectCompany_FeedbackOption_IClosedMyCompany: "Jag har lagt ner mitt företag",
    DisconnectCompany_FeedbackOption_NoLongerActive: "Jag är inte aktiv i företaget längre",
    DisconnectCompany_FeedbackOption_OtherReason: "Annan orsak",
    DisconnectCompany_FeedbackOption_TestCompany: "Det här var ett testföretag",
    EInvoiceSettings_Description: "Aktivera och konfigurera e-faktura. Detta gör det möjligt att skicka e-fakturor till företag som kan ta emot e-fakturor.",
    Export_FailedPaymentCTA: "Visa betalningar",
    Export_FailedPaymentError: "Export är inte tillgängligt medans ditt bolag har misslyckade betalningar.",
    ExportCompany_Action_VerificationsTsv: "Verifikationer (.tsv)",
    ExportCompany_Filename_Receipts: "Kvitton",
    ExportCompany_Filename_ReceiptsByDates: "Kvitton för datum",
    ExportCompany_UnbookkeptRecipts: "Exportera ej bokförda kvitton",
    FeatureToggles: "Beta-funktioner",
    FeatureToggles_EmptyText: "Just nu finns inga Beta-funktioner för ditt bolag",
    FeedbackModal_AdditionalFeedback_Label: "Feedback till Bokio",
    FeedbackModal_AdditionalFeedback_Placeholder: "Ta en stund att ge oss feedback om hur vi kan förbättra oss.",
    FileUploader_Accepted_Multiple: "Accepterade filtyper:",
    FileUploader_Accepted_Single: "Accepterad filtyp:",
    FileUploader_MainText_Desktop: "Dra en fil hit, eller klicka för att bläddra på din enhet",
    FileUploader_MainText_DropOnly: "Dra och släpp dina underlag för att ladda upp dessa",
    FileUploader_MainText_Mobile: "Tryck här för att välja en fil från din enhet",
    FileUploader_WrongFileType: "Fel filtyp",
    FiscalYear_CloseYearAction: "Stäng år",
    FiscalYear_CloseYearText: "Är du helt säker på att du vill stänga räkenskapsåret utan att gå via det vanliga bokslutsförfarandet?",
    FiscalYear_CloseYearTitle: "Stäng räkenskapsår",
    FiscalYear_ConfirmOpenModal_FollowingYears_Text: "Om du öppnar detta räkenskapsår så kommer även kommande stängda räkenskapsår att öppnas",
    FiscalYear_OpenYearAction: "Öppna år",
    FiscalYear_OpenYearTitle: "Öppna räkenskapsår",
    FiscalYears_AccountingFormChangeConfirmationText: "Är du säker på att du vill ändra redovisningsmetod?",
    FiscalYears_AccountingMethod: "Redovisningsmetod",
    FiscalYears_AccountingMethod_Description: "Kontantmetoden kallas ibland bokslutsmetoden. Du kan se vilken metod du har på {verksamt.se}",
    FiscalYears_Create_Title: "Skapa räkenskapsår",
    FiscalYears_CreateFiscalYear_InvalidPermissions: "Ditt räkenskapsår har löpt ut och tyvärr saknar du behörighet för att skapa ett nytt. Var vänlig kontakta någon med rätt behörighet för att fortsätta.",
    FiscalYears_DeleteYearAction: "Ta bort år",
    FiscalYears_DeleteYearText: "Vill du verkligen ta bort detta räkenskapsåret?",
    FiscalYears_DeleteYearTitle: "Ta bort räkenskapsår",
    FiscalYears_IncomingBalancesWarning: "Varning: Om du ändrar startdatum kommer de ingående balanserna att flyttas med.",
    FiscalYears_LockedDates_Explanation: "Datumen kan inte ändras, de genereras automatiskt baserat på ditt tidigare räkenskapsår.",
    FiscalYears_MoveWarning: "Ett antal verifikationer behöver flyttas ut ur eller in i detta räkenskapsår. För att göra denna ändring behöver du {kontakta support}",
    FiscalYears_NoActiveSettings_Message: "Det finns inget räkenskapsår för det nuvarande datumet. För att kunna använda Bokio behöver du skapa ett nytt nedan.",
    FiscalYears_OpeningBalancesExist_CannotCreatePreviousFiscalYear: "Du har ingående balanser för företagets första räkenskapsår, så du kan inte skapa ett räkenskapsår som börjar {0}. Om du vill skapa räkenskapsåret måste du ta bort alla dina ingående balanser.",
    FiscalYears_Turnover: "Beräknad omsättning",
    FiscalYears_Turnover_Description: "Används för att visa Viktiga datum från Skatteverket. Skriv in omsättningen du skulle använda där.",
    FiscalYears_VatPeriod: "Hur ofta redovisar du moms?",
    FiscalYears_VatPeriod_Description: "Du kan se vilken momsperiod du har på [verksamt.se](https://verksamt.se)",
    FiscalYears_VatPeriod_ToolTipMessage: "Om redovisningsfrekvensen ändras kommer alla perioder som inte är markerade som avstämda att ersättas. Om du vill ändra en existerade period måste avstämningssteget i momsrapporten vara upplåst.",
    GoBackHome: "Ta mig hem igen",
    GoToFiscalYears: "Gå till räkenskapsår",
    GoToInvoices: "Gå till fakturor",
    GoToPaymentMethods: "Gå till betalningsalternativ",
    HireAnAccountant_Description: "Har du kört fast eller behöver råd?\nVia våra samarbetspartners kan du få hjälp med allt från löpande bokföring till bokslut, deklaration rådgivning och skattefrågor av en professionell konsult. Ta kontakt med den byrå som passar dig och få hjälp av en redovisningskonsult som kan Bokio.\nBokio tar inte ansvar för samarbetet du ingår med din konsult, vi agerar endast förmedlare. Därför uppmanar vi dig som kund att själv undersöka att du hittar en byrå som passar ditt företags behov på bästa sätt.\n[**På vår hemsida**](https://www.bokio.se/anlita-en-konsult/) har vi sammanställt vanliga frågor och tips för ett lyckat samarbete med en redovisningskonsult.",
    Import_AccountantStep_Description: "Det bästa alternativet vore att få SIE filer med tidigare bokföring från din redovisningskonsult. ",
    Import_AccountantStep_Description2: "Är det inte möjligt att få tag på SIE filer kan du istället välja att sätta ingående balanser. För att garantera att det blir rätt är det bra att ha din balansräkning tillgänglig.",
    Import_AccountantStep_Title: "Hur gjordes bokföringen för företaget innan Bokio?",
    Import_BestImportSolution_Ibs_Label: "Sätt ingående balanser för det år du vill börja på i Bokio",
    Import_BestImportSolution_Ibs_Text: "Detta ger minst arbete om du har många år eller många händelser att bokföra men är svårare.",
    Import_BestImportSolution_Manual_Label: "Bokför alla tidigare händelser i Bokio",
    Import_BestImportSolution_Manual_Text: "Detta är enkelt men kan vara långsamt om du har många händelser som behöver bokföras. Du får också en full historik i Bokio vilket är bra.",
    Import_HowToImport_BestImportSolution_Label: "Välj det sätt att importera som fungerar bäst för er",
    Import_HowToImport_ImportSIE_Title: "Importera SIE filer (bästa valet)",
    Import_HowToImport_ImportSIE_WithoutPrevious_Description: "Detta är bästa alternativet om du har tillgång till dina SIE filer.  [Läs mer om SIE filer](https://www.bokio.se/hjalp/komma-igang/importera-bokforing/vad-ar-en-sie-fil/)",
    Import_HowToImport_ImportSIE_WithPrevious_Description: "Detta är bästa alternativet för {tool} om du har tillgång till dina SIE filer.  [Läs mer om SIE filer](https://www.bokio.se/hjalp/komma-igang/importera-bokforing/vad-ar-en-sie-fil/)",
    Import_ImportPreviousBookkeepingBlock_CancelBtn: "Jag har ingen tidigare bokföring",
    Import_ImportPreviousBookkeepingBlock_Description: "Att importera tidigare bokföring är nödvändigt för att siffrorna i din bokföring ska stämma och deklarationer ska bli rätt. Vi rekommenderar att du så tidigt som möjligt importerar din tidigare bokföring för att undvika problem och dubbelarbete. Du kan däremot börja bokföra innan du importerat din bokföring.",
    Import_ImportPreviousBookkeepingBlock_Heading: "Hur har du tidigare gjort din bokföring?",
    Import_ImportSieFileInstead_LinkText: "Importera föregående bokföring istället",
    Import_PreviousBookkeeping_Label: "Hur har bokföringen gjorts tidigare i ditt bolag?",
    Import_PreviousSolution_Accountant: "Redovisningkonsult",
    Import_PreviousSolution_Excel: "Excel eller liknande",
    Import_PreviousSolution_Label: "Vilket bokföringsprogram har du tidigare använt?",
    Import_PreviousSolution_NoPreviousBookkeeping: "Det har inte gjorts någon bokföring tidigare",
    Import_PreviousSolution_PenAndPaper: "Papper och penna",
    Import_PreviousSolution_Software: "Annat bokföringsprogram",
    Import_PreviousSolutionOtherName_Label: "Programmets namn",
    Import_SetOpeningBalances_LinkText: "Sätt ingående balanser istället",
    ImportCompany_FAQ_Header: "Hjälpsidor om import av tidigare bokföring",
    ImportCompany_FAQ_Link1_href: "https://www.bokio.se/hjalp/komma-igang/importera-bokforing/vad-ar-en-sie-fil/",
    ImportCompany_FAQ_Link1_inner: "Vad är en SIE-fil?",
    ImportCompany_FAQ_Link2_href: "https://www.bokio.se/hjalp/komma-igang/importera-bokforing/importera-bokforing-steg-for-steg/",
    ImportCompany_FAQ_Link2_inner: "Importera bokföring: Steg för steg",
    ImportCompany_FAQ_Link3_href: "https://www.bokio.se/hjalp/komma-igang/importera-bokforing/hur-exporterar-jag-sie-filer-fran-tidigare-bokforingsprogram/",
    ImportCompany_FAQ_Link3_inner: "Hur exporterar jag SIE-filer från tidigare bokföringsprogram",
    ImportCompany_FAQ_Link4_href: "https://www.bokio.se/hjalp/komma-igang/importera-bokforing/min-sie-fil-gar-inte-att-importera/",
    ImportCompany_FAQ_Link4_inner: "Min SIE-fil går inte att importera?",
    ImportCompany_FilesSent_Details: "Vi kommer se över filerna och återkomma till dig.",
    ImportCompany_FilesSent_Header: "Dina filer är skickade till support",
    ImportCompany_NoFiscalYearsFound: "Hittade inga räkenskapsår i någon av filerna",
    ImportCompany_PageHeader: "Importera tidigare bokföring",
    ImportCompany_Review_CTA_Force: "Importera ändå",
    ImportCompany_Review_CTA_Import: "Importera",
    ImportCompany_Review_CustomAccounts: "Egna konton",
    ImportCompany_Review_DuplicateVerifications_Multiple: "{0} verifikat är dubbletter och kommer inte att importeras",
    ImportCompany_Review_DuplicateVerifications_Single: "{0} verifikat är duplicerat och kommer inte att importeras",
    ImportCompany_Review_Error: "Fel",
    ImportCompany_Review_Error_FiscalYearClosed: "Redan befintligt räkenskapsår är stängt",
    ImportCompany_Review_Error_FiscalYearGap: "Glapp mellan räkenskapsår",
    ImportCompany_Review_Error_FiscalYearOverlap: "Räkenskapsår överlappar",
    ImportCompany_Review_FiscalYear: "Räkenskapsår",
    ImportCompany_Review_Header: "Granska filer",
    ImportCompany_Review_NewVerifications_Multiple: "{0} nya verifikat",
    ImportCompany_Review_NewVerifications_None: "Inga",
    ImportCompany_Review_NewVerifications_Single: "{0} nytt verifikat",
    ImportCompany_Review_Table_Date: "Datum",
    ImportCompany_Review_Table_Nr: "Nr.",
    ImportCompany_Review_Table_Sum: "Summa",
    ImportCompany_Review_Table_Title: "Titel",
    ImportCompany_ReviewSettings_AccountingMethodWarning: "'Bokföringsmetoden är inställd som \"{0}\" i Bokio men verkar vara \"{1}\" i filerna'",
    ImportCompany_ReviewSettings_Confirm: "Bekräfta att du vill importera trots varningarna ovan",
    ImportCompany_ReviewSettings_Description: "Dessa varning finns för att hjälpa dig att inte importera fel filer till ditt bolag då det är svårt att rätta till sedan. Du behöver nu dubellkolla att filerna du laddat upp tillhör detta bolaget. Gör de det kan du bara fortsätta. Om du vill ändra företagsinställningar kan du göra det under inställningarna efteråt.",
    ImportCompany_ReviewSettings_Header: "Vi hittade några inställningar som bör granskas",
    ImportCompany_ReviewSettings_MixedOrgNumbers: "Filerna du laddade upp är eventuellt från olika bolag. Var vänligt kontrollerna detta.",
    ImportCompany_ReviewSettings_NameWarning: "'Företagsnamnet är \"{0}\" i Bokio men var \"{1}\" i filerna'",
    ImportCompany_ReviewSettings_OrgNumberWarning: "'Organisationsnumret är \"{0}\" i Bokio men var \"{1}\" i filerna'",
    ImportCompany_ReviewSettings_VatSettingWarning: "'Momsperioden är inställd som \"{0}\" i Bokio men verkar vara \"{1}\" i filerna'",
    ImportCompany_Success_Header: "Dina filer har importerats!",
    ImportCompany_Success_Instructions: "Var god gå till inställningarna för räkenskapsåren och stäng de tidigare år som du redan gjort bokslutet för.",
    ImportCompany_Upload_Crash_Details: "Något gick snett när vi försökte läsa av din fil.",
    ImportCompany_Upload_Crash_Header: "Filen kunde inte läsas",
    ImportCompany_Upload_CTA_CheckFiles: "Kontrollera filer",
    ImportCompany_Upload_ErrorsFound: "Vi hittade {0} fel.",
    ImportCompany_Upload_Header: "Ladda upp filer",
    ImportCompany_Upload_Table_Name: "Filnamn",
    ImportCompany_Upload_Table_Size: "Filstorlek",
    ImportedJournalEntries: "Importerade verifikat",
    ImportPreviousBookkeeping: "Importera tidigare bokföring",
    Inbox_AddAllowedEmail: "Lägg till e-postadress",
    Inbox_AllowUnsafeEmails: "Tillåt osäker e-post",
    Inbox_Body: "Text",
    Inbox_EditInboxAddress_Preamble: "Ändra adressen till din inkorg",
    Inbox_EditInboxAddress_Title: "Adress till inkorg",
    Inbox_From: "Från",
    Inbox_History_AttachmentStatus_Accepted: "Inläst",
    Inbox_History_AttachmentStatus_Pending: "Ej inläst",
    Inbox_History_AttachmentStatus_RefusedMd5Checksum: "Redan inläst",
    Inbox_History_AttachmentStatus_RefusedMimetype: "Ej PDF",
    Inbox_History_MessageStatus_BlockedByInboxAccess: "Köat",
    Inbox_History_MessageStatus_BlockedByInboxAccess_Legend: "Meddelandet är köat tills du aktiverar Premium",
    Inbox_History_MessageStatus_Imported: "Inläst",
    Inbox_History_MessageStatus_Rejected: "Borttaget",
    Inbox_History_MessageStatus_Sandboxed: "Sandboxed",
    Inbox_Manage: "Hantera kvittoinkorgen",
    Inbox_Modal_Approve: "Godkänn och importera PDF'er",
    Inbox_Modal_Attachments: "Filer",
    Inbox_Modal_FraudChecks: "Bedrägerikontroll",
    Inbox_Modal_MessageDetails: "E-post-data",
    Inbox_Modal_Reject: "Ta bort",
    Inbox_Modal_Status: "Status",
    Inbox_RemovedUser: "(Borttagen användare)",
    Inbox_Sender: "Avsändare",
    Inbox_Subject: "Ämnesrad",
    Inbox_UnconnectedUser: "(Ej kopplade till användare)",
    InboxAddAllowedEmailModal_Preamble: "Lägg till en godkänd e-postadress för {0}",
    InboxAllowedSenders_Preamble: "Välj vilka personer och e-postadresser som ska kunna skicka mejl till din inkorg.",
    InboxAllowedSenders_Title: "Godkända sändare/e-postadresser",
    InboxGettingStartedInstructions: "Skicka dina fakturor eller kvitton till denna e-postadress så dyker de upp under {0}. För tillfället stödjer vi endast filformatet PDF, max 4 MB per fil.",
    InboxHistory: "Att ladda ner filer från en okänd källa är farligt!",
    InboxHistory_AttachmentStatus_Accepted_Legend: "Bilagan har importerats",
    InboxHistory_AttachmentStatus_Pending_Legend: "Bilagan blev inte importerad men kommer att importeras om du godkänner meddelandet",
    InboxHistory_InboundEmailResultStatus_Label_Accepted: "OK",
    InboxHistory_InboundEmailResultStatus_Label_RefusedNoFiles: "Inga filer",
    InboxHistory_InboundEmailResultStatus_Label_RefusedWhitelist: "Obetrodd avsändare",
    InboxHistory_InboxFraudCheckResult_Label_Fail: "Fel",
    InboxHistory_InboxFraudCheckResult_Label_Suspicious: "Misstänkt",
    InboxHistory_InboxFraudCheckResult_Trusted_Fail: "OK",
    InboxHistory_InitialAction_Label: "Bokio satte meddelandet som",
    InboxHistory_Label_CurrentStatus: "Status",
    InboxHistory_Label_FraudCheck: "Bedrägerikoll",
    InboxHistory_Label_ResultStatus: "Resultat",
    InboxHistory_Label_Sender: "Avsändare",
    InboxHistory_Label_TimeStamp: "Mottaget",
    InboxHistory_Modal_Title: "Meddelandedetaljer",
    InboxHistory_Status_Accepted_Imported_Legend: "Meddelandet och bilagor importerades",
    InboxHistory_Status_Accepted_Rejected_Legend: "Meddelandet togs bort och inga filer importerades",
    InboxHistory_Status_Accepted_Sandboxed_Legend: "Meddelandet blev satt i karantän. Inga filer importerades",
    InboxHistory_Title: "Meddelandehistorik",
    InReviewByAgency_NoticeTitle: "Granskas av {0}",
    Integrations_heading: "Integrationer",
    Integrations_iZettle_Description: "Koppla ihop ditt Bokio konto med Zettle och bokför automatiskt dina Zettle händelser.",
    Integrations_MainScene_SubText: "Här hittar du alla integrationer som för närvarande finns i Bokio.",
    Integrations_OpenAPI_AreYouInterested_heading: "Är du intresserad av ett öppet API från Bokio?",
    Integrations_OpenApi_Enquiry: "Förfrågan angående Bokios öppna API",
    Integrations_OpenApi_RegisterInterest: "Anmäl intresse",
    Integrations_OpenApi_WeArePlanning_message: "Vi samlar intresse och förfrågningar för att lära oss hur ni vill använda ett öppet API från Bokio.",
    InterestSubmittedSuccessfully: "Intresseanmälan skickades in",
    IntervalChangeModal_text: "Med start {0} kommer du betala {1}{2} excl moms.\n \n\n[Läs mer om att ändra betalningsperiod]({3})",
    IntervalChangeModal_title: "Ändra till {0} betalning",
    IntervalChangeModal_to_Premium_yearly_text: "För bara {0} ({1} inkl. moms), upgradera din plan för perioden {2} till {3}. Med denna {4} sänkning slipper du betala för oanvänd Premium-tid.",
    InvitationType_Accountant: "Revisor",
    InvitationType_General: "Allmän",
    Invite: "Bjud in",
    InviteAccountant_BothAgenciesNotice: "Denna e-postadress är också associerad med en byrå på sidan Anlita en revisor. Välj ett alternativ för att fortsätta.",
    InviteAccountant_PartnerAgency_Description: "Om du vill skicka en begäran till {0} måste du skicka den från sidan Anlita en revisor.",
    InviteAccountant_SuccessMsg_Content: "Du får ett meddelande när din revisor svarar på din inbjudan.",
    InviteAccountant_SuccessMsg_Title: "Din inbjudan har skickats",
    InviteAccountant_VisitHAAPage: "Besök sidan Anlita en revisor",
    InviteAgency_description: "Inbjudan skickas till byrån som redovisningkonsulten är medlem av. När inbjudan accepteras kan ni börja jobba tillsammans på Bokio.",
    InviteAgency_LoadingMessage: "Behörigheter och inbjudan förbereds",
    InviteAgency_NewAccountantDescription: "Vi kommer att skicka en inbjudan till {0}. När inbjudan är accepterad kan ni börja jobba tillsammans i Bokio.",
    InviteAgency_NewAccountantTitle: "Din redovisningskonsult använder inte Bokio",
    InviteNewAccountant_PersonalMessage_Label: "Personligt meddelande till din revisor (max 200 tecken) (valfritt)",
    InvoiceSettings_AddInvoiceNumberPrefix: "Lägg till prefix",
    InvoiceSettings_ConfirmChanges: "Bekräfta ändringar",
    InvoiceSettings_ConfirmNumberChanges: "Är du säker på att du vill ändra detta? Det kommer påverka dina nästkommande faktura- och kundnummer.",
    InvoiceSettings_CurrencyRounding_Description: "Avrunda totalbeloppet på fakturor och offerter till närmaste krona.",
    InvoiceSettings_CurrencyRounding_Heading: "Avrunda totalbelopp",
    InvoiceSettings_CustomerNumber: "Vilket kundnummer vill du börja med?",
    InvoiceSettings_Description: "Ställ in standardvärden för fakturavillkor (dagar, dröjsmålsränta, leveransvillkor), öresavrundning, kund- och fakturanummer.",
    InvoiceSettings_Heading: "Faktura- och offertinställningar",
    InvoiceSettings_InvoiceNumber: "Fakturanummer",
    InvoiceSettings_InvoiceNumberPrefix: "Prefix",
    InvoiceSettings_InvoiceTerms_Heading: "Fakturastandard",
    InvoiceSettings_RemoveInvoiceNumberPrefix: "Ta bort prefix",
    InvoiceSettings_SaveChanges: "Spara ändringar",
    KickbackCalculator_CalculateCasjback_Header: "Beräkna din återbäring här",
    KickbackCalculator_Description_BBA: "Detta baseras på ditt företagskonto. Du kan anpassa värdet för att göra en egen uppskattning. ",
    KickbackCalculator_Description_Bookkeeping: "Detta baseras på din tidigare bokföring. Du kan anpassa värdet för att göra en egen uppskattning. För att få cashback behövs ett aktivt Bokio företagskonto.",
    KickbackCalculator_Heading: "Uppskattning av cashback",
    KickbackCalculator_Label_AccountBalance: "Saldo på kontot",
    KickbackCalculator_Label_CardSpend: "Kortköp/mån",
    KickbackCalculator_Label_DepositedCapital: "Insatt kapital",
    KickbackCalculator_Label_TotalCardPurchases: "Summa kortköp per månad, exkluderat kontantuttag",
    KickbackCalculator_learn_more_about_bokio_cashback: "Läs mer om Bokios återbäring",
    KickbackCalculator_LI_CashbackCardSpend: "{0} cashback på kortköp",
    KickbackCalculator_LI_CashbackDeposited: "{0} återbäring på insatt kapital (per år)",
    KickbackCalculator_Recalculate: "Räkna om",
    KickbackCalculator_Result_Header: "Total återbäring",
    KickbackCalculator_ResultPrefix_Cashback: "Cashback",
    KickbackCalculator_ResultSuffix_PerMonth: "/månad",
    KickbackCalculator_Table_CashbackCardSpend: "Cashback på korttransaktioner ({0})",
    KickbackCalculator_Table_CashbackDeposited: "Återbäring på insatt kapital ({0} per år)",
    KickbackCalculator_Table_Header: "Beräkningen baseras på:",
    KickbackCalculator_Table_Total: "Total:",
    KickbackCalculator_Table_TotalValue: "{0}/mån",
    Layout_Heading_SubText: "Positionera din företagsadress till höger eller vänster, beroende på adressfönstret på kuveret",
    Layout_PageTitle: "Layout, logotyp och färg",
    LayoutColor_Subtext_description: "Med dessa inställningar kan du anpassa format och utseende på fakturor, offerter och lönespecar",
    LeaveCompany_ConfirmModal_Action: "Lämna {0}",
    LeaveCompany_ConfirmModal_Description1: "Du är på väg att lämna {0}, är du säker på att du vill fortsätta?",
    LeaveCompany_ConfirmModal_Description2: "Denna åtgärd kan inte ångras!",
    LeaveCompany_Feedback_Heading: "Vad är anledningen till att du lämnar företaget?",
    LeaveCompany_Feedback_Paragraph: "Vi är ledsna över att du lämnar men skulle vilja förstår varför du lämnar företaget, så vi kan skapa en ännu bättre tjänst. Vänligen ge oss feedback.",
    LeaveCompany_heading: "Lämna företag",
    LeaveCompany_LeaveWarning: "Vänligen notera att när du lämnat företaget så går det inte att ångra!",
    LeaveCompany_Section1_Heading1: "Vad innebär det att lämna företaget i Bokio?",
    LeaveCompany_Section1_Paragraph1: "När du lämnar företaget {0} så kommer du inte ha tillgång till företaget igen om ingen bjuder in dig igen.\n\nDitt personliga konto {1} kommer inte att bli borttaget, så du kommer fortfarande ha tillgång till andra företag i Bokio.",
    LeaveCompany_UnableToLeave_UserIsBbaOwner: "Du kan inte lämna detta företag då du påbörjat eller har ett aktivt Bokio Företagskonto. För att lämna företaget måste du först avsluta Bokio Företagskonto.",
    LeaveCompany_UnableToLeave_UserIsOwner: "Du kan inte lämna detta företag eftersom du är ägare av företaget i Bokio. För att lämna måste du antingen radera företaget eller överföra äganderättigheterna till en annan användare.",
    ManageNotifications: "Hantera notiser",
    ManagePlans: "Hantera planer",
    MarketingSettings: "Marknadsföringsinställningar",
    MobileAppPromotion_AppStoreLink: "https://apps.apple.com/se/app/bokio/id1477107219",
    MobileAppPromotion_PlayStoreLink: "https://play.google.com/store/apps/details?id=com.bokio.app",
    Notification_Description: "När du aktiverar en kategori kommer du att få notiser när du loggar in på Bokio eller öppnar Bokioappen.&lt;br/&gt;Utöver det kan du också välja våra push- och e-postnotiser.",
    Notification_EmailDescription: "{E-post} : Skickas till e-postadressen i ditt Bokiokonto",
    Notification_PushDescription: "{Push} : Skickas till den mobila enheten eller surfplattan som har Bokioappen",
    NotificationRedirect_CouldNotBeFound: "Sidan för notifikationen kunde inte hittas",
    OffboardAgency_FeedbackHeading: "Ta bort {0}",
    OffboardAgency_FeedbackMessage: "Varför tar du bord denna byrå?",
    OffboardAgency_FeedbackOption_AgreementEnded: "Avtalsperioden är slut",
    OffboardAgency_FeedbackOption_ClientPreferredAnotherSystem: "Min redovisningskonsult föredrar ett annat system",
    OffboardAgency_FeedbackOption_UnhappyWithProvidedService: "Jag är missnöjd med servicenivån",
    OffboardAgency_SubmitAndOffboard_Button: "Skicka och ta bort",
    OpeningBalances_AccountsDoNotExistInAccountPlan: "Ett eller flera konton finns inte i vår kontoplan.",
    OpeningBalances_Amount: "Summa",
    OpeningBalances_Assets_InstructionModal_Explanation: "De ingående balanserna för ett nytt räkenskapsår är alltid samma som de utgående balanserna från det tidigare räkenskapsåret. Byter man {0}-01-01 behöver man de utgående balanserna för {1}-12-31. Det behövs med andra ord en balansrapport från ditt tidigare bokföringsprogram som gäller för det föregående räkenskapsåret.\n\nBörja med att läsa av de utgående balanserna på tillgångssidan. Alla tillgångskonton börjar med 1XXX, till exempel 1510-Kundfodringar. De utgående balanserna återfinns längst till höger på balansrapporten.",
    OpeningBalances_Assets_Instructions_Step_FillSums: "Fyll i summorna för varje konto som börjar på 1xxx.",
    OpeningBalances_Assets_Instructions_Step_ReadBalanceReport: "Utgå från de utgående balanserna på balansrapporten. ",
    OpeningBalances_BalanceInfo: "Balans uppnås när totalen för tillgångar, eget kapital och skuld är 0,00 kr",
    OpeningBalances_BalanceIsCorrect: "Totalen för tillgångar, eget kapital och skuld är 0,00 kr.",
    OpeningBalances_BalanceIsWrong: "Balans har inte uppnåtts. Kontrollera att du har angett alla summor korrekt och att tillgångarna balanseras mot eget kapital och skulder. Om du saknar ett färdigt årligt resultat bör du överföra det beräknade resultatet under konto 2099.",
    OpeningBalances_BalanceIsWrong_Title: "Totalen för tillgångar, eget kapital och skuld är inte 0,00 kr",
    OpeningBalances_CantSave: "Kan du inte spara? Lär dig hur med {vår guide om ingående balans}",
    OpeningBalances_EditBalances: "Redigera balanser",
    OpeningBalances_EquityAndLiabilities_InstructionModal_Explanation: "De ingående balanserna för ett nytt räkenskapsår är alltid samma som de utgående balanserna från det tidigare räkenskapsåret. Byter man {0}-01-01 behöver man de utgående balanserna för {1}-12-31. Det behövs med andra ord en balansrapport från ditt tidigare bokföringsprogram som gäller för det föregående räkenskapsåret.\n\nLäs av de utgående balanserna på eget kapital och skulder. Alla dessa konton börjar med 2XXX, till exempel 2440-Leverantörsskulder. De utgående balanserna återfinns längs till höger på balansrapporten.",
    OpeningBalances_EquityAndLiabilities_Instructions_Step_FillSums: "Fyll i summorna för varje konto som börjar på 2xxx.",
    OpeningBalances_EquityAndLiabilities_Instructions_Step_FinalSum: "Om ditt bokslut inte är klart kan du lägga till summan för beräknat resultat på konto 2099.",
    OpeningBalances_EquityAndLiabilities_Instructions_Step_ReadBalanceReport: "Utgå från de utgående balanserna på balansrapporten.",
    OpeningBalances_Error_ErrorNoticeTitle: "Vi har upptäckt problem med dina ingående balanser.",
    OpeningBalances_HowDoIReadBalanceReport: "Hur läser jag balansrapporten?",
    OpeningBalances_Instruction: "Här registreras ingående balanser för ditt första räkenskapsår i Bokio som börjar **{start}**. Du kan också ändra och [se över dina räkenskapsår](bokio:/companyid/settings-r/fiscal-years) innan du gör detta. För att lättast sätta ingående balanser kan du ta hjälp av en balansrapport för föregående räkenskapsår med en period som sträcker sig till föregående räkenskapsårets sista dag.",
    OpeningBalances_InstructionModal_Example: "Fyll sedan i de exakta värderna i Bokio som visas i balansrapporten. Nedan är ett exempel på hur det ser ut.",
    OpeningBalances_InvalidAccountNumber: "Ogiltigt kontonummer",
    OpeningBalances_NewBalance: "Ny balans",
    OpeningBalances_NoBalancesFor: "Inga balanser har blivit tillagda för {0}",
    OpeningBalances_NotFirstFiscalYear: "Du tittar på räkenskapsåret som börjar {0} som inte är företagets första räkenskapsår och det är därför du inte kan ändra det.",
    OpeningBalances_SaveButtonTooltip: "Du kan endast spara dina ingående balanser om den totala balansen är noll, om sidan är olåst och om det inte finns något ogiltigt kontonummer.",
    OpeningBalances_SetOpeningBalancesHeader: "Sätt ingående balanser – {0}",
    OpeningBalances_Success: "Ingående balanser har sparats! Du kan se detaljer om dina [ingående balanser i huvudboken](bokio:/companyid/reports/ledger)",
    OpeningBalances_Title: "Ingående balanser",
    OpeningBalances_TotalBalance: "Total balans",
    Overview_AutomationRules_SubText_text: "Definiera egna regler för vilka bokföringsmallar som ska användas för att bokföra banktransaktioner.",
    Overview_ChangePassword: "Ändra lösenord",
    Overview_CompanySettings_SubText_text: "Dina företagsinställningar är nödvändiga för till exempel fakturering, löner, inkludera kontaktinformation, logotype och layout samt utbetalningar",
    Overview_CustomBookkeepingTemplates_SubText_text: "Skapa och hantera egna bokföringsmallar för att förenkla och effektivisera din bokföring",
    Overview_ExportBookkeeping: "Exportera bokföring och underlag",
    Overview_ExportCompanyData: "Exportera data",
    Overview_FiscalYears_SubText_text: "Innehåller datum för räkenskapsår, momsperioder, bokföringsmetod och beräknad omsättning",
    Overview_Import_Bookkeeping_Description: "Importera tidigare bokföring eller sätt ingående balanser",
    Overview_Import_TradeCreditors: "Leverantörreskontra",
    Overview_Import_TradeCreditors_Description: "Importera obetalda leverantörsfakturor för att få en överblick av vem som du är skyldig pengar i Bokio.",
    Overview_Import_TradeDebtors: "Kundreskontra",
    Overview_Import_TradeDebtors_Description: "Importera obetalda kundfakturor för att få en överblick av vem som är skyldig dig pengar i Bokio",
    Overview_Inbox_SubText_text: "Ställ in vilka som via e-mail kan skicka in fakturor som ska bokföras",
    Overview_MoneyAccounts_SubText_text: "Här finner du en lista på kontonamnen som finns tillgängliga att bokföra på i din huvudbok",
    Overview_OpeningBalances: "Flytta dina ingående balanser till Bokio genom att använda balansrapporten från ditt föregående redovsningsprogram.",
    Overview_Salary_ContactPerson: "Kontaktperson",
    Overview_TerminateBokioBusinessAccount_Description: "Avsluta Bokio Företagskonto och alla tillhörande tjänster.",
    Partner: "Partner",
    Partner_Application_DescribeWhatYouWantHelpWith: "Beskriv vad du vill ha hjälp med",
    PartnerOnboarding_FirstStep_Title: "1. Bekräfta företagsinformation",
    PartnerOnboarding_SecondStep_Title: "2.  Information om ditt räkenskapsår",
    PartnerOnboarding_Submit_Acknowledgment: "Jag bekräftar att:",
    PartnerOnboarding_SubmitNotice_WhatsNext: "Tack så mycket för din intresseanmälan. Våra redovisningskonsulter kommer kolla informationen och återkomma till dig på {email}",
    PartnerOnboarding_Submitted_UnderReview: "Vår partners onboarding-team granskar din ansökan och  kommer ge svar på {email}",
    PartnerOnboarding_ThirdStep_Employees: "Hur många anställda har ditt företag?",
    PartnerOnboarding_ThirdStep_ExpectedTurnover: "Vad beräknas ditt bolag omsätta per år?",
    PartnerOnboarding_ThirdStep_MainActivity: "Vad är den huvudsakliga sysselsättningen för ditt bolag?",
    PartnerOnboarding_ThirdStep_MainActivity_Placeholder: "Berätta vad ditt företag gör och om det är något speciellt vi behöver veta.",
    PartnerOnboarding_ThirdStep_PartnerStartMonth: "Vilken månad önskar du starta Bokio Partner?",
    PartnerOnboarding_ThirdStep_Title: "3. Övrig information",
    PartnerOnboarding_ThirdStep_Verifications: "Verifikat per månad",
    PaymentMethods_invoice: "Faktura",
    PaymentMethodSettings_Description: "Välj betalningsalternativ för inrikes och utländska fakturor",
    PaymentMethodSettings_Heading: "Betalningsalternativ",
    PersonalSettings_CouldNotUpdateDetails_errorMessage: "Vi kunde inte uppdatera dina uppgifter, var god försök igen",
    PersonalSettings_UserResearchGroup_Description: "Hjälp oss att förbättra produkten genom att delta i vår research-grupp. Du kommer få möjlighet att vara med i intervjuer, selftests m.m. (Du kan närsomhelst välja att lämna gruppen)",
    PersonalSettings_UserResearchGroup_Label: "Jag vill delta i Bokios research-grupp",
    PlaceOfEmployment: "Tjänsteställe",
    Plan_Ends: "Upphör {0}",
    Plan_Renews: "Förnyas {0}",
    Plan_Starts: "Startar {0}",
    Plans_6_month_free_promo_text: "Som nystartat företag får du upp till 6 månader på köpet när du uppgraderar. [Läs mer om Bokio Start här]({0}). **Erbjudandet gäller till {1}**",
    Plans_6_month_free_promo_title: "Uppgradera din prisplan och få 6 månader gratis med Bokio Start",
    Plans_BanksSupportingAutomaticImport: "Banker som stöder automatisk bankkoppling",
    Plans_BBABanner_Activate_Header: "Aktivera Bokio Företagskonto",
    Plans_BBABanner_Activate_Paragraph: "Automatisk bokföring för både fakturor och löner. Det blir även lättare att ta betalt med Bokio Företagskonto. Med ett aktivt företagskonto kan du dessutom få cashback med Premium Plus planen.",
    Plans_BBABanner_CTA: "Läs mer",
    Plans_BilledMonthly: "{0} dras månadsvis",
    Plans_BilledQuarterly: "{0} dras kvartalsvis",
    Plans_BilledYearly: "{0} dras årsvis",
    Plans_BokioStartCampaign_Content: "Som nystartad får du upp till 6 månader på köpet när du tecknar en plan. Bokio Företagskonto ingår utan extra kostnad!",
    Plans_BokioStartCampaign_Get6FreeMonths: "Få ett försprång med 6 extra månader",
    Plans_BokioStartCampaign_GetStarted: "Kom igång",
    Plans_BokioStartCampaign_OfferEnds: "Erbjudandet gäller till {0}",
    Plans_BuyModal_ExVat: "exkl. moms",
    Plans_BuyModal_ExVatAndCashback: "exkl. moms och cashback",
    Plans_BuyModal_Free_Description: "Manuell bokföring och fakturering för väldigt små bolag.",
    Plans_BuyModal_Heading: "Nästan klar!",
    Plans_BuyModal_MissingInfo: "Du behöver ställa in ditt organisationsnummer och företagsnamn innan du kan fortsätta",
    Plans_BuyModal_Monthly: "månadvis",
    Plans_BuyModal_MonthlyBilling: "Betala månadsvis",
    Plans_BuyModal_PerMonth: "/ månad",
    Plans_BuyModal_PerQuarter: "/ kvartal",
    Plans_BuyModal_PerYear: "/ år",
    Plans_BuyModal_Premium_Description: "Alla funktioner ingår i vår allt-i-ett lösning med företagskonto, fakturering, lön och bokföring",
    Plans_BuyModal_Pro_Description: "Designat för att ge mer – återbäring, personlig support och bokföringshjälp",
    Plans_BuyModal_Quarterly: "kvartalsvis",
    Plans_BuyModal_QuarterlyBilling: "Betala kvartalsvis",
    Plans_BuyModal_Sub1_Heading: "Välj plan",
    Plans_BuyModal_Sub2_Heading: "Hur vill du betala?",
    Plans_BuyModal_Sub3_Heading: "Prenumerationsdetaljer",
    Plans_BuyModal_Yearly: "årsvis",
    Plans_BuyModal_YearlyBilling: "Betala årsvis",
    Plans_Campaign_AppliedIntervalDiscount_Description: "Du har {0}% rabatt. Din rabatt är aktiv till och med {1}.",
    Plans_Campaign_IntervalDiscount_Description: "Få {0}% rabatt när du väljer {1} med {2} betalning. Detta erbjudande gäller för {3}.",
    Plans_Campaign_IntervalDiscount_FirstBillingPeriod: "din första betalningsperiod",
    Plans_Campaign_IntervalDiscount_FirstMonths: "dina första {0} månader",
    Plans_Campaign_IntervalDiscount_Label: "Rabatt {0}%",
    Plans_CancelPlan_button: "Avsluta plan",
    Plans_Checkout_AutomaticallyRenews: "Ditt abonnemang förlängs automatiskt tills du väljer att avsluta det.",
    Plans_Checkout_Conditions_Heading: "Villkor",
    Plans_Checkout_SubscriptionCostMonthly: "Månadskostnad",
    Plans_Checkout_SubscriptionCostQuarterly: "Kvartalskostnad",
    Plans_Checkout_SubscriptionCostYearly: "Årlig kostnad",
    Plans_Checkout_ToPay: "Att betala inkl. moms",
    Plans_Checkout_TrialNotice: "Första betalningen kommer att ske {0}, då provperioden avslutats.",
    Plans_Checkout_TrialText: "Du betalar inte förrän provperioden slutar den {0}. ",
    Plans_Checkout_VAT25: "Moms 25 %",
    Plans_ConfirmUpgrade_CashbackNotice: "Din uppskattade återbäring på {0}/månad beräknas vara högre än kostnaden för Premium Plus på {1}/månad.",
    Plans_ConfirmUpgrade_LearnMore: "Läs mer om att ändra prisplaner här",
    Plans_ConfirmUpgrade_PayButton: "Betala {0}",
    Plans_ConfirmUpgrade_Text: "För endast {0} ({1} inkl. moms) kan du uppgradera din plan för perioden {2} till {3}. Detta avdrag på {4} gör att du slipper betala för oanvänd tid av Premium-planen.",
    Plans_ConfirmUpgrade_Text3_NoDiscount: "Efter {0} kommer du betala {1} {2} exkl moms.",
    Plans_ConfirmUpgrade_Text3_WithDiscount: "Du har en rabatt som gäller fram till {0}, därefter kommer du betala {1} {2} exkl moms.",
    Plans_ConfirmUpgrade_Title: "Bekräfta uppgradering",
    Plans_ConfirmUpgrade_ToPremiumPLus_AndOnwards: "**{0} och framåt:** {1}",
    Plans_ConfirmUpgrade_ToPremiumPLus_ReadMore: "Lär dig mer om reducerat pris vid uppgradering",
    Plans_ConfirmUpgrade_ToPremiumPLus_Text: "Priset för Premium Plus reduceras under den återstående tiden av din nuvarande prenumeration. {0} fördelas månadsvis och reducerar dina kommande kvartalsbetalningar tills hela beloppet har använts.",
    Plans_ConfirmUpgrade_ToPremiumPLus_TodayYouPay: "{0} {1} {2} ({3} inkl. moms)",
    Plans_ConfirmUpgrade_ToPremiumPLus_TodayYouPay_start: "Idag betalar du:",
    Plans_ConfirmUpgrade_TrialUpgrade: "Genom att fortsätta kommer din schemalagda betalning för ett {0}-abonnemang med {1} att avbrytas, och du kommer att byta till ett {2}-abonnemang med {3}. Den första betalningen sker {4} då {5} exkl. moms dras, om du inte avbryter abonnemanget innan dess.",
    Plans_CurrentPlanBox_MonthlyPayments: "Månadsbetalningar på {0} exkl. moms.",
    Plans_CurrentPlanBox_NextPayment: "Nästa betalning dras {0}.",
    Plans_CurrentPlanBox_PlanIsEnding: "Planen kommer avslutas {0}.",
    Plans_CurrentPlanBox_QuarterlyPayments: "Kvartalsbetalningar på {0} exkl. moms.",
    Plans_CurrentPlanBox_YearlyPayments: "Årliga betalningar på {0} exkl. moms.",
    Plans_DisabledMessage: "Just nu är prisplanssidan avaktiverad. Vi jobbar på att aktivera den så snart som möjligt.",
    Plans_DowngradeToPremium_okButton: "Bekräfta Nedgradering",
    Plans_DowngradeToPremium_Quarter: "kvartal",
    Plans_DowngradeToPremium_Quarterly: "kvartalsvis",
    Plans_DowngradeToPremium_StartingFrom: "Från och med {0} betalar du {1}/{2} exkl. moms.",
    Plans_DowngradeToPremium_Text: "Genom att nedgradera kommer du inte längre att ha tillgång till månatlig cashback eller prioriterad support. Din planerade betalning avbryts och ersätts av en betalning för Premium med en {0} faktureringscykel.",
    Plans_DowngradeToPremium_Title: "Nedgradera till Premium",
    Plans_DowngradeToPremium_Year: "år",
    Plans_DowngradeToPremium_Yearly: "årlig",
    Plans_EPB_Text_Monthly: "Månadsbetalning",
    Plans_EPB_Text_Quarterly: "Kvartalsbetalning",
    Plans_EPB_Text_Yearly: "Årsbetalning",
    Plans_F_ArsredovisningOnline: "Integration till Årsredovisning Online",
    Plans_F_AssetManagement: "Anläggningsregister",
    Plans_F_AutomaticReports: "Automatiska rapporter",
    Plans_F_Bank: "Bank",
    Plans_F_BankAccount: "Bankkonto",
    Plans_F_BankRules: "Bankregler",
    Plans_F_BBA: "Bokio Företagskonto",
    Plans_F_BBankSync: "Automatisk bankkoppling (Se länk nedan för vilka banker det gäller)",
    Plans_F_BBGNumber: "Bankgiro med företagsnamn",
    Plans_F_BCard: "Företagskort",
    Plans_F_BFreeTransactions: "Banktransaktioner per månad",
    Plans_F_BManualImports: "Manuell bankimport",
    Plans_F_Bookkeeping: "Bokföring",
    Plans_F_bookkeepingTemplates: "Bokföringsmallar",
    Plans_F_BOther: "Övrigt",
    Plans_F_BSupport: "Support",
    Plans_F_BSupport_Free: "Buggrapporter och community support",
    Plans_F_BSupport_Premium: "Priosupport",
    Plans_F_BSupport_Pro: "Priosupport och livechatt",
    Plans_F_BulkRecording: "Bokför flera transaktioner samtidigt",
    Plans_F_CashbackCapital: "Återbäring på insatt kapital",
    Plans_F_CashbackCard: "Cashback på kortköp",
    Plans_F_Closure: "Manuellt bokslut",
    Plans_F_CurrencyExchangeSurcharge: "Valutaväxlingspåslag",
    Plans_F_CurrencyExchangeSurcharge_text: "Enl bankens kursdata",
    Plans_F_CurrencyExchangeSurchargeInternationalPayments: "Valutaväxlingspåslag utlandsbetalning",
    Plans_F_CustomTemplates: "Egna bokföringsmallar",
    Plans_F_Discount: "{0} {1} under år {3}",
    Plans_F_ECommerce: "E-handel",
    Plans_F_EInvoice: "E-faktura",
    Plans_F_EmployerTax: "Arbetsgivardeklaration (AGI)",
    Plans_F_ExchangeSurchargeFee: "Valutaväxlingspåslag kort",
    Plans_F_ExchangeSurchargeFeeApplciableFrom: "(gäller från 1 oktober 2024)",
    Plans_F_IBranding: "Välj färg, layout och logotyp på fakturor och offerter",
    Plans_F_IChecking: "Automatisk avprickning",
    Plans_F_Inbox: "Kvittoinkorg",
    Plans_F_Integrations: "Integrationer",
    Plans_F_InternationalPaymentFee: "Utlandsbetalning understigande {3000}",
    Plans_F_InternationalPayments: "Internationella betalningar",
    Plans_F_InternationalPaymentsContactSupport: "Utlandsbetalningar (kontakta support for aktivering)",
    Plans_F_InvoicePurchase: "Fakturaköp",
    Plans_F_InvoiceReports: "Fakturarapporter",
    Plans_F_Invoicing: "Fakturering",
    Plans_F_InvoicingBasic: "Skapa, skicka och bokför fakturor",
    Plans_F_ISecondaryFeatures: "E-faktura, ROT/RUT och offert",
    Plans_F_IZettle: "Zettle",
    Plans_F_ManualBookkeeping: "Manuell Bokföring",
    Plans_F_MultipleAccounts: "Flera bankkonton",
    Plans_F_NeAttachment: "NE-bilaga för enskild firma",
    Plans_F_NrOfUsers: "Antal användare",
    Plans_F_OMobileApp: "Mobilapp",
    Plans_F_PaymentsOutsideEU: "Betalningar utanför EU",
    Plans_F_PayrollManagement: "Lönehantering",
    Plans_F_QR_AVI: "QR-kod och AVI-rad på fakturor",
    Plans_F_Quotes: "Offerter",
    Plans_F_ReceiptSupport: "Bokföringssupport",
    Plans_F_RecurringInvoices: "Återkommande fakturor",
    Plans_F_RotRut: "Stöd för rot och rut",
    Plans_F_Salary: "Lön och anställda",
    Plans_F_Salary_DirectorsPayroll: "Directors payroll",
    Plans_F_SBasic: "Skapa, bokför och rapportera löner",
    Plans_F_SEmployeeCost: "Kostnad per extra anställd",
    Plans_F_SExpenses: "Hantera utlägg",
    Plans_F_SFreeEmployees: "Anställda som ingår per månad",
    Plans_F_SmartTemplates: "Smarta bokföringsmallar",
    Plans_F_Stripe: "",
    Plans_F_SwishMonthlyFee: "Månadsavgift Swish Företag",
    Plans_F_SwishTransactionCost: "Swish transaktionsavgift",
    Plans_F_Tags: "Taggar",
    Plans_F_TaxAccount: "Skattekonto",
    Plans_F_Unlimited: "Obegränsat",
    Plans_F_VacationSalary: "Semesterhantering",
    Plans_F_VATReturns: "Momsdeklaration",
    Plans_F_Verification: "verifikat",
    Plans_F_WithdrawalFee: "Uttagsavgift Sverige och utomlands",
    Plans_FailedPaymentBlock_Heading: "Din betalning mysslyckades",
    Plans_FailedPaymentBlock_Message: "För att fortsätta använda din plan måste du uppdatera dina betalningsuppgifter",
    Plans_FreeCompany_SelectPlan: "Välj en plan för att fortsätta med Bokios tjänster",
    Plans_LimitedTimeOffer_PremiumPlus1: "Få Premium Plus till priset av Premium första månaden (spara 300 kr). Återbetalningen sker till ditt Bokio Företagskonto",
    Plans_LimitedTimeOffer_PremiumPlus2: "Dubbel cashback på kortköp (2%) första månaden",
    Plans_LimitedTimeOffer_PremiumPlusHeading: "Tidsbegränsat erbjudande för Premium Plus",
    Plans_LimitedTimeOffer_PremiumPlusOfferEnds: "Erbjudandet gäller t.o.m. {0}.",
    Plans_Monthly: "månadsvis",
    Plans_NPT_Current: "Aktiv plan",
    Plans_NPT_Quarterly: "Kvartal",
    Plans_NPT_Recommended: "Rekommenderad",
    Plans_NPT_Yearly: "År",
    Plans_Premium_ChangeTo_Quarterly: "kvartalsvis betalning",
    Plans_Premium_ChangeTo_Yearly: "årsvis betalning",
    Plans_PremiumPlus_to_Premium: "Premium",
    Plans_Quaterly: "kvartalsvis",
    Plans_RequiresBBA: "Kräver Bokio Företagskonto",
    Plans_RPU_AppliedBokioStartOffer_Description: "Som ett nystartat företag fick du {0} månader på köpet när du tecknade en plan.",
    Plans_RPU_BokioStartOffer: "{0} extra månader",
    Plans_RPU_BokioStartOffer_Description: "Som ett nystartat företag får du {0} månader på köpet när du tecknar en plan.\n\n**Erbjudandet gäller till {1}.**",
    Plans_RPU_campaigns_header: "Kampanjer som ingår:",
    Plans_RPU_CancelCancellation: "Avbryt uppsägning",
    Plans_RPU_CancelCurrentPlan: "Avsluta {0}",
    Plans_RPU_ChangeTo: "Ändra till {0}",
    Plans_RPU_ConfirmCBLabel: "Jag bekräftar att mitt företag är {0} och mitt organisationsnummer är {1}",
    Plans_RPU_DowngradeTo: "Nedgradera till {0}",
    Plans_RPU_EndOfYear_CampaignHeading: "Nu när julen nalkas, är det onödigt att med bokföringen fightas. Vad har Bokio nu hittat på? Jo, 30% rabatt du ska få",
    Plans_RPU_EndOfYear_CampaignMessage: "Passa på och teckna en plan innan årets slut – få 30% rabatt på Premium eller 20% på Premium Plus! Erbjudandet är riktat och gäller bolag som inte redan har någon prisplan och som inte heller redan är kopplat till någon användare som har en prisplan. Rabatten är giltig mellan den 15 december - 31 december 2023 och tillämpas endast på den första abonnemangs- och betalningsperioden.",
    Plans_RPU_EndOfYear_InfoHeading: "{0}% på första perioden",
    Plans_RPU_EndOfYear_InfoMessage: "Erbjudandet gäller t.o.m. 31 December",
    Plans_RPU_GetStarted: "Sätt igång",
    Plans_RPU_Heading_Single_Plan: "Uppgradera till {0}",
    Plans_RPU_Perks_Premium_BankServices: "Banktjänster",
    Plans_RPU_Perks_Premium_BokioBusinessAccount: "Bokio Företagskonto",
    Plans_RPU_Perks_Premium_Heading: "Inkluderar:",
    Plans_RPU_Perks_Premium_MoreProfessionalInvoicing: "Proffsigare fakturering",
    Plans_RPU_Perks_Premium_SalaryServices: "Lönehantering",
    Plans_RPU_Perks_Premium_SmarterBookkeeping: "Smartare bokföring",
    Plans_RPU_Perks_PremiumPlus_CashbackCapital: "{0} återbäring på insatt kapital",
    Plans_RPU_Perks_PremiumPlus_CashbackCardSpend: "{0} cashback på kortköp",
    Plans_RPU_Perks_PremiumPlus_FreeAccountingAssistance: "Gratis bokföringssupport",
    Plans_RPU_Perks_PremiumPlus_Heading: "Allt i Premium och:",
    Plans_RPU_Perks_PremiumPlus_PrioSupport: "Prioriterad support med livechat",
    Plans_RPU_Text: "**Trygg betalning med Stripe:** Endast kortbetalning accepteras.",
    Plans_RPU_UpgradeTo: "Uppgradera till {0}",
    Plans_SelectPlan: "Välj plan",
    Plans_TestCompany: "Som testbolag har ni tillgång till funktioner som kräver planen {0}. Det går inte att ändra plan för testbolag.",
    Plans_TrialPeriod: "Provperiod",
    Plans_UpcomingPayments: "Kommande betalningar:",
    Plans_UpdatePaymentDetails_Heading: "Ange kortuppgifter",
    Plans_UpgradeModal_Premium_Features: "### Det här ingår i Premium:\n- Bokio Företagskonto\n- Smarta bokföringsmallar\n- Egna bokföringsmallar\n- Automatisk bankimport från andra banker\n- Prioriterad support\n- Egen logotyp, färg, QR-kod och AVI-rad på fakturor\n- Lön- och utläggshantering\n- Automatisk avprickning av fakturor\n… och mycket mer!",
    Plans_UpgradeModal_Pro_Features: "### Det här ingår i Premium Plus:\nAllt i Premium och:\n- Cashback på kortköp\n- Återbäring på insatt kapital\n- Gratis bokföringssupport\n- Prioriterad support med live chat",
    Plans_UpgradeModal_YouNeedToUpgrade: "Du måste uppgradera din plan för att få tillgång till funktionen.",
    Plans_VerifyOrgNumber_Notice: "Vi kunde inte samla in dina företagsuppgifter. Vänligen uppdatera ditt organisationsnummer innan du kan se personliga erbjudanden och starta en prenumeration.",
    Plans_Yearly: "årsvis",
    PleaseUpdateTheseDetails: "Vänligen uppdatera dessa detaljer innan du går vidare",
    PricePlan_Purchase_Premium_Plus_Success_bba: "Du har nu full tillgång till cashback och bokföringssupport.",
    PricePlan_Purchase_Premium_Plus_Success_no_bba: "Du har nu tillgång till bokföringssupport. För att aktivera cashback behöver du skaffa Bokio Företagskonto.",
    PricePlan_Purchase_Success: "Du har aktiverat {0}.",
    PricePlan_Purchase_Success_Free: "Du har aktiverat {0}.",
    PricePlan_Purchase_Success_Get_BBA: "Skaffa företagskonto",
    PricePlan_Purchase_Success_OnTrial: "Du har uppgraderat till {0}. Din nya plan kommer att aktiveras när din provperiod är över.",
    PricePlan_Purchase_Success_Undo_button_text: "Kanske senare",
    ReadMoreAboutBokioPartner: "Läs mer om Bokio Partner",
    Reason: "Anledning",
    RemoveImport_ContactSupport: "Kontakta {support@bokio.se} om du har känner dig osäker.",
    RemoveImport_Feedback_Title: "Har du någon feedback på flödet för att ta bort importerade verifikat?",
    RemoveImport_Menu: "Ta bort importerade verifikat",
    RemoveImport_SubHeaderText: "Här kan du hantera importerade verifikat för relevanta räkenskapsår. För att rätta dina bokningar har du möjligheten att ta bort oönskade verifikat för att försäkra dig om att din bokföring är korrekt.",
    RemoveImportedVerifications_SuccessMessage: "{0}-importerade verifikat för räkenskapsår {1} har tagits bort.",
    RemoveImportedVerifications_SuccessTitle: "Importerade verifikat har tagits bort",
    RemoveImportModal_Text: "Är du säker på att du vill ta bort **verifikat för {0}**? Uppmärksamma att det inte går att ångra denna åtgärden. Om du vill ha en säkerhetskopiering, gör först en export av bokföringen för detta räkenskapsåret.",
    RemoveImportModal_Title: "Ta bort importerade verifikat",
    RequestAQoute_HireAnAccountant: "Anlita en konsult",
    Result: "Resultat",
    RPU_PaymentConditions: "Läs mer om betalningsvillkor här",
    RPU_PeriodWarning_Cancellation: "**Uppsägning av prenumeration:** Det går inte att avsluta en prenumeration i förtid men du kan säga upp den inför nästa betalningsperiod. Prisplanen och din åtkomst fortlöper tills perioden är slut. Uppsägning måste ske senast dagen innan en ny betalningsperiod påbörjas. Inga återbetalningar görs.",
    RPU_PeriodWarning_RefundsAndAutomaticRenewal: "**Automatisk förnyelse:** Prenumerationen förnyas automatiskt när betalningsperioden (kvartal eller år) gått ut och om ingen uppsägning gjorts.",
    RPU_PeriodWarning_UsageFees: "**Rörliga kostnader:** Kostnader som varierar baserat på din användning dras automatiskt månadsvis i efterskott. [Se detaljerad prislista här.](https://www.bokio.se/priser/#prisplaner)",
    RPU_ReadMoreAboutPremiumPlus: "Lär dig mer om Premium Plus",
    SelectCountry_Placeholder: "Välj land",
    Settings_CompanyColor_heading: "Företagsfärg",
    SIEExport_DidYouKow_InviteAccountantNotice_Message: "Visste du att du kan bjuda in din redovisningskonsult i ditt Bokio-konto?",
    SIEExport_DidYouKow_YearEndNotice_Message: "Visste du att Bokio nu tillhandahåller bokslutstjänster?",
    SIEExport_FeedbackHeading: "Exportera SIE-filer",
    SIEExport_FeedbackMessage: "Varför exporterar du SIE-filerna?",
    SIEExport_FeedbackOption_AccountantUsingOtherSoftware: "Min redovisningskonsult använder en annan programvara",
    SIEExport_FeedbackOption_BokioIsMissingFunctionality: "Bokio saknar funktionalitet som mitt företag behöver",
    SIEExport_FeedbackOption_DownloadBackup: "För säkerhetskopiering",
    SIEExport_FeedbackOption_Other: "Övrig",
    SIEExport_FeedbackOption_ToCreateYearEndInOtherSoftware: "För att göra bokslut i en annan programvara",
    SIEExport_SubmitAndExport_Button: "Skicka och exportera",
    StartBonus_text: "startbonus",
    StepXOfY: "Steg {0} av {1}",
    SubmitInterest_Acknowledgment_AllowPartnerAgency: "Genom att skicka in denna förfrågan tillåter du byrån att se er bokföring för att ta fram den bästa möjliga offerten för dig.",
    SubmitInterest_Acknowledgment_BokioIsIntermediary: "Bokio agerar endast som en mellanhand mellan dig och byrån utan något ytterligare ansvar.",
    SubmitInterest_Acknowledgment_InfoIsCorrect: "Alla uppgifter är korrekta.",
    SubmitInterest_Info_IntegratedWithAO: "Integrationen med Årsredovisning Online är gratis, men eventuella kostnader för tjänsterna är separata från Bokio.",
    SubmitInterest_Info_NEBilaga: "NE-bilaga ingår om du uppgraderar till {premium årsplan}.",
    SubmitInterest_Info_SubmitIsFree: "Det är kostnadsfritt att lämna in denna begäran.",
    SubmitInterestAgency: "Anmäl intresse",
    Subscription_FAQ_Answer_1: "Vilken prisplan du ska välja beror på vilka fördelar du vill få för ditt företag. Alla funktioner såsom bokföring, banktjänster, fakturering, lönehantering och rapportering ingår oavsett om du väljer Premium eller Premium Plus.\n\nSkillnaden mellan planerna är att du med Premium Plus har möjlighet att tjäna pengar på ditt företagskonto. Premium Plus ger dig nämligen återbäring (f.n. **[{0}%]**) på ditt kontosaldo och {1}% cashback på alla kortköp. Återbäringen fungerar och beräknas på samma sätt som ränta. Till skillnad från de flesta banker får du en fördelaktig återbäring utan tak utan att behöva låsa dina pengar. Du har fria uttag och återbäringen betalas ut månadsvis till ditt Bokio Företagskonto.\n\nBeroende på ditt kontosaldo kan du alltså tjäna pengar direkt i din bokföringstjänst! Använd kalkylatorn längre upp på sidan för att beräkna din återbäring. Med Premium Plus får du dessutom gratis bokföringssupport där du har möjlighet att få hjälp med bokföringsfrågor direkt i Bokio.\n\n[Jämför våra planer mer i detalj här](https://www.bokio.se/priser/#prisplaner)",
    Subscription_FAQ_Answer_2: "Nej, i Bokio finns ingen bindnings- eller uppsägningstid utöver själva betalningsperioden, dvs. Den period du betalar för när du tecknar en plan. Prenumerationen förnyas däremot automatiskt om uppsägning inte sker senast dagen innan en ny betalningsperiod påbörjas.\n\nVäljer du att betala årsvis, så betalar du för 12 månader direkt. Väljer du kvartalsvis prenumeration så betalar du för 3 månader. Varje betalning täcker hela perioden under vilken du har tillgång till Bokios tjänster. Det går inte att avsluta en prenumeration i förtid men du kan säga upp den inför nästkommande betalningsperiod. Om du inte säger upp din prenumeration senast dagen innan en ny betalningsperiod påbörjas så förlängs det automatiskt med samma period som tidigare.\n\n[Mer information om betalningsvillkor hittar du här](https://www.bokio.se/priser/#villkor).",
    Subscription_FAQ_Answer_3: "För att öppna Bokio Företagskonto måste du först teckna en plan. Därefter ansöker du enkelt om företagskontot under Bank i vänstermenyn. Vid ansökan får du välja om du vill inkludera bankgiro i tjänsten och där också ha möjlighet att flytta ett redan befintligt bankgiro till Bokio Företagskonto om du skulle ha det. När allt är klart kommer du kunna sköta dina betalningar i Bokio och beställa ett företagskort.\n\nBokio Företagskonto går att ansöka om för dig med aktiebolag (AB), enskild firma (EF), och handelsbolag (HB). [Läs mer frågor och svar om Bokio Företagskonto här](https://www.bokio.se/hjalp/bokio-foretagskonto-bankkoppling/bokio-foretagskonto/vanliga-fragor-om-bokio-foretagskonto/).",
    Subscription_FAQ_Answer_4: "För att kunna använda Bokio måste du ha en aktiv prenumeration. Om du inte längre vill använda Bokio och säger upp din prenumeration är det viktigt att du själv hämtar ut all data från Bokio som du kan komma att behöva framöver.",
    Subscription_FAQ_Answer_5: "Premium går att teckna som både kvartals- och årsprenumeration. Vill du ändra från årsvis till kvartalsvis betalning går detta att göra fram till senast dagen innan nästa betalningsperiod börjar. Ändringen går först igenom efter att din årsprenumeration är slut. Har du däremot kvartalsprenumeration och ändrar till årsvis betalning så kommer betalning och aktivering av den nya prenumerationen gå igenom direkt. Det resterande beloppet som återstår för din betalningsperiod kommer att dras av från priset för årsprenumerationen. Observera att Premium Plus endast går att teckna som kvartalsprenumeration.",
    Subscription_FAQ_Answer_6: "Ja, du kan uppgradera till Premium Plus direkt oavsett om du har Premium med kvartalsvis- eller årsvis betalning. Vid betalningen kommer det resterande beloppet som återstår för din betalningsperiod (per månad) att dras av från ditt pris för Premium Plus (per kvartal).\n\n[Läs mer i detalj här](https://www.bokio.se/hjalp/bokio-medlemskap/prisplaner/vanliga-fragor-om-prisplaner/#uppgradera)",
    Subscription_FAQ_Answer_7: "För att vara säker på att din prenumeration avslutas bör uppsägning ske av dig vid inloggat läge och inte via mejl senast dagen innan en ny betalningsperiod börjar. Du kan när som helst säga upp din prenumeration. Prisplanen och din åtkomst fortlöper tills betalningsperioden är slut. Betald prenumeration återbetalas inte.   Observera att uppsägning av Bokio Företagskonto och uppsägning av prenumeration av prisplan är skilda processer. Om du har Bokio Företagskonto och vill säga upp din prenumeration behöver du först avsluta ditt företagskonto.",
    Subscription_FAQ_Answer_8: "Ja, återbäringen (i %) är garanterad under hela prenumerationsperioden (dvs. betalningsperioden), med undantag vid höjning. Skulle återbäringen sänkas påverkar det dig alltså först vid nästa period.  Precis som med rörlig ränta hos andra svenska banker så justeras återbäringen (%) max en gång var tredje månad (med undantag vid höjd återbäring). Den aktuella återbäringen i % eller justeringen reflekteras av rådande marknadsläge och vår bankpartner Sveas utsikter för framtida räntejusteringar på marknaden. Bokio tillsammans med Svea kommer alltid sträva efter att erbjuda den bästa återbäringen svenska småföretagare kan få på sitt transaktionskonto.\n\n[Läs mer om återbäringen här](https://www.bokio.se/hjalp/bokio-medlemskap/prisplaner/vanliga-fragor-om-premium-plus/)",
    Subscription_FAQ_Answer_9: "Ja, vid byte från Premium Plus till Premium genomförs ändringen efter avslutad betalningsperiod. Återbäringen och cashback på Bokio Företagskonto gäller under hela den återstående perioden av din prenumeration på Premium Plus.",
    Subscription_FAQ_externalLink: "Läs svar på fler frågor här",
    Subscription_FAQ_heading: "Vanliga frågor om våra prenumerationer",
    Subscription_FAQ_Question_1: "Hur vet jag vilken prisplan jag ska välja?",
    Subscription_FAQ_Question_2: "Finns det någon bindnings - och uppsägningstid för Bokios prisplaner?",
    Subscription_FAQ_Question_3: "Hur öppnar jag företagskonto i Bokio?",
    Subscription_FAQ_Question_4: "Hur kommer jag åt min bokföring?",
    Subscription_FAQ_Question_5: "Kan jag ändra betalningsperiod?",
    Subscription_FAQ_Question_6: "Blir jag återbetald för min resterande betalningsperiod med Premium om jag uppgraderar till Premium Plus?",
    Subscription_FAQ_Question_7: "Hur säger jag upp min prenumeration?",
    Subscription_FAQ_Question_8: "Är återbäringen (%) låst under hela prenumerationsperioden?",
    Subscription_FAQ_Question_9: "Kan jag nedgradera från Premium Plus till Premium?",
    TagAdmin_AddOrEditAction: "Lägg till eller ändra taggar",
    TagTypes_AddNewTagType: "Lägg till ny taggrupp",
    TagTypes_AddTagsToCostPool: "Lägg till kostnadsställen",
    TagTypes_AddTagsToGroup: "Lägg till taggar i gruppen",
    TagTypes_AddTagsToProjects: "Lägg till projekt",
    TagTypes_AllowedValueLabel: "Tagg",
    TagTypes_Badge: "{0} tagg(ar)",
    TagTypes_Badge_Plural: "{0} taggar",
    TagTypes_CreateCustomTagType: "Skapa egen taggrupp",
    TagTypes_CreateGroup: "Skapa en ny grupp",
    TagTypes_CreateTagType: "Skapa taggrupp",
    TagTypes_DeleteTagTypeInfo: "När taggruppen tas bort så tas även de relaterade tillåtna taggarna bort.",
    TagTypes_DeleteUsedAllowedValueError: "Kunde inte ta bort taggen då den används. Om du behöver radera denna kan du kontakta support.",
    TagTypes_DeleteUsedTagTypeError: "Kunde inte ta bort taggruppen då den används. Om du behöver radera denna kan du kontakta support.",
    TagTypes_DuplicateAllowedValue: "Kunde inte spara. Detta värde används redan.",
    TagTypes_DuplicateDimension: "Kunde inte spara. Denna fördefinerade grupp används redan.",
    TagTypes_DuplicateQuickAlias: "Kunde inte spara. Detta prefix används redan.",
    TagTypes_EditTagType: "Uppdatera Taggrupp",
    TagTypes_Example_Header: "Projekt",
    TagTypes_Example_Item: "Projekt {0}",
    TagTypes_HideExample: "Dölj exempel",
    TagTypes_Name: "Gruppens namn",
    TagTypes_PlaceHolder: "T.ex. Projekt",
    TagTypes_PredefinedPicker_CostPool: "Kostnadsställen",
    TagTypes_PredefinedPicker_CostPool_Detail: "Kostnadsställen är för dig som vill kunna följa upp kostnader inom bolaget, exempelvis på team eller avdelningar.",
    TagTypes_PredefinedPicker_Custom: "Egen",
    TagTypes_PredefinedPicker_Custom_Detail: "Sätt fritt upp dina egna taggar för att kunna följa upp på andra sätt. Se exempel.",
    TagTypes_PredefinedPicker_Projects: "Projekt",
    TagTypes_PredefinedPicker_Projects_Detail: "Projektuppföljning är för dig som har flera projekt som du behöver kunna jämföra och följa upp var för sig.",
    TagTypes_QuickAlias: "Prefix",
    TagTypes_QuickAlias_Extrainfo: "Välj ett tecken som du kan använda för att snabbtagga saker i denna gruppen. T.ex '$' för projekt och '@' för personer",
    TagTypes_ShowExample: "Visa exempel",
    TagTypes_SubTitle: "Taggar kan användas för projektredovisning, kostnadsställen och att analysera andra saker i mer detalj",
    TagTypes_Title: "Tagginställningar",
    TOTP_ActivateText: "Autentiseringsappen ska nu ge dig en 6-siffring kod för Bokio. Fyll i den här för att verifiera och gör klart processen!",
    TOTP_BackupText: "Om du byter din mobil eller på annat sätt förlorar din 2FA-koppling så behöver du backup-nyckeln för att behålla tillgång till ditt konto. Ladda ner och lagra återställningsdokumentet på ett säkert ställe.",
    TOTP_BackupTitle: "Glöm inte att spara din säkerhetskod!",
    TOTP_ConfirmBackupLabel: "Jag förstår att om jag förlorar min 2FA-koppling, så blir jag utlåst från kontot om jag inte laddade ner och sparade återställningsdokumentet!",
    TOTP_Continue: "Fortsätt",
    TOTP_DeactivateIntro: "För att avaktivera 2-faktor-autentisering, fyll i ditt lösenord och verifikationskoden (från din authenticator-app). ",
    TOTP_DeactivateTitle: "Avaktivera 2-faktor-autentisering",
    TOTP_Disable: "Avaktivera",
    TOTP_DisableAction: "Avaktivera 2-faktor-autentisering",
    TOTP_DownloadAndContinue: "Ladda ner och fortsätt",
    TOTP_DownloadApp: "Ladda ner 2FA-appen",
    TOTP_DownloadApp_Text: "Du måste ladda ner en app som genererar unika koder för tvåfaktorsverifiering till din telefon. Vi föreslår en av dessa:",
    TOTP_Enable: "Aktivera",
    TOTP_EnableTwoFactor: "Aktivera 2-faktor-autentisering",
    TOTP_OrManuallyEnter: "Eller skriv manuellt in koden:",
    TOTP_ReadMore: "&lt;a\n target=\"_blank\"\n rel=\"noopener noreferrer\"\n href=\"https://www.bokio.se/hjalp/bokio-medlemskap/ditt-bokio-konto/tvafaktor-autentisering-med-bokio/\"\n &gt;Läs mer om 2FA&lt;/a&gt;",
    TOTP_ScanCode: "Scanna QR-koden",
    TOTP_ScanCode_Text: "Öppna appen du just installerade och använd den för att scanna QR-koden för att koppla ihop appen med Bokio.",
    TOTP_SuggestedAppsTitle: "Vi rekommenderar en av dessa:",
    TOTP_Text: "Genom att aktivera 2-faktor-autentisering så blir ditt konto mycket säkrare. När du loggar in så kommer vi kräva en kod genererad av din telefon utöver ditt lösenord.",
    TOTP_Title: "2-faktor-autentisering",
    TOTP_Title_Activated: "Ditt konto är skyddat med 2-faktor-autentisering",
    TOTP_YourBackupCode: "Din säkerhetskod",
    Turnover_0To1: "Upp till 1 miljon",
    Turnover_1To40: "1 till 40 miljoner",
    Turnover_40ToX: "Över 40 miljoner",
    UpdateUserName: "Uppdatera din e-post",
    UpdateUsername_ConfirmInstructions: "Vi har skickat ett e-postmeddelande till dig med en kod (kolla skräpposten). Klicka antingen på länken i e-postmeddelandet eller ange koden nedan.",
    UpdateUserName_ConfirmNewEmail: "Bekräfta ny e-post",
    UpdateUsername_ConfirmTitle: "Verifiera din nya e-post",
    UpdateUserName_CurrentEmail: "Nuvarande e-post",
    UpdateUsername_Error_Completed: "Redan verifierad!",
    UpdateUsername_Error_EmailFailed: "Det gick inte att skicka verifieringsmailet!",
    UpdateUsername_Error_EmailNotAvailable: "Det existerar redan ett Bokio-konto med den valda e-postadressen!",
    UpdateUsername_Error_InvalidEmail: "Den valda eposten har inte ett giltigt format!",
    UpdateUsername_Error_NoLongerValid: "Koden har gått ut och kan inte längre användas!",
    UpdateUsername_Error_NoLongerValid_Action: "Gå tillbaka och försök igen",
    UpdateUsername_Error_WrongCodeOrRequest: "Koden eller länken var felaktig!",
    UpdateUsername_Error_WrongUser: "Den inloggade användaren skapade inte denna ändringen",
    UpdateUserName_Has2FA_Warning: "Du har för närvarande tvåfaktor-autentisering aktiverat. Det kommer att fortsätta att fungera om du byter e-post, men autentiseringsappen kommer visa den gamla e-posten. Om du vill åtgärda detta måste du inaktivera 2FA och sedan aktivera det igen efter att du bytt din e-post.",
    UpdateUserName_NewEmail: "Ny e-post",
    UpdateUserName_NewEmailHelp: "Den nya e-posten är den du kommer använda för att logga in med och kontakta support med i framtiden. Vi skickar också alla e-postmeddelanden till den här adressen.",
    User_Accountant: "Byrå och redovisningskonsult",
    User_AccountantDescrription: "Samarbeta med din redovisningskonsult i Bokio",
    User_Admin: "Affärspartner",
    User_AdminDescription: "Ge full Bokio-åtkomst till din affärspartner eller familjemedlem",
    User_Administration: "Adminbehörigheter",
    User_InviteAccountant: "Bjud in redovisningskonsult",
    User_InviteAdmin: "Bjud in din affärspartner",
    User_InviteNewUser: "Bjud in ny användare",
    User_InvitePayrollManager: "Bjud in löneadministratör",
    User_InviteUser: "Bjud in användare",
    User_Other: "Annat",
    User_OtherDescription: "Skräddarsy åtkomst och inställningar",
    User_PayrollManager: "Löneadministratör",
    User_PayrollManagerDescription: "Behörighet att hantera lönekostnader, anställdas löner samt utlägg",
    User_SalariesAndExpenses: "Löner och utlägg",
    UserRole_Error_TryingToTransferRoleUserDoesntHave: "Du måste ha rollen som du försöker överföra till någon annan",
    Users_ActiveUsers: "Aktiva användare",
    Users_AddAccountant: "Lägg till redovisningskonsult",
    Users_AddUser: "Lägg till användare",
    Users_AgencyPermissions_Header: "Byrå behörigheter",
    Users_AgencyPermissions_Tooltip: "När en redovisningskonsult läggs till från byrån kommer den få dessa behörigheter. Behörigheterna för byrån kan du ändra närsomhelst.",
    Users_AreYouSureYouWantToCancelTheInvite: "Är du säker på att du vill avbryta inbjudan till ",
    Users_AreYouSureYouWantToDeleteYourUser: "Är du säker på att du vill ta bort användaren ",
    Users_CancelInvite: "Avbryt inbjudan",
    Users_DeleteUser: "Radera användare",
    Users_EditPermissionsFor: "Redigera behörighet för",
    Users_InvitationPending: "Inbjudan väntar",
    Users_NoPermission: "Du har inte behörighet att se denna sida. Kontakta administratören för företaget. Om du behöver hjälp, kontakta support.",
    Users_OffboardAgency: "Koppla från byrå",
    Users_SendInvite: "Skicka inbjudan",
    Users_UnableToDeleteBbaOwner: "Denna användare har ett aktivt Bokio Företagskonto. Kontakta support för att få hjälp med att ta bort användaren.",
    Users_UnableToDeleteOwner: "Denna användaren äger företaget. Användaren måste först överföra ägandeskapet innan den kan bli borttagen.",
    Users_UnableToDeleteSelf: "Kan inte radera dig själv från administratörssidorna. Ta bort dig själv från företaget istället.",
    UsersScene_NoAssignedAccountants: "Inga revisorer är för närvarande tilldelade ditt företag",
    YourInboxAddressIs: "Adressen till din kvittoinkorg är",
  };
}