/* eslint-disable */

import type { PartnersLang } from "../types/PartnersLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function PartnersLangDef(): PartnersLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    Action_AddUser: "Lägg till användare",
    Action_DeleteInvite: "Ta bort inbjudan",
    Action_SendInvite: "Skicka inbjudan",
    ActionAdd: "Lägg till",
    ActionEdit: "Redigera",
    ActionRemove: "Ta bort",
    Actions: "Åtgärder",
    ActionSearch: "Sök",
    Companies: "Företag",
    Company: "Företag",
    CompanyOrg: "Org Nr.",
    CreatePartner: "Skapa partner",
    CreatePartnerAction: "Skapa",
    Invitations: "Inbjudningar",
    InvitedUsers: "Användare som fått inbjudan",
    InviteUsers: "Bjud in användare",
    MyCustomers: "Mina kunder",
    Partner: "Partner",
    PartnerName: "Namn",
    PartnerNamePlaceholder: "Partnernamn",
    Permissions: "Rättigheter",
    Receipts: "Kvitton",
    Settings: "Inställningar",
    ToRegister: "Att bokföra",
    UserEmail: "Email",
    UserEmail_Label: "Användarens email",
    UserName: "Namn",
    Users: "Användare",
  };
}