/* eslint-disable */

import type { ExpensesLang } from "../types/ExpensesLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function ExpensesLangDef(): ExpensesLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    Accepted: "Accepterad",
    ActiveClaims: "Pågående utlägg",
    AddExtraPage: "Lägg till sida",
    AFewMoreDetails_message: "Vi behöver bara några fler uppgifter innan du kan komma igång med utlägg.",
    AllAccounts: "Alla konton",
    AllEmployeesConnected: "Alla anställda är kopplade till användare.",
    AllExpenses: "Alla utlägg",
    AllExpensesForCompany_message: "Här finner du alla utlägg för ditt företag",
    AlmostThere_heading: "Snart klar!",
    Amount: "Belopp",
    AmountInSek_label: "Belopp i SEK",
    Approved_status: "Godkänd",
    AreYourSureUndoBookkeeping_text: "Är du säker på att du vill ångra bokföringen för ",
    BankAccountNo: "Bankkontonummer.",
    BBAPaymentOption_Description: "Sköter betalningarna och den följande bokföringen helt automatiskt. Du behöver bara signera betalningen.",
    BookkeptAs: "Bokförd som",
    BookkeptBy: "Bokfört av",
    CanRequestPermissionEmployerAdmin: "Du kan be din arbetsgivare eller administratör om det.",
    ChangePermissions: "Ändra dina rättigheter",
    ClaimDate: "Underlag uppladdat",
    Claimer: "Utlägget gjort av",
    Claims: "Utlägg",
    CompanyCards_Description: "Företagskort är kreditkort där företaget betalar fakturan och har ansvaret. Utlägg gjorda med dessa kort betalas inte ut till den anställda, vilket är anledningen till att hantera dessa annorlunda.",
    CompanyCards_Title: "Företagskort",
    Completed: "Klar",
    ConfirmDelete_heading: "Bekräfta borttagning",
    ConfirmDelete_message: "Vill du verkligen radera ditt utlägg?",
    Connect_AddEmployeeAction: "Skapa anställd",
    Connect_Info: "För att kunna skapa utlägg så behöver personen ha en användare (en inloggning) i företaget och vara kopplad till en anställd. De behöver också rättighet att ladda upp utlägg. Notera: Vi kallar det anställda här men det kan också vara styrelsemedlemmar eller andra personen kopplade till bolag som ofta gör utlägg.",
    Connected: "Kopplad",
    ConvertToExpense: "Flytta underlag till utlägg",
    ConvertToExpense_Blocked_BankConnection: "Underlaget är kopplat till en bankrad och kan inte flyttas till utlägg",
    ConvertToExpense_Blocked_Employees: "Du har ingen anställd som vi kan flytta utlägget till",
    ConvertToExpense_Blocked_Expense: "Underlaget är redan ett utlägg",
    ConvertToExpense_Blocked_Recorded: "Underlaget kan är redan bokfört och kan inte flyttas till utlägg",
    ConvertToExpense_Blocked_SupplierInvoice: "Underlaget är kopplat till en leverantörsfaktura och kan inte flyttas till utlägg",
    ConvertToExpense_Description: "När du flyttar detta underlaget kopplas det först till dig. Du kan sedan flytta det till en annan anställd.",
    ConvertToReceipt_Action: "Flytta till bolagets underlag",
    ConvertToReceipt_Confirm: "Ja, flytta det",
    ConvertToReceipt_Description: "Detta komma att flytt utlägget till bolagets underlag istället. Det kommer inte längre betalas tillbaka till dig.",
    CreateEmployee_action: "Lägg till anställd",
    CreateExpense_heading: "Skapa utlägg",
    CreateExpense_SaveAsDraft: "Gör senare",
    CreateNewEmployee_action: "Ny anställd",
    CreateNewExpense_text: "Skapa ett utlägg genom att ladda upp ett kvitto. Inga mer glömda utlägg!",
    Date: "Datum",
    DaysOverdue: "dagar sen",
    Delete_DisabledActionDescription: "Utlägg som är bokförda eller planerade för betalning måste tas bort från dessa ställen först",
    DescribeYourReceipt: "Beskriv vad utlägget gäller",
    Description_label: "Beskrivning",
    Details: "Detaljer",
    Disbursement_CancelPayment: "Avbryt betalning",
    Disbursement_DeleteInfo: "Om du behöver ändra något av dessa utlägg eller deras bokföring behöver du första ångra bokföringen av utbetalningen och sedan ta bort betalningen. Det är ofta enklare att bokföra en manuell justering av utlägget istället.",
    Disbursement_PaymentMethod: "Betalsätt",
    Disbursement_ScheduledBy: "Planerad av {0}",
    Disbursements_GoAndSign: "Signera betalningar",
    Disbursements_NeedSign: "Dessa betalningar behöver signeras",
    Disbursements_PaymentsTitles: "{0} betalningar",
    Disbursements_SignedMessage: "Dessa betalningar är signerade och kommer att betalas",
    DueIn: "Betalning om ",
    DueToday: "Betalning idag",
    EditExpense_action: "Redigera",
    EditExpenseScene_ExpenseAccount_Label: "Hur betalade du utlägget?",
    EditUser: "Redigera användare",
    Employee: "Anställd",
    Employees: "Anställda",
    Expense: "Utlägg",
    Expense_DeleteInfo: "Om du behöver ändra detta utlägg eller dess bokföring behöver du första ta bort betalningen det är kopplat till. Ofta är det enklare att bokföra en justering manuellt.",
    Expense_Form_EmployeeForExpense: "Anställd detta gäller",
    ExpenseAcconts_Active: "Aktivt",
    ExpenseAcconts_AddAccount: "Lägg till kort",
    ExpenseAcconts_DeleteAccount_Description: "Om utlägg är kopplade till kortet arkiveras det. Annars tas det bort permanent.",
    ExpenseAcconts_Title: "Mina kort",
    ExpenseAcconts_Type: "Typ",
    ExpenseAccount_Deactivate: "Avaktivera",
    ExpenseAccount_Label: "Betalad med",
    ExpenseAccounts_Active: "Aktivt",
    ExpenseAccounts_CompanyCC: "Kreditkort, företag",
    ExpenseAccounts_CompanyCC_Description: "För kreditkort där bolaget betalar fakturan.",
    ExpenseAccounts_CompanyDC: "Debitkort, företag",
    ExpenseAccounts_Deactivated: "Avaktiverad",
    ExpenseAccounts_DefaultTitle: "Egna pengar",
    ExpenseAccounts_Information: "Om du gör utlägg med olika kort och lägger till dessa här så kan du filtrera dina utlägg på kort sedan, det gör det lättare att verifiera att du lagt in alla dina utlägg. För företagskreditkort så administreras dessa under inställningar av en administratör.",
    ExpenseAccounts_PrivateCard: "Privat kort",
    ExpenseAccounts_PrivateCard_Description: "För privata kort eller anndra konton där företaget ska ersätta dig för utlägget.",
    ExpenseAccounts_Reactivate: "Återaktivera",
    ExpenseDate_label: "Utläggsdatum",
    ExpenseDetails: "Utläggsdetaljer",
    ExpensePayment: "Utbetalning",
    ExpensePermissions_heading: "Utläggsrättigheter ",
    Expenses_heading: "Utlägg",
    Expenses_Planned: "Planerad",
    Expenses_text: "utlägg",
    ExpensesAwaitingAttest_heading: "Utlägg som inväntar attestering",
    ExpensesAwaitingPayment_heading: "Utlägg att betala",
    ExpensesDrafts_heading: "Utkast",
    ExpenseSettingsTitle: "Sätt tidigaste bokföringsdatum för utlägg",
    ExpensesPayments_heading: "Utbetalningar",
    FilterEmployees_All: "Alla anställda",
    FilterExpenseStatus_All: "Alla statusar",
    FilterExpenseStatus_Pending: "Pågående",
    forPayment: "för betalning",
    GoToMyExpenses_action: "Gå till mina utlägg",
    HmmNoExpensesSystem: "Hmm, det verkar inte finnas några utlägg registrerade. Sätt igång genom att skapa ett utlägg.",
    ImDone: "Jag är färdig",
    InviteEmployee_action: "Bjud in anställd",
    InviteEmployees_action: "Bjud in anställda",
    InviteEmployeesExpenses: "Bjud in anställd att göra utlägg",
    InvitePending: "Inbjudan skickad",
    ManagePeople: "Hantera personer",
    ManageRights_action: "Hantera rättigheter",
    ManualPaymentOption: "Manuell betalning",
    ManualPaymentOption_Description: "Du behöver betala dessa manuellt och sedan bokföra dessa manuellt.",
    ManualPaymentOption_SwitchToTeller_Description: "Om du vill betala dessa automatiskt kan du ångra betalningarna och schemalägga dessa igen.",
    MatchingXOfYExpensesWithATotalOfZ: "Matchar {0} av {1} utlägg. Totalsumma: {2}",
    MinBookkeepingDate_HelpText: "Om detta datum är inställt kommer alla utlägg med ett tidigare datum bokföras på detta datum. Detta är användbart för att undvika att sent rapporterade utlägg bokförs på stängda perioder.",
    MustBeEmployee: "Du måste vara anställd för att skapa utlägg.",
    MyExpenses: "Mina utlägg",
    NewExpense_action: "Nytt utlägg",
    NoAccessExpensesPayments: "Du har inte behörighet att hantera utbetalningar för utlägg.",
    NoAccessViewAllExpenses: "Du har inte behörighet att se alla utlägg.",
    NoActiveExpenses: "Du har inga utlägg att ta hand om för tillfället.",
    NoEmployees: "Inga anställda.",
    NoExpensesEmptyState_message: "Har du utlägg du vill få betalt för? Ladda upp dina utlägg här för att komma igång",
    NoExpensesScheduled: "Det finns inga schemalagda utbetalningar för tillfället",
    NoExpensesWaitingSchedule_empty: "Inga utlägg behöver schemaläggas",
    NoPaidExpenses_empty: "Det finns ännu inga genomförda utbetalningar för utlägg.",
    NoPermissionUpload: "Du har ännu inte behörighet att ladda upp utlägg.",
    NoResultsChangeFilters: "Inga resultat. Prova att justera eventuella filter.",
    NotApproved_heading: "Ej godkända",
    NotEditable_Info: "Detta utlägg kan inte längre redigeras.",
    NoUsersWithoutEmployee: "Alla användare är kopplade till en anställd",
    NowEmployerApprove: "Nu ska din arbetsgivare godkänna utlägget och sedan betala ut det.",
    NowExpensesBookkeep: "Nu ska utlägget bokföras och utbetalningen planeras.",
    Onboarding_EmptyState_NotAdmin_CompanyOnboarded_message: "Hoppsan, det verkar som att utläggsfunktionen inte har aktiverats för din användare än. Vänligen be din administratör att aktivera den.",
    Onboarding_EmptyState_NotAdmin_message: "Hoppsan, det verkar som att ditt företag inte aktiverat utläggsfunktionen än. Vänligen be din administratör att aktivera den.",
    Onboarding_ForWho_Heading: "Vilka företag borde använda Utlägg?",
    Onboarding_ForWho_Who: "Utlägg i Bokio är för alla företag med anställda eller där flera personer gör inköp åt företaget.",
    Onboarding_ForWho_WhoNot: "Enskilda firmor använder normalt inte Utlägg. Om du är ägaren och den enda som gör inköp för bolaget är det enklare att hantera detta i vanliga bokföringsflödet.",
    Onboarding_GetStarted_Heading: "Hur kommer du igång?",
    Onboarding_GetStarted_Text: "Det första du behöver göra är att ställa in vilka som kan göra utlägg och vilka som kan administrera dessa. Klicka på knappen nedan för att komma igång.",
    Onboarding_Intro: "När en anställd gör ett köp med egna pengar har det traditionellt varit en massa tråkigt arbete både för medarbetaren och administratören att hantera detta. Med Bokios Utlägg behöver medarbetaren bara ta ett foto av kvittot direkt vid köpet, fylla i lite snabb info om köpet och sedan är de klara. Administratören får sedan dessa utläggen organiserade och redo att bokföras. Därefter kan de snabbt betala tillbaka utläggen till alla anställda med ett enda knapptryck.",
    Paid: "Utbetald",
    PaymentMehtod: "Automatisk betalning",
    PaymentScheduled_status: "Planerad utbetalning",
    ReadingReceipt_loading: "Läser underlag",
    RegisterAndBookkeep_action: "Registrera och bokför",
    RegisterBookkeepPayment: "Bokför utbetalning",
    RegisterPayment_action: "Bokför betalning",
    Rejected_status: "Ej godkänd",
    Schedule: "Schemalägg",
    ScheduledPaymentDate_DatePickerLabel: "Datum för utbetalning",
    ScheduledPayments_Description: "Dessa utbetalningar behöver du göra manuellt.",
    ScheduledPayments_Heading: "Schemalagda utbetalningar",
    ScheduleForPayment_Manual_Action: "Schemalägg {0} för manuell betalning",
    ScheduleForPayment_Teller_Action: "Betala {0} med Bokio Företagskonto",
    SeePaidOrRejected: "utlägg för att se utbetalda eller ej godkända utlägg.",
    SendInvites_action: "Skicka {0} inbjudningar",
    SettingsPage_MinDate_Label: "Det tidigaste datum som utlägg kan bokföras på",
    SettingsPage_Title: "Inställningar för utlägg",
    SpecifyEmailBeforeInviting: "Ange e-post",
    StartUsingExpenses_heading: "Utlägg - Förenkla anställdas inköp",
    Status: "Status",
    SubmitExpense_action: "Skapa utlägg",
    SubmitExpense_editAction: "Redigera utlägg",
    SubmitForBookkeeping_action: "Skicka för bokföring",
    SuccessEditExpense: "Utlägget är nu redigerat!",
    SuccessExpense: "Ditt utlägg har skickats in!",
    TellerScheduledPayments_Description: "Dessa betalas automatiskt med Bokio Företagskonto.",
    TellerScheduledPayments_Heading: "Kommande automatiska betalningar",
    temp: "test",
    Today: "Idag",
    TotalPayment: "Total utbetalning",
    TotalSum: "Totalt belopp",
    Unattended_status: "Obehandlad",
    UndoBookkeeping_action: "Ångra bokföring",
    UndoExpensePayment_MovedToOtherTab_text: "Den kommer att flyttas till {0} under fliken {1}.",
    Upload_FileTypeRestrictions: "(Endast jpg- png- eller pdf-filer)",
    Upload_Instruction_Desktop: "Dra en bild hit eller klicka för att bläddra på din enhet",
    Upload_Instruction_Mobile: "Tryck här för att ta en bild eller ladda upp kvitto",
    Upload_PdfFileTooLarge: "Filen du försöker ladda upp är för stor. För tillfället stödjer vi bara PDF-filer upp till 4 MiB.",
    Upload_ProcessingImage: "Bearbetar bilden",
    Upload_UploadingExpense: "Laddar upp underlag",
    UploadAnother: "Ladda upp ett till",
    UploadExpense_action: "Ladda upp underlag",
    UploadFirstExpense_action: "Ladda upp första underlag",
    UserAccount: "Konto Användare",
    Users_Unconnected_Info: "Dessa användare är kopplade till ditt bolag men inte till någon anställd. Vill du att dessa ska kunna göra utlägg så kan du antingen skapa en ny anställd och koppla ihop dessa. Finns redan den anställda i listan ovan kan du klicka på den för att koppla ihop dessa.",
    UsersWithoutEmployees: "Användare utan kopplad anställd",
    ViewReceipt_action: "Se underlag",
    WaitingToAttestBookeep_action: "Öppna i Att göra",
    WaitingToAttestBookeep_info: "{0} utlägg väntar på att attesteras eller bokföras.",
    WhenThereAreExpenses: "När det finns utlägg som ska schemaläggas och betalas ut, kommer de synas här.",
    XOfNClaimsSelected: "{0} av {1} utlägg valda",
    YesDelete_action: "Ja, ta bort",
    YoureAboutToRegisterBookkeep_text: "Du håller på att bokföra utbetalningen om {0} till {1}.",
  };
}