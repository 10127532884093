/* eslint-disable */

import type { PayslipCalculationsLang } from "../types/PayslipCalculationsLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function PayslipCalculationsLangDef(): PayslipCalculationsLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    Error_HolidayNotPermamentEmployee: "Semesterlön är bara till för de med fast månadslön",
    UnitShouldBeDay: "Enheten ska vara 'dag'",
  };
}