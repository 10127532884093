/* eslint-disable */

import type { BackOfficeLang } from "../types/BackOfficeLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function BackOfficeLangDef(): BackOfficeLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    Accountant: "Redovisningskonsult",
    AccountantPanel_EmptyStateDesc_Comment: "Kommentarer för denna kund som du nämnts i eller som du själv skapat kommer att visas här.",
    AccountantPanel_EmptyStateDesc_Task: "Här visas pågående uppgifter som har tilldelats dig för den här kunden.",
    AccountantPanel_EmptyStateTitle_Comment: "Du har inga kommentarer än",
    AccountantPanel_EmptyStateTitle_Task: "Du har inga uppgifter än",
    AccountantPanel_MyTasks_Dropdown: "Mina uppgifter",
    AccountantPanel_Notes_CreatedBy: "{0} av {1}",
    AccountantPanel_OpenInBokioBackoffice: "Öppna i Bokio Byrå",
    AccountantPanelHeader: "Öppna Bokio Byrå för att hantera uppgifter och kommentarer. Informationen som visas i denna sidopanel är endast synlig för dig och inte för din kund.",
    Accountants: "Redovisningskonsulter",
    ActiveQuotesRequests_EmptyState_Description: "När du får offertförfrågan från ett företag i Bokio kommer de att dyka upp här.",
    ActiveQuotesRequests_EmptyState_Title: "Du har inga aktiva förfrågningar",
    AddCompany: "Lägg till företag",
    AddEditNote_AssignedTask_Notice: "Du kommer att kunna se om mottagaren har läst eller ändrat uppgiftens status.",
    AddEditNote_AssignedTo_Label: "Tilldelats",
    AddEditNote_DueDateCheckbox_Label: "Färdigdatum krävs inte",
    AddEditNote_ModalDescription_Add: "Detta skapar en ny {0} för din kund **{1}**",
    AddEditNote_ModalDescription_Add_QuoteRequest: "Detta kommer att skapa en ny {0} för offertförfrågan från **{1}**",
    AddEditNote_ModalDescription_Edit: "Detta kommer att redigera den befintliga {0} för din kund **{1}**",
    AddEditNote_ModalDescription_Edit_QuoteRequest: "Detta kommer att redigera den befintliga {0} för offertförfrågan från {1}",
    AddEditNote_ModalFooter_Create: "Skapa {0}",
    AddEditNote_ModalFooter_CreateAndSend: "Skapa och skicka {0}",
    AddEditNote_ModalTitle_Add: "Lägg till {0}",
    AddEditNote_ModalTitle_CreateNew: "Skapa ny {0}",
    AddEditNote_ModalTitle_Edit: "Redigera {0}",
    AddEditNoteModal_PlaceHolder: "Lägg till kommentar. Använd @ för att nämna någon.",
    AddEditNotes_AssigneeOption_Text: "{0} (Jag själv)",
    AddMe: "Lägg till mig",
    AddNewButton: "Lägg till ny ",
    AddPartner: "Lägg till ny byrå",
    AddToClients: "Lägg till i Kunder",
    AddUser: "Lägg till användare",
    AddUserByEmail_Placeholder: "Skriv in en e-postadress",
    Agency: "Byrå",
    Agency_ExportNotes: "Exportera anteckningar",
    Agency_ID: "ID",
    AgencyClientNotes_Filter_AccountantAndClient: "Redovisningskonsult och Kund",
    AgencyClientNotesTable_LatestUpdate: "Senaste uppdatering",
    AgencyClientNotesTable_ReadAt: "Läst kl {0}",
    AgencyClientOverview_ReadFullMessage: "Läs hela meddelandet",
    AgencyClientQuote_ConfirmRegistration: "Bekräfta registrering",
    AgencyClientQuote_ConfirmRejection: "Bekräfta nekad förfrågan",
    AgencyClientQuote_NoticeHeading: "Viss innehåll och funktionalitet är inte tillgängligt",
    AgencyClientQuote_NoticeText: "För att få tillgång till all företagsinformation och funktionalitet så måste du vara tilldelad kunden under *tilldelade kundansvariga* nedan.",
    AgencyClientQuote_RegisterClient: "Acceptera förfrågan",
    AgencyClientQuote_RegisterModalText: "Detta kommer registrera **{0}** som en aktiv kund i din byrå. Tilldelade redovisningskonsulter kommer att ha åtkomst till att hantera företagets bokföring.",
    AgencyClientQuote_RejectModal_Tip: "Du kan se arkiverade förfrågningar genom att filtrera på *‘visa arkiverade kunder’* på **offertförfrågningar** sidan.",
    AgencyClientQuote_RejectModalText: "Detta kommer neka och arkivera offertförfrågan från **{0}**.",
    AgencyClients_EditClientCompanyInvitation: "Redigera kundföretagsinbjudan",
    AgencyClients_ImportClient_Banner: "Du har tillgång till ett företag som inte är kopplat till den här byrån.",
    AgencyClients_ImportClients_Banner: "Du har tillgång till {0} företag som inte är kopplade till den här byrån.",
    AgencyClients_ManageClient: "Hantera kund",
    AgencyClients_RemoveDraft_Description: "Är du säker på att du vill ta bort kundföretaget och inbjudan till företagets ägare?",
    AgencyClients_RemoveDraft_Disclaimer: "Detta kommer att avbryta företagsägarinbjudan som skickats till {0} och radera kunden **{1}**. Om du vill ändra företagsägarens e-postadress eller skicka inbjudan igen kan du redigera inbjudan.",
    AgencyClients_RemoveDraft_Title: "Ta bort kundföretag och inbjudan",
    AgencyClientsComments_EmptyState_Message: "När du lägger till kommentarer till din kund kommer de att visas här",
    AgencyClientsNotes_Filter_MyNotes: "Visa bara mina anteckningar",
    AgencyClientsNotes_Filter_OnlyAssignedToMe: "Tilldelade till mig",
    AgencyClientsNotes_Filter_OnlyCreatedByMe: "Skapade av mig",
    AgencyClientsTasks_EmptyState_Message: "När du lägger till uppgifter till din kund kommer de att visas här",
    AgencyExportNotes_EmptyState: "Du har inga aktiva kunder nu.",
    AgencyExportNotes_TooltipInfo: "Endast revisorer som är tilldelade en viss kund kan exportera anteckningar.",
    AgencyImportClients_EmptyState: "Det finns inga företag att koppla just nu.",
    AgencyImportClients_Error_AccountantOwnsCompany: "Redovisningskonsulter i din byrå kan inte vara ägare av kundföretag.",
    AgencyImportClients_Error_ConnectedToAnotherAgency: "Minst ett företag är redan kopplat till en annan byrå.",
    AgencyImportClients_Error_NotEnoughPermissions: "Du saknar rättigheten att hantera användare i minst ett kundföretag.",
    AgencyImportClients_Errors_ManyCompanies_Title: "{0} av företagen går inte att lägga till som kund på grund av följande anledningar:",
    AgencyImportClients_Errors_OneCompany_Title: "Ett av företagen går inte att lägga till som kund på grund av följande anledning:",
    AgencyImportClients_Tooltip_AccountantOwnsCompany: "Du kan inte koppla detta företag till din byrå eftersom det ägs av en redovisningskonsult i din byrå.",
    AgencyImportClients_Tooltip_ConnectedToAnotherAgency: "Detta företaget är redan kopplat till en annan byrå.",
    AgencyImportClients_Tooltip_NotEnoughPermissions: "Du saknar rättigheten att hantera användare i det här kundföretaget.",
    AgencyNotes_Summary: "Visar {0} av {1}",
    AgencyNotes_ViewAll: "Visa alla",
    AgencyOverview_ViewAllTasks: "Visa alla uppgifter",
    AgencyPartner_AfterSignUp_CongratulationsMsg: "Grattis, du är nu en Byråpartner! Beroende på hur många kunder du har i Bokio hamnar din byrå på någon av de tre nivåerna: Brons, Silver eller Guld. Ju högre nivå du är på, desto större fördelar får du som Byråpartner.",
    AgencyPartner_AfterSignup_ExistingCustomers_Heading: "Existerande kunder i Bokio",
    AgencyPartner_AfterSignUp_ExistingCustomers_Text: "Det finns lite olika scenarion där du kan ha kunder kopplade till din användare i Bokio som ännu inte är tillagda i byråmiljön. Så här lägger du till dina kunder i ditt konto. {0}!",
    AgencyPartner_AfterSignUp_Heading: "Väx tillsammans med Bokio",
    AgencyPartner_AfterSignup_NewCustomers_Heading: "Nya kunder till Bokio",
    AgencyPartner_AfterSignUp_NewCustomers_Text: "Som Byråpartner får du provision för nya kunder i Bokio. Därför är det viktigt att de registreras via ditt byråkonto och 'Skapa kund'. {0}!",
    AgencyPartner_AfterSignUp_TierInfo: "Du befinner dig nu i {0}",
    AgencyPartner_AuthorityCheck_Accept: "Jag bekräftar att jag har behörighet att, för {0}'s räkning, godkänna villkoren för Bokio Byråpartner.",
    AgencyPartner_ConfirmAndSign_Button: "Bekräfta och signera",
    AgencyPartner_NameOfSignatory: "Firmatecknarens namn",
    AgencyPartner_SupportBox_Text: "Vill du komma i kontakt med vårt team för att veta mer om Bokio byråpartner? Kontakta oss på {0}",
    AgencyPartner_TermsAndCondition_Accept: "Jag har läst och accepterar de {0} för Bokio Byråpartner",
    AgencyPartnerTier_Bronze: "Brons",
    AgencyPartnerTier_Gold: "Guld",
    AgencyPartnerTier_Heading: "Byråpartner nivå",
    AgencyPartnerTier_Silver: "Silver",
    AgencyQuoteRequest_KeepNotes: "Behåll uppgifterna och kommentarerna för detta företag.",
    AgencyQuoteRequestDetails_HasBBA_Label: "Har Bokio Företagskonto",
    AgencySettings_AgencyInformation_Description: "Hantera din redovisningsbyråsuppgifter såsom, kontaktinformation och företagsinformation",
    AgencySettings_AgencyInformation_Title: "Redovisningsbyråsuppgifter ",
    AgencySettings_EditAgencyInformation_Title: "Redigera redovisningsbyråsuppgifter ",
    AgencySettings_ImportClients_Description: "Koppla kundföretag som du har tillgång till i Bokio till din byrå",
    AgencySettings_ImportClients_Title: "Koppla företag till din byrå",
    AgencySettings_Users_NoPermission: "Du har inte behörighet att se denna sida. Kontakta administratören för byrån.",
    AgencyUsers_EditPermissions: "Redigera rättigheter",
    AgencyUsers_ManageAccountant: "Hantera konsult",
    AgencyUsers_RemoveAccountant: "Ta bort konsult",
    AllAccountants: "Alla redovisningskonsulter",
    AllAssignees: "Alla uppdragstagare",
    AllClients: "Alla kunder",
    AllCompanies: "Alla företag",
    AllPricePlans: "Alla planer",
    AllStatuses: "Alla statusar",
    ApplicantEmail: "Sökandes email",
    Apply: "Applicera",
    ArchivedQuotesRequests_EmptyState_Description: "När du avslår en offertförfrågan från ett företag kommer de att dyka upp här.",
    ArchivedQuotesRequests_EmptyState_Title: "Du har inga arkiverade förfrågningar",
    AssignedSupportTickets_AssignedTo_Label: "Tilldelade till",
    AssignedSupportTickets_Checkbox: "Visa endast olästa",
    Assignee: "Uppdragstagare",
    AssignToMe_CheckBox: "Tilldela till mig",
    BackOffice_Name: "Back-office",
    Bank: "Bank",
    BokioAgency: "Bokio Byrå",
    BokioBusinessAccount: "Bokio Företagskonto",
    BokioForAccountants: "Bokio för redovisningskonsulter",
    BookkeepingSupport_Label_ShowingReceipts: "Visar: {0}/{1}",
    CancelInvitation: "Avbryt inbjudan",
    CancelInvitationModal_Content: "Är du säker på att du vill avbryta inbjudan för {0}?",
    CancelInviteModal_Title: "Avbryt inbjudan för {0}",
    ChangeClientPricePlan_Link: "Ändra prisplan",
    ChooseClientCompany: "Välj kundföretag",
    ChoosePractice: "Välj agentur",
    Client: "Kund",
    ClientDetails_AccountantPermissions_Info: "*Konsulträttigheterna hanteras av företagsägaren. Vänligen kontakta ägaren av företaget om ni önskar fler rättigheter.",
    ClientDetails_CannotAddAccountants: "Du har inte behörighet att lägga redovisningskonsulter till kunden. Kontakta byråns administratör för att få rätt behörigheter eller för att tilldelas kunden.",
    ClientDetails_Select_ChooseAccountant: "Välj redovisningskonsult",
    ClientDetails_Title_UserPermission: "Ingen konsult är tilldelad kunden",
    ClientOwnership_AgencyResponsibilities_Header: "Byråns ansvarsområden",
    ClientOwnership_AgencyResponsibilities_Paragraph: "Ange vilka ansvarsområden ni har för denna kund:",
    ClientOwnership_AgencyResponsibilities_Select: "Välj ansvarsområden",
    ClientOwnership_InviteClient_Paragraph: "Vänligen ange e-post och användarbehörigheter för ägaren av **{0}**",
    ClientOwnership_InviteClient_Title: "Bjud in kund och tilldela ägandeskap",
    ClientOwnership_Paragraph: "Vänligen ange information om personen som äger företaget du skapar.",
    ClientOwnership_Title: "Kund- och byråinformation",
    ClientQuotes_Scene_PageTitle: "Kundoffertförfrågningar",
    ClientRequests_AssignedAccountant: "Kundansvarig",
    ClientRequests_DeclineInvitation_Content: "Är du säker du vill neka den här förfrågningen?",
    ClientRequests_DeclineInvitation_Title: "Neka förfrågning",
    ClientRequests_Description: "Företagen nedan har skickat en förfrågan till en av era redovisningskonsulter för att bli kopplade till er byrå. Om förfrågan accepteras kommer konsulten att bli tillagd på företaget och byrån kommer att få tillgång till det.",
    ClientRequests_EmptyState: "När du får kundförfrågningar från företag i Bokio kommer de att dyka upp här. Just nu finns inga förfrågningar till din byrå.",
    Clients: "Kunder",
    Clients_ActiveClients: "Aktiva kunder",
    Clients_Checkbox: "Visa endast kunder som tilldelats mig",
    Clients_CreateClient_CTA: "Skapa kund",
    Clients_EmptyState: "Just nu finns det inget att visa. När du har skapat en kund kommer de att synas här.",
    Clients_PendingClients: "Kundinbjudningar",
    Clients_Tooltip_PendingClients: "När företagets ägare accepterat inbjudan kommer de angivna redovisningskonsulterna ha tillgång till företaget.",
    ClientSince: "Kund sedan",
    ClientSince_BeforeSept23: "Före sep. 23",
    Closure: "Bokslut",
    Comment: "Kommentar",
    Comment_TextField_Label: "Beskriv kommentaren",
    Comments: "Kommentarer",
    Comments_AssignedAccountantsCanView_Notice: "Endast kundansvariga för detta företag kan visa eller lägga till kommentarer",
    Comments_AssignedAccountantsCanView_QuoteRequest_Notice: "Endast kundansvariga för denna offertförfrågan kan visa eller lägg till kommentar",
    CompaniesInQueue_EmptyState_Description: "När ni har nya företag kommer de börja visas här.",
    CompaniesInQueue_EmptyState_Title: "Inget företag att visa",
    CompanyColumn_AssignedConsultant_Title: "Ansvarig",
    CompanyColumn_CompanyName_Title: "Namn",
    CompanyColumn_CompanyType_Title: "Typ",
    CompanyColumn_LastCompletedMonth_Title: "Sista avslutade månad",
    CompanyColumn_NextVatOn_Title: "Nästa momsperiod",
    CompanyColumn_Status_Title: "Status",
    CompanyColumn_TodoItems_Title: "Att göra",
    CompanyDetails_ApplicationDetails_Heading: "Applikationsinformation",
    CompanyDetails_Title: "Företagsinformation",
    CompanyInQueue_OnboardModal_Title: "Onboard - {0}",
    CompanyInQueue_OnboardNow: "Registrera nu",
    Consulting: "Konsultering",
    ContactUsOption_Description: "Hör av dig till vårt team för expertvägledning om hur du använder Bokio till sin fulla potential",
    ContactUsOption_Title: "Lär dig hur du använder Bokio",
    ContractAgreements: "Uppdragsbrev",
    ContractSigned: "Kontrakt signerat",
    Conversations: "Konversationer",
    Conversations_LastActivity: "Senaste aktivitet",
    Conversations_ViewAll: "Visa alla konversationer",
    Create_WhatCompany: "Vilket företag vill du skapa?",
    CreateAgency_Create_AgencyName: "Byrånamn",
    CreateAgency_Create_Preamble: "Skriv in dina redovisningsbyråsuppgifter manuellt för att slutföra ditt konto",
    CreateAgency_Create_Title: "Redovisningsbyråuppgifter",
    CreateAgency_Employee_Preamble: "För att fortsätta till vår lösning för redovisningsbyråer och redovisningskonsulter måste du först be din administratör att bjuda in dig till din redovisningsbyrå.",
    CreateAgency_Employee_Title: "Innan du kan fortsätta",
    CreateAgency_UserType_AdminDescription: "Skapa och hantera din byrå. Bjud in anställda och lägg till kunder du arbetar med.",
    CreateAgency_UserType_AdminTitle: "Skapa en byrå",
    CreateAgency_UserType_EmployeeDescription: "Du behöver få en inbjudan till en byrå för att kunna börja använda tjänsten.",
    CreateAgency_UserType_EmployeeTitle: "Bli inbjuden till en byrå",
    CreateAgency_UserType_Preamble: "Välj om du vill skapa en byrå eller om inväntar en inbjudan till en som redan existerar",
    CreateAgency_UserType_Title: "Hur vill du fortsätta?",
    CreateAgencyPreStep_AnotherAgency: "Skapa en annan byrå",
    CreateAgencyPreStep_Description: "Använd din befintliga byrå för att hantera alla dina kunder på det mest effektiva sättet och för att kunna dra nytta av fördelarna med att vara en Bokio Byråpartner.",
    CreateAgencyPreStep_Title: "Du har redan en byrå",
    CreateAgencyPreStep_ViewClients: "Visa alla dina kunder",
    CreateClient_SearchClientCompany: "Sök efter din kunds företag",
    CreateClient_WeWillImport: "Vi importerar företagsuppgifterna från Bolagsverket.",
    CreateClientRating_Feedback_Description: "Har du några tankar som kan hjälpa oss att förbättra din upplevelse av att skapa ett nytt kundföretag?",
    CreateClientRating_Rate_Title: "Hur var din upplevelse av att skapa ett nytt kundföretag?",
    CreateNewClientCompanyOption_Context: "Skapa ett nytt kundföretag och börja hantera konton och samarbeta i Bokio",
    CreateNewClientCompanyOption_Title: "Skapa ett nytt kundföretag",
    CreateNoteRating_Feedback_Description: "Har du några tankar som kan hjälpa oss att förbättra din upplevelse av att lägga till anteckningar?",
    CreateNoteRating_Rate_Title: "Hur var din upplevelse av att lägga till en anteckning till din kund",
    CreateTestClient_CTA: "Skapa testkund",
    CreateTestClientCompanyOption_Description: "Hoppa rakt in i ett testkundföretag och utforska alla Bokios bokföringsfunktioner",
    CreateTestClientCompanyOption_Title: "Kom igång med ett kundföretag",
    CreationDate: "Registreringsdatum",
    CustomClientColumnsRating__Feedback_Description: "Har ni några synpunkter som skulle kunna förbättra kundanpassningen?",
    CustomClientColumnsRating_Rate_Title: "Hur var er upplevelse gällande anpassningen av er kunds vy?",
    CustomerEmailAddress: "Kundens e-postadress",
    CustomerEmailExample: "kund@mail.se",
    CustomizeBokioForYourClient: "Anpassa Bokio för din kund",
    DateAndPrice: "Datum och pris",
    DeleteAgency: "Ta bort byrån",
    DeleteAgency_IAgreeToLetBokioDelete: "Jag samtycker till att Bokio raderar byrån {0} ({1}).",
    DeleteAgency_IAgreeToLetBokioDelete_NoOrgNumber: "Jag samtycker till att Bokio raderar byrån {0}.",
    DeleteAgency_IUnderstandLossOfData: "Jag förstår att jag inte kommer att kunna få tillgång till byrån {0} ({1}) och tillhörande kundföretag i Bokio.",
    DeleteAgency_IUnderstandLossOfData_NoOrgNumber: "Jag förstår att jag inte kommer att kunna få tillgång till byrån {0} och tillhörande kundföretag i Bokio.",
    DeleteAgency_Restriction_MoreClientsConnected: "Ta bort alla aktiva kunder i byrån",
    DeleteAgency_Restriction_MoreUsersConnected: "Ta bort alla revisorer i byrån",
    DeleteAgency_Restriction_PendingClientRequest: "Avvisa alla kundförfrågningar för denna byrå",
    DeleteAgency_Restriction_PendingQuoteRequest: "Avvisa alla offertförfrågningar för den här byrån",
    DeleteAgency_Restrictions_Heading: "För att radera byrån behöver du göra följande:",
    DeleteAgency_Section1_Heading: "Vad innebär det att ta bort denna byrå i Bokio?",
    DeleteAgency_Section1_Paragraph1: "När du raderar byrån **{0}** kommer samtliga att förlora åtkomst till den.\n\nAlla anslutna företag kommer att kopplas bort från byrån och alla tilldelade revisorer kommer att förlora tillgång till kundföretagen.\n\nDitt personliga konto **{1}** raderas inte och du har fortfarande tillgång till andra byråer eller företag som inte är direkt kopplade till **{0}** i Bokio.",
    DeleteAgency_Section1_Paragraph2: "Vi rekommenderar starkt att du exporterar de anteckningar du gjort för samtliga kunder då du inte längre kommer ha tillgång till dem efter att du lämnat byrån.",
    DeleteAgency_Section2_Heading: "Vad är anledningen till att du vill radera din byrå?",
    DeleteAgency_Section2_Paragraph2: "För att vi ska kunna förbättra våra tjänster hade vi verkligen uppskattat ifall du hade velat dela med dig utav varför du väljer att radera. Vänligen ge oss feedback.",
    DeleteAgency_Warning: "När du har raderat din byrå kan ingen få tillgång till den igen!",
    DisconnectAgency_AdditionalFeedback_Label: "Feedback till Bokio",
    DisconnectAgency_AdditionalFeedback_PlaceHolder: "Ta en stund att ge oss feedback om hur vi kan förbättra oss.",
    DisconnectAgency_AgencyFeedBack_AgencyClosed: "Byrån har nu stängts",
    DisconnectAgency_AgencyFeedBack_DuplicateAgency: "Det här är en dublett av en byrå",
    DisconnectAgency_AgencyFeedBack_NoLongerActiveInAgency: "Jag är inte längre aktiv i byrån",
    DisconnectAgency_AgencyFeedBack_Other: "Annan anledning",
    DisconnectAgency_BokioIsMissingFunctionality_FeedbackOption: "Bokio saknar funktioner som min byrå behöver",
    EditClientCompanyInvitationModal_Description: "En ny inbjudan kommer att skickas till företagsägaren av **{0}** med följande e-postadress. Den tidigare inbjudan kommer att avbrytas",
    EditClientCompanyInvitationModal_ResendInvitation: "Skicka inbjudan igen",
    EditInvitation: "Redigera inbjudan",
    EditUserModalTitle: "Ändra behörigheter för {0}",
    EmailAddress: "E-postadress",
    EndDate: "Slutdatum",
    ExternalCommunication_Warning: "Extern kommunikation. Kom ihåg att enbart inkludera information som är avsedd för din kund.",
    FeedBack_Dashboard_Header: "Ny översikt för Bokio Byrå",
    FeedBack_Dashboard_Text: "Vi värdesätter din åsikt! Vår nya översikt är här, och vi behöver din feedback för att förbättra upplevelsen! Dela dina tankar med oss idag och bidra till att göra den ännu bättre!",
    FeedbackModal_Dashboard_Description: "Hjälp oss förbättra översikten genom att dela med dig av dina tankar!",
    FeedbackModal_Dashboard_InputPrompt: "Vad vill du se i din översikt?",
    Filter_ShowCompleted: "Visa färdiga",
    Filter_ShowOnlyByActive: "Visa bara aktiva",
    Filter_UnreadConversations: "Olästa meddelanden",
    FilteredSupportTickets_IncludePreviousStatus_Checkbox: "Inkludera tidigare status",
    FiltredSupportTicket_MarkForBilling_Button: "Markera för fakturering",
    FiscalYearEnds: "Räkenskapsårets slutdatum",
    FormFilledIn: "Formuläret ifyllt",
    HelpWithK10: "Hjälp med K10",
    Here: "här.",
    Hmrc_Backoffice_EmptyState_Text: "There are no VAT returns to show or the client haven’t connected to HMRC yet. You can update the VAT list at any time by going to the VAT period listing inside Bokio.",
    IncludeDeletedAgencies: "Inkludera raderad byråer",
    InviteAccountant: "Bjud in redovisningskonsult",
    InviteAlreadyUsed: "Denna inbjudan har redan använts!",
    InviteAnAccountantOption_Context: "Bjud in en medarbetare till din byrå och börja tilldela kunder och ansvar",
    InviteAnAccountantOption_Title: "Bjud in en revisor",
    InviteCompanyOwner_Msg_Placeholder: "Vänligen ange inte personlig information",
    InviteCompanyOwner_PersonalMessage_Label: "Meddelande till företagets ägare (max 200 tecken) (valfritt)",
    InviteUser: "Bjud in användare",
    JoinAgency: "Gå med i {x}",
    KrPerMonth: "{0} kr/månad",
    LastActivity: "Senaste aktivitet: {0}",
    LastBookkeptOn: "Senast bokfört",
    LastUpdatedBy: "Senast ändrad av",
    LastUpdatedByClient: "Senast uppdaterad av kund",
    LeaveAgency: "Lämna byrå",
    LeaveAgency_AgencyOwner_ErrorPart: "Du kan inte lämna denna byrå eftersom du är ägare till byrån i Bokio.För att lämna byrån behöver du först överföra ägandeskapet till en annan användare.",
    LeaveAgency_IUnderstandLossOfAccess: "Jag förstår att jag inte kommer att kunna få tillgång till byrån {0} ({1}) och tillhörande kundföretag i Bokio.",
    LeaveAgency_IUnderstandLossOfAccess_NoOrgNumber: "Jag förstår att jag inte kommer att kunna få tillgång till byrån {0} och tillhörande kundföretag i Bokio.",
    LeaveAgency_NoAgencyOwner_Error: "För att lämna byrån behöver det finnas en byråägare.",
    LeaveAgency_Section1_Heading: "Vad innebär det att lämna denna byrå i Bokio?",
    LeaveAgency_Section1_Paragraph1: "När du lämnar byrån {0}, kommer du förlora tillgång till byrån och behöver bli inbjuden på nytt för att få åtkomst igen.\n\nDu kommer också att förlora tillgång till alla associerade kundföretag som du för närvarande är tilldelad.\n\nDitt personliga konto {1} raderas inte, och du har fortfarande tillgång till andra byråer eller företag som inte är direkt kopplade till {0}, i Bokio.",
    LeaveAgency_Section1_Paragraph2: "Vi rekommenderar starkt att du exporterar de anteckningar du gjort för samtliga kunder då du inte längre kommer ha tillgång till dem efter att du lämnat byrån.",
    LeaveAgency_Section2_Heading: "Vad är anledningen till att du lämnar denna byrå?",
    LeaveAgency_Section2_Paragraph: "För att vi ska kunna förbättra våra tjänster hade vi verkligen uppskattat ifall du hade velat dela med dig utav varför du väljer att lämna.",
    ManageContractAgreement: "Hantera uppdragsbrev",
    ManageContractAgreement_InvalidForm_Price: "Pris måste vara ifyllt på varje rad i {0} sektionen",
    ManageContractAgreement_InvalidForm_StartDate: "Start datum måste vara ifyllt för varje rad i {0} sektionen",
    ManagedBy: "Hanteras av",
    Maybe_Later: "Kanske senare",
    MentionsMe: "Nämner mig",
    MoveToBokio_ClientRange: "Hur många kunder vill du flytta?",
    MoveToBokio_Info: "Överföringen tar ofta cirka 2 arbetsdagar, men kan ta upp till 5 arbetsdagar beroende på antalet kunder.",
    MoveToBokio_Placeholder: "Skriv ett kort meddelande",
    MoveToBokio_PreviousSystem: "Nuvarande bokföringstjänst ",
    MoveToBokio_RequestHelp: "Begär hjälp",
    MoveToBokio_Subject: "Flytta dina kunder till Bokio",
    MoveToBokio_Title: "Behöver du hjälp med att flytta över dina kunder?",
    MoveToBokio_TitleMessage: "Vi erbjuder en kostnadsfri förmedlingstjänst för att hjälpa dig flytta över dina befintliga kunder till Bokio Byrå.",
    MustbeNumeric: "Kan bara innehålla siffror",
    NewAgencyGuide_Description: "Fortsätt konfigurera {0} genom att skapa din första kund eller bjuda in en kollega att samarbeta.",
    NewAgencyGuide_Notice_Context: "Nu är det dags att börja samarbeta med dina kunder i Bokio",
    NewAgencyGuide_Notice_Title: "Du är redo!",
    NewAgencyGuide_Title: "Välkommen till Bokio Byrå!",
    NewBackofficeRedirect_Notice: "Följ {länken} för att komma till det nya byrå.",
    NextClosure: "Nästa bokslut",
    None_Assigned: "Ingen kundansvarig",
    NonRecurrentCompanyColumn_OrderedOn_Title: "Beställd",
    NonRecurrentCompanyColumn_SupportOn: "Hjälp med",
    Notes_AssignedAccountantsCanView_Notice: "Endast kundansvariga för detta företag kan visa eller lägga till noteringar",
    Notes_AssignedDetails: "Tilldelats {0}",
    Notes_CreatedBy: "Skapad av {0} {1}",
    Notes_DueDateDetails: "Senast {0}",
    Notes_LimitedText: "{0}....\n{1}",
    Notes_NotificationStatusDetails: "{0} av {1} {2}",
    Notes_Title: "Noteringar",
    Notes_Tooltip: "{count} {type} till",
    Notes_Unassigned: "Otilldelad",
    Notification_OverdueTooltip: "{type} - {count} försenade",
    Notification_Tooltip: "{type} - {count} nya",
    NotificationsWillNotBeRemoved_Notice: "Detta tar inte bort notisar som redan har skickats.",
    NotStarted: "Ej startat",
    NoUserExistsWithThatEmailAddress: "Vi kunde inte hitta någon användare med den givna e-postadressen",
    NoUserFoundWithEmail: "Ingen användare hittades med email {0}",
    NumberOfAccountants: "Antal konsulter",
    NumberOfClients: "Antal kunder",
    NumberPayingClients: "Antal betalande kunder",
    OnboardNow: "Registrera nu",
    OpenInBokio: "Öppna i Bokio",
    Optional: "Valfri",
    OrganisationNumber: "Org. nummer",
    Overview_OnboardingChecklist_CreateCompany_Button: "Skapa nytt företag",
    Overview_OnboardingChecklist_CreateCompany_Heading: "Har du redan en kund du vill lägga till?",
    Overview_OnboardingChecklist_CreateCompany_Text: "Skapa ett nytt kundföretag och börja hantera konton och samarbeta i Bokio.",
    Overview_OnboardingChecklist_Import_Heading: "Anslut kunder",
    Overview_OnboardingChecklist_Intro: "Här hittar du tips kring det mest relevanta för att effektivt komma igång med din Byrå i Bokio",
    Overview_OnboardingChecklist_Invite_Button: "Bjud in konsult",
    Overview_OnboardingChecklist_Invite_Heading: "Bjud in redovisningskonsulter",
    Overview_OnboardingChecklist_Invite_Text: "Anslut enkelt alla dina redovisningskonsulter till byrån för en förenklad och användarvänlig upplevelse",
    Overview_OnboardingChecklist_Moving_Button: "Boka gratis konsultation",
    Overview_OnboardingChecklist_Moving_Heading: "Importera dina kunder från ett annat bokföringsprogram",
    Overview_OnboardingChecklist_Moving_Text: "Vi erbjuder byråer gratis konsultation för att hjälpa till med kundmigrationen",
    Overview_OnboardingChecklist_NewAgency_Heading: "Ny byrå i Bokio",
    Overview_OnboardingChecklist_NewAgency_Text: "Skapa ditt företag och börja hantera dina kunder enkelt.",
    Overview_OnboardingChecklist_TestClient_Button: "Skapa ett testföretag",
    Overview_OnboardingChecklist_TestClient_Text: "Vi kommer att skapa ett testföretag åt dig så att du kan dyka rätt in i Bokio",
    Overview_PageTitle: "Välkommen till Bokio Byrå",
    Overview_PartnerProgramme_InviteMore: "Bjud in {0} betalande kunder till för att nå {1}",
    Overview_PartnerProgramme_Onboardedclients: "Kund{er} onboardad{e} senaste månaden",
    Overview_PartnerProgramme_Readmore: "Läs mer om Bokio Byråpartner",
    Overview_PartnerProgramme_Unsigned_Header: "Bokio Byråpartner",
    Overview_PartnerProgramme_Unsigned_Text: "Väx med Bokio och få provision för nya och existerande kunder genom att bli Byråpartner. {Läs mer och bli partner!}",
    Overview_SupportBox_Heading: "Behöver du personlig experthjälp?",
    Overview_SupportBox_Text: "Vill du komma i kontakt med vårt team för expertvägledning om hur du använder Bokio till sin fulla potential? {0} Kontakta oss på {1}",
    Overview_TotalNumberOfComments: "Totalt antal kommentarer",
    Overview_TotalNumberOfTasks: "Totalt antal uppgifter",
    Page_ClientRequests: "Kundförfrågningar",
    Page_QuoteRequests: "Offertförfrågningar",
    Partner: "Partner",
    PartnerContent_BecomePartner_Text: "Bli Byråpartner och få provision",
    PartnerContract_Pending: "Pågår",
    PartnerContract_Signed: "Signerat",
    PartnerContract_Unsigned: "Ej signerat",
    PartnerHelpscoutMailbox: "Helpscout mailbox id",
    PartnerInfo_Banner_ButtonText: "Registrera nu",
    PartnerInfo_Banner_Heading: "Registrera dig som Byråpartner och börja tjäna provision idag",
    PartnerInfo_Banner_Text: "Upptäck oändliga provisionsmöjligheter som en uppskattad Byråpartner. Bli partner och börja tjäna provision baserat på alla kunder som ansluter sig till din byrå.",
    PartnerInfo_Clarification_Marketplace: "{**Synas på marknadsplatsen:} Kontakta oss på {byrasupport@bokio.se} för att få mer information om vad det innebär och för att få en offert. Som Byråpartner behöver du inte synas på marknadsplatsen, men det är ett alternativ om så önskas.",
    PartnerInfo_Pros_Grow_Heading: "Väx tillsammans med Bokio!",
    PartnerInfo_Pros_Grow_Text: "Synliggör din byrå för alla våra kunder genom marknadsplatsen “Anlita en konsult” samt på Bokios hemsida, dit många Bokioanvändare vänder sig när de behöver extra hjälp.",
    PartnerInfo_Pros_NewCustomers_Heading: "Provision för nya kunder i Bokio",
    PartnerInfo_Pros_NewCustomers_Text: "Som Byråpartner får du provision för nya kunder du tar till Bokio. För de som har bindningstid kvar betalar vi dessutom mellanskillnaden.",
    PartnerInfo_Pros_Services_Heading: "Provision för kunders prisplaner",
    PartnerInfo_Pros_Services_Text: "Som Byråpartner får du exklusiv provision för dina kunders prisplaner. Desto fler kunder som använder Bokio, desto mer provision!",
    PartnerInfo_Text: "Bli Byråpartner och få provision för dina kunders prisplaner. Beroende på hur många kunder du har i Bokio hamnar din byrå på någon av de tre nivåerna: Brons, Silver eller Guld. Ju högre nivå du är på, desto fler fördelar får du som Byråpartner.",
    PartnerInfoTable_AccordingOffer: "Enligt offert",
    PartnerInfoTable_Commission_Amount: "Provision - baserad på slutkundens månadsavgift",
    PartnerInfoTable_Commission_Customers: "Krav om betalande kunder",
    PartnerInfoTable_Commission_Onboarding: "Provision - onboarding nya kunder",
    PartnerInfoTable_Marketplace_Cost: "Kostnad för att synas på marknadsplatsen",
    PartnerInfoTable_Marketplace_Title: "Synas på marknadsplatsen - \"Anlita en konsult\"**",
    PartnerInfoTable_Marketplace_Visibility: "Synas på Bokios hemsida",
    PartnerInfoTable_Support_Text: "Prioriterad byråsupport",
    PartnerInfoTable_Support_Title: "Support",
    PartnerName: "Byrånamn",
    Partners: "Byråer",
    PendingClients_CompanyOwnerEmail: "Företagsägarens e-post",
    PendingClients_DateSent: "Datum skickat",
    PendingClients_Status_Tooltip: "Inbjudningar är endast giltiga i 48 timmar",
    PendingInvites: "Utestående inbjudningar",
    PendingTodoItems: "Att göra",
    Period: "Period",
    Permission_BookkeepingSupport_Description: "Redovisningskonsulten har rätt att sköta bokföringssupport.",
    Permission_BookkeepingSupport_Title: "Bokföringssupport",
    Permission_ClosureSupport_Description: "Redovisningskonsulten har rätt att sköta support för bokslut.",
    Permission_ClosureSupport_Title: "Bokslutssupport",
    Permission_ManageCompanies_Description: "Redovisningskonsulten har rätt att lägga till eller ta bort kundföretag och administrera vem som är ansvarig för kunderna.",
    Permission_ManageCompanies_Title: "Hantera företag",
    Permission_ManageQuoteRequests_Description: "Redovisningskonsulten har rätt att sköta offertförfrågningar från kunder.",
    Permission_ManageQuoteRequests_Title: "Hantera offertförfrågningar",
    Permission_ManageUsers_Description: "Redovisningskonsulten har rätt att lägga till och ta bort andra konsulter samt att redigera deras rättigheter.",
    Permission_ManageUsers_Title: "Hantera redovisningskonsulter",
    Permission_PartnerSettings_Description: "Redovisningskonsulten har rätt att ändra kontoinställningar.",
    Permission_PartnerSettings_Title: "Hantera inställningar",
    Permission_SelfAssign_Description: "Redovisningskonsulten har rätt att lägga till sig själv som ansvarig för kunden.",
    Permission_SelfAssign_Title: "Kundansvar",
    Permission_Viewer_Title: "Endast insyn",
    Plan_PaidUntil: "Betald till",
    PricePerMonth: "Pris (kr/mån)",
    Priority_High: "Hög",
    Priority_Low: "Låg",
    Priority_Medium: "Mellan",
    PromotionBanner_MoveToBokio_BookFreeConsultation: "Boka kostnadsfri konsultation",
    PromotionBanner_MoveToBokio_Description: "Vi erbjuder en personlig och kostnadsfri service för din byrå där vi hjälper dig att flytta över dina kunder till Bokio Byrå från ett annat redovisningsprogram.",
    PromotionBanner_MoveToBokio_Title: "Behöver du hjälp med att flytta över dina kunder till Bokio?",
    ProvideFeedback: "Skicka feedback",
    QuoteRequest_RegisterClient_SuccessHeading: "{0} har registrerats {Visa aktiva kunder}",
    QuoteRequestRating_Rate_Description: "Har du några tankar som kan hjälpa oss att förbättra din upplevelse av offertförfrågningar?",
    QuoteRequestRating_Rate_Title: "Hur var din upplevelse med att använda offertförfrågningar?",
    QuoteRequests_ActiveTab: "Aktiva",
    QuoteRequests_ArchivedTab: "Arkiverad",
    ReasonToReject: "Anledning till nekning",
    RecordingSupport_PageTitle: "Bokföringssupport",
    RecordingSupport_RecordingSupportTab_Assigned: "Tilldelade ärenden",
    RecordingSupport_RecordingSupportTab_Filtered: "Filtrera ärenden",
    RecordingSupport_RecordingSupportTab_Pending: "Ej påbörjade",
    RecordingSupport_RecordingSupportTab_Search: "Sök",
    RecordingSupport_SearchSupportTicket_SearchCompany: "Sök företag",
    RegistrationDate: "Registrerat",
    Reject: "Neka och arkivera",
    RejectAndArchive: "Neka ansökan",
    RejectQuoteRequest_Feedback_AgencyCantFulfilRequest: "Byrån kunde ej uppfylla kraven",
    RejectQuoteRequest_Feedback_CompanyDidntLikeTheOffer: "Företaget nekade offerten",
    RejectQuoteRequest_Feedback_CompanyDidntRespond: "Företaget svarade ej",
    RejectQuoteRequest_Feedback_Other: "Annat",
    RejectQuoteRequest_FeedbackLabel: "Varför avvisar ni denna begäran?",
    RejectQuoteRequest_PersonalMessage_Label: "Personligt meddelande till företaget (max 200 tecken)",
    RejectRequest: "Neka förfrågan",
    ReleaseNotes_Message_Agency: "Nya funktioner har lagts till.",
    ReleaseNotes_Message_Company: "Nya funktioner finns tillgängliga i Bokio Byrå.",
    RemoveClient: "Ta bort kund",
    RemovedClient: "Borttagen kund",
    RemoveMe: "Ta bort mig",
    RemovePartnerCompany: "Ta bort företag",
    RemovePartnerCompanyModalMessage: "Är du säker på att du vill ta bort företaget {0} från {1}?",
    RemoveResponsibleAccountantWarning_Text: "Användare {email} är ansvarig ekonom för detta företag. Tar du bort denna användare tar du också bort den ansvariga ekonomen.",
    RemoveResponsibleAccountantWarning_Title: "Ta bort ansvarig ekonom",
    RemoveUserModalMessage: "Är du säker på att du vill ta bort användaren {0}?",
    RemoveUserModalTitle: "Ta bort användare {0}",
    Request: "Förfrågan",
    Reset: "Återställ",
    Responsibilities_Accounting: "Redovisning",
    Responsibilities_AdvisoryServices: "Rådgivning",
    Responsibilities_Bookkeeping: "Löpande bokföring",
    Responsibilities_ForecastingAndBudgeting: "Prognoser och budget",
    Responsibilities_ManagementAccounts: "Management accounts",
    Responsibilities_Payroll: "Lönehantering",
    Responsibilities_Reconciliation: "Avstämningar",
    Responsibilities_ReviewAndCorrectionOfBookkeeping: "Granskning och rättning av bokföring",
    Responsibilities_Salary: "Löner",
    Responsibilities_TaxAdvice: "Skatterådgivning",
    Responsibilities_TaxReturns: "Self Assessment tax return",
    Responsibilities_VatReturns: "Momsrapportering",
    Responsibilities_YearEnd: "Bokslut och rapportering",
    ResponsibilitiesModal_Description: "Välj de tjänster du hjälper och ansvarar för åt din kund:",
    ResponsibleAccountant: "Ansvarig ekonom",
    ReviewAndConnect: "Granska och koppla",
    Salary_LastDateToSubmit: "Sista dag att rapportera",
    Salary_LastDateToSubmitForMonth: "Sista dag att rapportera för {month}",
    Salary_LastLockedMonth: "Senaste stängda månaden",
    Salary_NumberOfEmployees: "Antal anställda",
    Scene_Admin_Title: "Admin",
    Scene_ClientsInQueue_Onboarding: "Registrering",
    Scene_ClientsInQueue_WaitingForApproval: "Väntar på godkännande",
    Scene_Companies_NonRecurrent: "Engångs",
    Scene_Companies_Recurrent: "Återkommande",
    Scene_Companies_Title: "Företag",
    Scene_CompaniesInQueue_Title: "Företag i kö",
    Scene_ManageCompanies_Title: "Hantera företag",
    Scene_MyCompanies_Title: "Mina företag",
    Scene_Overview_Title: "Översikt",
    Scene_Partners_Title: "Byråer",
    Scene_Settings_Title: "Inställningar",
    Scene_Todo_Title: "Att göra",
    Scene_Users_Title: "Användare",
    Search_BackOfficeAgencies_Placeholder: "Sök på Namn / ID",
    SendInvite: "Skicka inbjudan",
    ShowAllClientsImAMemberOf: "Visa alla klienter jag är medlem i",
    ShowArchived: "Visa arkiverade företag",
    SignUp_Banner_ButtonText: "Bli Byråpartner",
    SignUp_Banner_Text: "Bli Byråpartner och få provision för dina kunders prisplaner. Ju fler kunder du har i Bokio, desto fler fördelar får du!",
    StartDate: "Startdatum",
    Status: "Status",
    SubmissionDate: "Inlämningsdatum",
    SupportTicket_OrgNumberMissing: "Organsiationsnummer saknas",
    SupportTicketList_AssignButton: "Tilldela",
    SupportTicketList_AssignTo_Label: "Tilldela",
    SupportTicketList_TicketInfoHeading_AccountingType: "Bokföringsform:",
    SupportTicketList_TicketInfoHeading_Comments: "Kommentarer",
    SupportTicketList_TicketInfoHeading_CompanyId: "CompanyId:",
    SupportTicketList_TicketInfoHeading_CompanyType: "Företagstyp:",
    SupportTicketList_TicketInfoHeading_PricePlan: "Prisplan:",
    SupportTicketList_TicketInfoHeading_Updated: "Updaterad",
    SupportTicketList_Unassigned: "Ej tilldelad",
    Task: "Uppgift",
    Task_EditAccess_Anyone_Option: "Tillåt samtliga att redigera",
    Task_EditAccess_CheckboxLabel: "Redigera åtkomst",
    Task_EditAccess_OnlyMe_Option: "Endast jag kan redigera",
    Task_TextField_Label: "Beskriv uppgiften",
    Task_TitleField_Label: "Titel",
    Tasks: "Uppgifter",
    Tasks_AssignedAccountantsCanView_Notice: "Endast kundansvariga för detta företag kan visa eller lägga till uppgifter",
    Tasks_AssignedAccountantsCanView_QuoteRequest_Notice: "Endast kundansvariga för denna offertförfrågan kan visa eller lägg till uppgifter",
    Tasks_MarkAs: "Markera",
    TasksAndComments_Title: "Uppgifter och kommentarer",
    TransferCompany_CompanyToTransfer: "Företag som ska överföras",
    TransferCompany_PartnerToTransferTo: "Byrå som företaget ska överföras till",
    TransferCompany_TransferToAnotherPartner: "Överför till annan byrå",
    TryAgencyBanner_Content: "Vi fortsätter att utveckla Bokio för redovisningskonsulter så att det ska vara så enkelt som möjligt för dig att arbeta med dina kunder. Registrera dig som redovisningskonsult och få tillgång till vårt klienthanteringssystem direkt.",
    TryAgencyBanner_CTA: "Prova Bokio för redovisningskonsulter",
    TypeHere_Placeholder: "Skriv här...",
    UpdatedByClientAt: "Uppdaterad av företag",
    UserIsAMemberOfTheFollowingCompanies: "{0} är användare i följande företag",
    Users: "Användare",
    Users_AgencyOwner: "Byråägare",
    Users_AgencyOwner_Paragraph: "Byrån ägs av  {0} ({1}).",
    Users_ChangeAgencyOwner: "Byt byråägare",
    Users_ChangeOwner: "Byt ägare",
    Users_ChangeOwnerWarning_Header: "Varning!",
    Users_ChangeOwnerWarning_Paragraph: "Bara byråägaren kan ändra ägare. När du har ändrat byråägare kan bara den nya ägaren ändra.",
    UserX_CreatedANoteOn_DateY: "Skapad av {0} den {1}",
    Vat_Completed: "Klar",
    Vat_ReadyToSubmit: "Redo att skickas",
    Vat_Submitted: "Skickad",
    VatStatus_NonPeriodic: "Momsstatus",
    VatStatus_Periodic: "Momsperiod",
    ViewActiveClients: "Visa aktiva kunder",
    ViewAllDetails: "Visa alla detaljer",
    ViewRequest: "Visa förfrågan",
    WaitingSince: "Väntar sedan",
    WeNeedSomeMoreInfo: "För att fortsätta behöver vi några uppgifter från dig",
    WorksBestOnComputer: "Denna sida fungerar bäst i en webbläsare på en dator",
    WorkStatus_Archived: "Arkiverad",
    WorkStatus_Completed: "Klar",
    WorkStatus_ConsultationComplete: "Konsultation klar",
    WorkStatus_ConsultationInProgress: "Konsultation pågår",
    WorkStatus_Contacted: "Kontaktad",
    WorkStatus_ContractSent: "Kontrakt skickat",
    WorkStatus_ContractSigned: "Kontrakt signerat",
    WorkStatus_Incomplete: "Ofullständig",
    WorkStatus_New: "Ny",
    WorkStatus_OnboardingComplete: "Onboarding klar",
    WorkStatus_OnboardingInProgress: "Onboarding pågår",
    WorkStatus_Ongoing: "Pågår",
    WorkStatus_Overdue: "Försenad",
    WorkStatus_Waiting: "Väntar",
    WriteReason: "Uppge en anledning till nekandet för framtida uppföljning",
    YouAreNotAuthorizedToAccessCompanyInfo: "Du saknar behörighet att visa den här sidan. Lägg till dig själv på företaget eller kontakta administratören.",
  };
}