/* eslint-disable */

import type { CountriesLang } from "../types/CountriesLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function CountriesLangDef(): CountriesLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    ABW: "Aruba",
    AFG: "Afghanistan",
    AGO: "Angola",
    AIA: "Anguilla",
    ALA: "Åland",
    ALB: "Albanien",
    AND: "Andorra",
    ARE: "Förenade Arabemiraten",
    ARG: "Argentina",
    ARM: "Armenien",
    ASM: "Amerikanska Samoa",
    ATA: "Antarktis",
    ATF: "Franska södra territorierna",
    ATG: "Antigua och Barbuda",
    AUS: "Australien",
    AUT: "Österrike",
    AZE: "Azerbajdzjan",
    BDI: "Burundi",
    BEL: "Belgien",
    BEN: "Benin",
    BES: "Bonaire, Saint Eustatius och Saba",
    BFA: "Burkina Faso",
    BGD: "Bangladesh",
    BGR: "Bulgarien",
    BHR: "Bahrain",
    BHS: "Bahamas",
    BIH: "Bosnien och Hercegovina",
    BLM: "Saint-Barthélemy",
    BLR: "Vitryssland",
    BLZ: "Belize",
    BMU: "Bermuda",
    BOL: "Bolivia",
    BRA: "Brasilien",
    BRB: "Barbados",
    BRN: "Brunei",
    BTN: "Bhutan",
    BVT: "Bouvetön",
    BWA: "Botswana",
    CAF: "Centralafrikanska republiken",
    CAN: "Kanada",
    CCK: "Kokosöarna",
    CHE: "Schweiz",
    CHL: "Chile",
    CHN: "Kina",
    CIV: "Elfenbenskusten",
    CMR: "Kamerun",
    COD: "Demokratiska republiken Kongo",
    COG: "Kongo-Brazzaville",
    COK: "Cooköarna",
    COL: "Colombia",
    COM: "Komorerna",
    CPV: "Kap Verde",
    CRI: "Costa Rica",
    CUB: "Kuba",
    CUW: "Curacao",
    CXR: "Julön",
    CYM: "Caymanöarna",
    CYP: "Cypern",
    CZE: "Tjeckien",
    DEU: "Tyskland",
    DJI: "Djibouti",
    DMA: "Dominica",
    DNK: "Danmark",
    DOM: "Dominikanska republiken",
    DZA: "Algeriet",
    ECU: "Ecuador",
    EGY: "Egypten",
    ERI: "Eritrea",
    ESH: "Västsahara",
    ESP: "Spanien",
    EST: "Estland",
    ETH: "Etiopien",
    FIN: "Finland",
    FJI: "Fiji",
    FLK: "Falklandsöarna",
    FRA: "Frankrike",
    FRO: "Färöarna",
    FSM: "Mikronesiska federationen",
    GAB: "Gabon",
    GB_ENG: "England",
    GB_NIR: "Nordirland",
    GB_SCT: "Skottland",
    GB_WLS: "Wales",
    GBR: "Storbritannien",
    GEO: "Georgien",
    GGY: "Guernsey",
    GHA: "Ghana",
    GIB: "Gibraltar",
    GIN: "Guinea",
    GLP: "Guadeloupe",
    GMB: "Gambia",
    GNB: "Guinea Bissau",
    GNQ: "Ekvatorialguinea",
    GRC: "Grekland",
    GRD: "Grenada",
    GRL: "Grönland",
    GTM: "Guatemala",
    GUF: "Franska Guyana",
    GUM: "Guam",
    GUY: "Guyana",
    HKG: "Hongkong",
    HMD: "Heard- och McDonaldsöarna",
    HND: "Honduras",
    HRV: "Kroatien",
    HTI: "Haiti",
    HUN: "Ungern",
    IDN: "Indonesien",
    IMN: "Isle of Man",
    IND: "Indien",
    IOT: "Brittiska territoriet i Indiska Oceanen",
    IRL: "Irland",
    IRN: "Iran",
    IRQ: "Irak",
    ISL: "Island",
    ISR: "Israel",
    ITA: "Italien",
    JAM: "Jamaica",
    JEY: "Jersey",
    JOR: "Jordanien",
    JPN: "Japan",
    KAZ: "Kazakstan",
    KEN: "Kenya",
    KGZ: "Kirgizistan",
    KHM: "Kambodja",
    KIR: "Kiribati",
    KNA: "Saint Kitts och Nevis",
    KOR: "Sydkorea",
    KWT: "Kuwait",
    LAO: "Laos",
    LBN: "Libanon",
    LBR: "Liberia",
    LBY: "Libyen",
    LCA: "Saint Lucia",
    LIE: "Liechtenstein",
    LKA: "Sri Lanka",
    LSO: "Lesotho",
    LTU: "Litauen",
    LUX: "Luxemburg",
    LVA: "Lettland",
    MAC: "Macau",
    MAF: "Saint Martin (franska delen)",
    MAR: "Marocko",
    MCO: "Monaco",
    MDA: "Moldavien",
    MDG: "Madagaskar",
    MDV: "Maldiverna",
    MEX: "Mexiko",
    MHL: "Marshallöarna",
    MKD: "Makedonien",
    MLI: "Mali",
    MLT: "Malta",
    MMR: "Burma",
    MNE: "Montenegro",
    MNG: "Mongoliet",
    MNP: "Nordmarianerna",
    MOZ: "Moçambique",
    MRT: "Mauretanien",
    MSR: "Montserrat",
    MTQ: "Martinique",
    MUS: "Mauritius",
    MWI: "Malawi",
    MYS: "Malaysia",
    MYT: "Mayotte",
    NAM: "Namibia",
    NCL: "Nya Kaledonien",
    NER: "Niger",
    NFK: "Norfolkön",
    NGA: "Nigeria",
    NIC: "Nicaragua",
    NIU: "Niue",
    NLD: "Nederländerna",
    NOR: "Norge",
    NPL: "Nepal",
    NRU: "Nauru",
    NZL: "Nya Zeeland",
    OMN: "Oman",
    PAK: "Pakistan",
    PAN: "Panama",
    PCN: "Pitcairnöarna",
    PER: "Peru",
    PHL: "Filippinerna",
    PLW: "Palau",
    PNG: "Papua Nya Guinea",
    POL: "Polen",
    PRI: "Puerto Rico",
    PRK: "Nordkorea",
    PRT: "Portugal",
    PRY: "Paraguay",
    PSE: "Palestinska territoriet, ockuperade",
    PYF: "Franska Polynesien",
    QAT: "Qatar",
    REU: "Réunion",
    ROU: "Rumänien",
    RUS: "Ryssland",
    RWA: "Rwanda",
    SAU: "Saudiarabien",
    SDN: "Sudan",
    SEN: "Senegal",
    SGP: "Singapore",
    SGS: "Sydgeorgien och Sydsandwichöarna",
    SHN: "Sankta Helena",
    SJM: "Svalbard och Jan Mayen",
    SLB: "Salomonöarna",
    SLE: "Sierra Leone",
    SLV: "El Salvador",
    SMR: "San Marino",
    SOM: "Somalia",
    SPM: "Saint-Pierre och Miquelon",
    SRB: "Serbien",
    SSD: "Sydsudan",
    STP: "São Tomé och Príncipe",
    SUR: "Surinam",
    SVK: "Slovakien",
    SVN: "Slovenien",
    SWE: "Sverige",
    SWZ: "Swaziland",
    SXM: "Sint Maarten (nederländska delen)",
    SYC: "Seychellerna",
    SYR: "Syrien",
    TCA: "Turks- och Caicosöarna",
    TCD: "Tchad",
    TGO: "Togo",
    THA: "Thailand",
    TJK: "Tadzjikistan",
    TKL: "Tokelauöarna",
    TKM: "Turkmenistan",
    TLS: "Östtimor",
    TON: "Tonga",
    TTO: "Trinidad och Tobago",
    TUN: "Tunisien",
    TUR: "Turkiet",
    TUV: "Tuvalu",
    TWN: "Taiwan",
    TZA: "Tanzania",
    UGA: "Uganda",
    UKR: "Ukraina",
    UMI: "USA:s yttre öar",
    URY: "Uruguay",
    USA: "USA",
    UZB: "Uzbekistan",
    VAT: "Vatikanstaten",
    VCT: "Saint Vincent och Grenadinerna",
    VEN: "Venezuela",
    VGB: "Brittiska Jungfruöarna",
    VIR: "Amerikanska Jungfruöarna",
    VNM: "Vietnam",
    VUT: "Vanuatu",
    WLF: "Wallis- och Futunaöarna",
    WSM: "Samoa",
    XKX: "Kosovo",
    YEM: "Jemen",
    ZAF: "Sydafrika",
    ZMB: "Zambia",
    ZWE: "Zimbabwe",
  };
}