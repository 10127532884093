/* eslint-disable */

import type { OnboardingLang } from "../types/OnboardingLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function OnboardingLangDef(): OnboardingLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    AutomateTransactions_box1_button: "Koppla till din bank",
    AutomateTransactions_box1_header: "Sätt upp bankkoppling",
    AutomateTransactions_box1_text: "Minska misstagen och förenkla din bokföring genom att koppla Bokio till ditt företags bank.",
    AutomateTransactions_box2_button: "Öppna företagskonto",
    AutomateTransactions_box2_header: "Öppna Bokio Företagskonto",
    AutomateTransactions_box2_text_1: "Banktjänst fullt integrerad i Bokio för ännu mer automatisering, kontroll och bekvämlighet.",
    AutomateTransactions_box2_text_2: "Bokio Företagskonto kräver att du först tecknar en plan.",
    AutomateTransactions_Name: "Välkommen {0}, låt oss öppna upp automatisering i Bokio",
    AutomateTransactions_Text: "Få en smidig start genom att synka dina transaktioner. Välj ett eller båda alternativ beroende på dina behov:",
    Autopilot_AllPricesExclVat: "Alla priser som visas är exklusive moms",
    Autopilot_BokioAndRedflag: "Bokio och Red Flag har gått samman och vi håller för närvarande på att utvärdera hur tjänsten som idag ligger hos Red Flag ska presenteras under Bokios varumärke. Tack vare din medverkan har vi fått in mer information om hur vi kan göra detta på bästa sätt.",
    Autopilot_BokioAutopilot: "Bokio Autopilot",
    Autopilot_BuyAutopilot: "Köp Autopilot",
    Autopilot_DontMatchExpectations: "Utbudet matchar inte mina förväntningar",
    Autopilot_DontNeed: "Jag har inte behov av det för tilllfället",
    Autopilot_DontSeeTheValue: "Jag ser inte värdet av tjänsten",
    Autopilot_EverythingFromInvoicing: "Allt från fakturering, bokföring och arkivering av papper hanteras digitalt. Du har alltid en uppdaterad bild av hur det går för ditt företag och du behöver inte heller köpa dyra revisorstjänster för att ha ordning på din bokföring – den tar Autopiloten hand om.",
    Autopilot_Feature1: "Alltid uppdaterad bild av hur det går för ditt företag",
    Autopilot_Feature2: "Företagskonto med självbokförande betalkort",
    Autopilot_Feature3: "Ladda upp kvitton och räkningar – autopiloten gör resten",
    Autopilot_Feature4: "Du missar aldrig en moms- eller skattebetalning",
    Autopilot_GetStartedWithAutopilot: "Kom igång med Bokio Autopilot",
    Autopilot_HelpUsGetBetter: "Var med och påverka tjänsten",
    Autopilot_HelpUsImprove: "Hjälp oss att förbättras",
    Autopilot_Included: "Inkluderat",
    Autopilot_IncludedList: "- Allt för ditt aktiebolag eller enskilda firma i en användarvänlig tjänst\n- Självbokförande kort från Mastercard och företagskonto\n- Fakturering i Sverige och utomlands direkt i tjänsten\n- Enkel hantering av kvitton och räkningar\n- Automatisk hantering av skatter och avgifter\n- Bokslut och deklaration till ditt företag\n- Alltid uppdaterad bild av hur det går",
    Autopilot_NotInterested: "Inte intresserad",
    Autopilot_Other: "Annat",
    Autopilot_Price: "Från 498 kr/månad",
    Autopilot_PriceAndPayment: "Pris och betalning",
    Autopilot_ReadMore: "Läs mer",
    Autopilot_ReceivedFeedbackInterested: "Vi har tagit emot din feedback och vi kommer att göra vårt bästa för att förbättra Bokio Autopilot ytterligare. Vi hör av oss till dig när tjänsten släpps, så att du blir en av de första som får ta del av den.",
    Autopilot_ReceivedFeedbackNotInterested: "Vi har tagit emot din feedback och vi kommer att göra vårt bästa för att förbättra Bokio Autopilot och andra erbjudanden i framtiden.",
    Autopilot_SleepTight: "Sov gott utan att oroa dig över att missa något – låt autopiloten göra jobbet.",
    Autopilot_ThankYouGetBetter: "Tack för att du hjälper oss att bli bättre",
    Autopilot_ThankYouImprove: "Tack för att du hjälper oss att förbättras",
    Autopilot_ThankYouInterest: "Tack för att du visat intresse för Bokio Autopilot",
    Autopilot_ThePrice: "Priset",
    Autopilot_ThePriceIsBasedOn: "Priset baseras på ditt bolags omsättning. Vänligen notera att 1 498 kr faktureras utöver standardavgiften per år om du väljer att köpa till årsbokslut och 998 kr för årsredovisning.",
    Autopilot_TurnoverAbove7500: "Omsättning över 75 000 kr/månad",
    Autopilot_TurnoverAbove7500Price: "1 198 kr/månad",
    Autopilot_TurnoverUpTo10000: "Omsättning upp till 10 000 kr/månad",
    Autopilot_TurnoverUpTo10000Price: "498 kr/månad",
    Autopilot_TurnoverUpTo75000: "Omsättning upp till 75 000 kr/månad",
    Autopilot_TurnoverUpTo75000Price: "798 kr/månad",
    Autopilot_WeAppreciateFeedback: "Vi uppskattar feedback och om du har några tankar kring Bokio Autopilot får du väldigt gärna beskriva dina behov och tankar så att vi kan skapa ett bokföringsprogram i världsklass.",
    Autopilot_WeUnderstand: "Vi förstår att du inte är intresserad av Bokio Autopilot men vi skulle verkligen uppskatta om du vill berätta varför. Det skulle hjälpa oss att förbättra våra erbjudanden i framtiden som förhoppningsvis passar dig bätte.",
    Autopilot_WhatIsMissing: "Tycker något saknas eller kan förbättras?",
    Autopilot_WhatIsTheReason: "Vad är anledningen till att Bokio Autopilot inte passar dig?",
    Autopilot_WhatsIncluded: "Vad som ingår",
    Autopilot_YouCanUseTheService: "Du kan redan idag börja använda tjänsten på",
    Bookeeping_SearchInfo: "Sök i sökrutan ovan för att hitta rätt bokföringsmall till det du ska bokföra. Vi har skapat dessa bokföringsmallar så du slipper tänka på debet och kredit.",
    Deals_BokioDeals: "Bokio Deals",
    Deals_BuyDeals: "Köp Deals",
    Deals_ChoosePaymentInterval: "Välj om du vill betala för Bokio Deals per år eller månad.",
    Deals_DontNeed: "Jag har inte behov av det för tilllfället",
    Deals_DontSeeTheValue: "Jag ser inte värdet av tjänsten",
    Deals_Feature1: "Ta del av 30+ färdigförhandlade avtal med ledande leverantörer",
    Deals_Feature2: "Upp till 45 % rabatt på varor och tjänster",
    Deals_Feature3: "Avtal inom juridiska tjänster, försäkring, frakt, pension m.m.",
    Deals_Feature4: "Fokusera på din verksamhet. Vi jämför och förhandlar priser åt dig",
    Deals_GetStartedWithDeals: "Kom igång med Bokio Deals",
    Deals_HelpUsGetBetter: "Var med och påverka tjänsten",
    Deals_HelpUsImprove: "Hjälp oss att förbättras",
    Deals_Monthly: "Månadsvis",
    Deals_MonthlyPrice: "99 kr/månad",
    Deals_NotInterested: "Inte intresserad",
    Deals_Other: "Annat",
    Deals_PayLess: "Betala mindre för dina inköp med Bokio Deals",
    Deals_PayLessDetails: "Konceptet är enkelt – spara pengar och dyrbar tid samtidigt som du samlar dina inköp på ett och samma ställe. Kombinationen av de gemensamma volymerna och våra förhandlingar möjliggör att du som Bokio Deals-användare får storbolagspriser. Ju fler företag som ansluter sig till Deals desto större blir rabatterna vi kan tillhandahålla. Vi jämför och granskar tjänster och leverantörer, samt förhandlar villkor och priser åt dig medan du kan fokusera på din kärnverksamhet.",
    Deals_PayLessList: "- Upp till 35 % rabatt på förbrurkningsinventarier\n- Upp till 15 % på resor och boende\n- Upp till 45 % på frakt och logistik\n- Upp till 25 % på elektronik och telefoni\n- Upp till 25 % på försäkring och pension\n- Upp till 10 % på drivmedel",
    Deals_Payment: "Betalning",
    Deals_PaymentInformation: "Betalningsinformation anges i samband med att du slutför köpet.",
    Deals_Price: "Priset",
    Deals_ReadMore: "Läs mer",
    Deals_ReceivedFeedbackInterested: "Vi har tagit emot din feedback och vi kommer att göra vårt bästa för att förbättra Bokio Deals ytterligare. Vi hör av oss till dig när tjänsten släpps, så att du blir en av de första som får ta del av den.",
    Deals_ReceivedFeedbackNotInterested: "Vi har tagit emot din feedback och vi kommer att göra vårt bästa för att förbättra Bokio Deals och andra erbjudanden i framtiden.",
    Deals_ReduceYourCosts: "Sänk dina kostnader och spara tid på dina inköp av tjänster och varor.",
    Deals_Save16: "Spara 16 %",
    Deals_ThankYouGetBetter: "Tack för att du hjälper oss att bli bättre",
    Deals_ThankYouImprove: "Tack för att du hjälper oss att förbättras",
    Deals_ThankYouInterest: "Tack för att du har visat intresse för Bokio Deals",
    Deals_TheServiceIsNotAvailable: "Tjänsten är inte tillgänglig riktigt än. Vi håller för närvarande på att utvärdera hur tjänsten ska läggas upp. Så fort den släpps kommer du vara en av de första som får ta del av den.",
    Deals_WeAppriciateYourFeedback: "Vi uppskattar feedback och om du har några tankar kring Bokio Deals får du väldigt gärna beskriva dina behov och tankar så att vi kan utforma en tjänst i världsklass.",
    Deals_WeUnderstandNotInterested: "Vi förstår att du inte är intresserad av Bokio Deals men vi skulle verkligen uppskatta om du vill berätta varför. Det skulle hjälpa oss att förbättra våra erbjudanden i framtiden som förhoppningsvis passar dig bätte.",
    Deals_WhatDoYouThink: "Tycker något saknas eller kan förbättras?",
    Deals_WhatIsTheReason: "Vad är anledningen till att Bokio Deals inte passar dig?",
    Deals_WhatsIncluded: "Vad som ingår",
    Deals_Yearly: "Årsvis",
    Deals_YearlyPrice: "999 kr/år",
    FAQSection_link: "FAQ istället",
    FeatureInterest_HelpUsImproveDescription: "Vi förstår att du inte är intresserad men vi skulle verkligen uppskatta om du kan förklara varför. Det kommer hjälpa oss att erbjuda ännu bättre funktioner som förhoppningsvis passar dig bättre.",
    FeatureInterest_WhatsTheReason: "Vad är anledningen till att {0} inte passar dig?",
    FinancialOverview_AccountingAndBankDetails: "Bokföring och bank",
    FinancialOverview_AccountingDetails: "Bokföring",
    FinancialOverview_BankDetails: "Bank",
    FinancialOverview_Customers: "Kunder",
    FinancialOverview_Days: "dagar",
    FinancialOverview_DueToday: "Betalas idag",
    FinancialOverview_Expenses: "Utgifter",
    FinancialOverview_Expenses_EmptyStateText: "Så fort du har börjat bokföra utgifter så kommer du se dina topp 5 utgifter här!",
    FinancialOverview_GetStartedWithBookkeeping: "Börja bokföra",
    FinancialOverview_Header: "Finansiell översikt",
    FinancialOverview_Income: "Inkomst",
    FinancialOverview_IncomeAndExpense_EmptyStateAction: "Bokför verifikat",
    FinancialOverview_IncomeAndExpense_EmptyStateText: "Denna graf visar dina inkomster och kostnader. Just nu har vi inte tillräckligt med data för att visa något under den valda perioden. Börja bokföra för att se din data i diagrammet!",
    FinancialOverview_IncomeAndExpense_Header: "Inkomst och utgifter",
    FinancialOverview_InvoiceOwedToYou: "Inkommande fakturor",
    FinancialOverview_Items: "Artiklar",
    FinancialOverview_Last12Months: "Senaste 12 månaderna",
    FinancialOverview_NewInvoice: "Ny faktura",
    FinancialOverview_NewSupplierInvoice: "Ny leverantörsfaktura",
    FinancialOverview_OverDue: "Försenad",
    FinancialOverview_ProfitAndLoss: "Vinst och förlust",
    FinancialOverview_RecordExpenses: "Bokför utgift",
    FinancialOverview_Result: "Resultat",
    FinancialOverview_SupplierInvoiceYouNeedToPay: "Utgående leverantörsfakturor",
    FinancialOverview_Top5_CreateInvoice: "Ny faktura",
    FinancialOverview_Top5Articles_EmptyStateText: "Så fort du har bokfört några fakturor så kommer du se dina topp 5 artiklar här",
    FinancialOverview_Top5Customers: "Topp 5 kunder/artiklar",
    FinancialOverview_Top5Customers_EmptyStateText: "Så fort du har bokfört några fakturor så kommer du se dina topp 5 kunder här",
    FinancialOverview_Top5Expenses: "Topp 5 utgifter",
    FinancialOverview_Top5Expenses_Other: "Övrigt",
    FinancialOverview_TotalExpense: "Utgifter totalt",
    FinancialOverview_TotalIncome: "Inkomst totalt",
    FinancialOverview_ViewArticles: "Visa artiklar",
    FinancialOverview_ViewCustomerInvoices: "Visa alla aktiva fakturor",
    FinancialOverview_ViewCustomers: "Visa kunder",
    FinancialOverview_ViewProfitAndLoss: "Visa resultatrapporten",
    FinancialOverview_ViewSupplierInvoices: "Visa alla aktiva leverantörsfakturor",
    GetStarted_Bookkeeping_ListItem1: "Importera tidigare bokföring",
    GetStarted_Bookkeeping_ListItem2: "Kontrollera ingående balanser",
    GetStarted_Bookkeeping_ListItem3: "Bokför ditt första underlag",
    GetStarted_Bookkeeping_ReadMoreText: "Läs mer om hur du kommer igång med bokföring",
    GetStarted_Bookkeeping_Text1: "Ladda upp dina underlag och bokför med vår smarta bokföring.",
    GetStarted_Bookkeeping_Text2Heading: "Om du byter till Bokio",
    GetStarted_Bookkeeping_Text3Heading: "Se hur bokföringen i Bokio går till",
    GetStarted_Bookkeeping_Title: "Bokföring",
    GetStarted_Invoicing_ListItem1: "Skapa din första faktura",
    GetStarted_Invoicing_ReadMoreText: "Läs mer om hur du kommer igång med fakturering",
    GetStarted_Invoicing_Text1: "Skapa och skicka fakturor lätt med några enkla steg. Få uppdateringar på försenade eller obetalda fakturor.",
    GetStarted_Invoicing_Text2: "Du kan skicka fakturorna direkt via e-post eller ladda ned som pdf.",
    GetStarted_Invoicing_Text3Heading: "Se hur du skapar en faktura",
    GetStarted_Invoicing_Title: "Fakturering",
    GetStarted_Notification_LinkToGettingStarted: "Välkommen tillbaka! För att komma igång med resten av Bokio, kan du se fler guider under Hjälp. {Visa guiden Kom igång}",
    GetStarted_SalaryAndExpenses_ListItem1: "Lägg in anställda",
    GetStarted_SalaryAndExpenses_ListItem2: "Skapa löner eller bjud in anställda som kan skapa utlägg",
    GetStarted_SalaryAndExpenses_ListItem3: "Skapa din första lön",
    GetStarted_SalaryAndExpenses_ReadMoreText: "Läs mer om hur du kommer igång med löner",
    GetStarted_SalaryAndExpenses_Text1: "Hantera företagets personal, löner och kringkostnader snabbt och enkelt.",
    GetStarted_SalaryAndExpenses_Text2Heading: "Så här kommer du igång",
    GetStarted_SalaryAndExpenses_Text3Heading: "Se hur du hanterar löner",
    GetStarted_SalaryAndExpenses_Title: "Löner",
    GotIt_Confirm_action: "Jag fattar!",
    HelpMenuItem_ShowOnboarding: "Visa startguiden",
    HideOnboardingModal_GoodJob: "Känner du dig klar med startguiden? Gött! Vill du se den igen vid senare tillfälle kan du alltid gå in under hjälp-knappen överst på sidan.",
    Important_Dates_Wizard_Start_Button: "Kom igång nu",
    Important_Dates_Wizard_Start_Heading: "Kom igång med viktiga datum",
    Important_Dates_Wizard_Start_Heading_P_2: "Viktiga datum skiljer sig åt mellan olika företag men kan exempelvis vara då du ska deklarera moms, lämna in arbetsgivardeklaration eller betala preliminärskatt. {Se till att ditt räkenskapsår är korrekt inställt} så att du får datum som är relevanta för din verksamhet.",
    Important_Dates_Wizard_Start_P_1: "Som företagare finns det flera händelser och deadlines under året att hålla reda på. På den här sidan får du hjälp med detta!",
    Important_Dates_Wizard_Start_P_2: "För att sätta upp dina Viktiga datum kommer du också få besvara några ytterligare frågor. Känner du dig osäker på någon del kan du alltid ändra vid senare tillfälle.",
    ImportantDates_FaultySettings_Quarterly_Error: "Felaktiga inställningar på nuvarande räkenskapsår. Momsredovisning per kvartal stöds endast om förväntad omsättning är mindre är 40 miljoner SEK. Inga nya viktiga datum laddas från Skatteverket. [Granska inställningar](bokio:/companyid/settings-r/fiscals)",
    ImportantDates_FaultySettings_Yearly_Error: "Felaktiga inställningar på nuvarande räkenskapsår. Momsredovisning per helår stöds endast om förväntad omsättning är mindre är 1 miljon SEK. Inga nya viktiga datum laddas från Skatteverket. [Granska inställningar](bokio:/companyid/settings-r/fiscals)",
    ImportantDatesDisclaimer: "Vi hämtar viktiga datum från Skatteverket baserat på dina företagsinställningar. Vissa datum kanske inte är relevanta för dig, då kan du välja att dölja dem individuellt eller alla framtida datum av den typen. Om dolda datum i framtiden blir relevant kan du visa dem igen.",
    Navigation_Menu_AboutNavigation_action: "Om navigationen",
    Navigation_NewNavigation_heading: "Vi har lanserat en enklare navigation!",
    Navigation_NewNavigationReadMore_Message: "Du kan lära dig mer om vad som har uppdaterats under vår nya hjälp.",
    Onboarding_BbaToPremiumPlus_PromotionModal_Calculation: "Beräkning",
    Onboarding_BbaToPremiumPlus_PromotionModal_Calculation_account: "{0} Cashback på insatt kapital",
    Onboarding_BbaToPremiumPlus_PromotionModal_Calculation_card: "{0} Cashback på kortköp",
    Onboarding_BbaToPremiumPlus_PromotionModal_calculator_heading: "Cashback med Premium {0}",
    Onboarding_BbaToPremiumPlus_PromotionModalHeading: "Cashback och bokföringssupport är ett {0} i vardagen!",
    Onboarding_BbaToPremiumPlus_PromotionModalHeading_preamble: "Baserat på ditt företagskontos aktivitet kan du få **{0} / månad** tillbaka genom att uppgradera till Premium Plus. Använd vår kalkylator för att få en tydlig bild av hur mycket du kan tjäna varje månad:",
    Onboarding_FreeToPaidPlan_PromotionItem_tagline_bba: "Alla funktioner och ett integrerat bankkonto och företagskort",
    Onboarding_FreeToPaidPlan_PromotionItem_tagline_plus: "Alla funktioner, ett integrerat företagskonto, {0} på kapital och {1} på kortköp tillbaka till fickan.",
    Onboarding_FreeToPaidPlan_PromotionItem_tagline_premium: "Alla funktioner i vår allt-i-ett-lösning",
    Onboarding_FreeToPaidPlan_PromotionListItem_with_bba: "med företagskonto",
    Onboarding_FreeToPaidPlan_PromotionModal_CTA_button: "Läs mer",
    Onboarding_FreeToPaidPlan_PromotionModalHeading_cashback: "Förenklad bokföring {0} {1} /månad i cashback!",
    Onboarding_FreeToPaidPlan_PromotionModalHeading_noCashback: "Det enda företagskontot du behöver",
    Onboarding_FreeToPaidPlan_PromotionModalPreamble: "Uppgradera till Premium-planen och aktivera ditt Bokio Business-konto för ännu bättre bokföring. Välj det Bokio-lösningen som passar dig.",
    Onboarding_FreeToPaidPlan_PromotionModalPreamble_cashback: "Baserat på dina kontoaktiviteter kan du få **{0} / månad** genom cashback om du uppgraderar till Premium Plus för **599 kr /månad**. Om det inte passar dig kan du istället välja en lösning med Premium-planen.",
    Onboarding_HideOnboarding_ActionText: "Göm startguiden",
    Onboarding_PremiumToBba_PromotionListItem_bba_1: "Ännu enklare bokföring",
    Onboarding_PremiumToBba_PromotionListItem_bba_2: "Stenkoll på företagets finanser",
    Onboarding_PremiumToBba_PromotionListItem_bba_3: "Inga transaktionskostnader eller dolda avgifter",
    Onboarding_PremiumToBba_PromotionListItem_cashback_2: "{0} cashback på kortköp",
    Onboarding_PremiumToBba_PromotionListItem_cashback_3: "{0} återbäring på insatt kapital",
    Onboarding_PremiumToBba_PromotionListItem_cashback_free_support: "Gratis Bokföringssupport",
    Onboarding_PremiumToBba_PromotionListItem_heading_banking: "Företagskonto",
    Onboarding_PremiumToBba_PromotionListItem_heading_cashback: "Företagskonto med cashback",
    Onboarding_PremiumToBba_PromotionListItem_PromotionModalPreamble_banking: "Helt integrerat bankkonto med kort och mycket mer",
    Onboarding_PremiumToBba_PromotionListItem_PromotionModalPreamble_cashback_benefit: "Du kan få **{0} / månad** i cashback om du aktiverar Bokio Företagskonto och uppgradera till Premium Plus för **599 kr / månad**.",
    Onboarding_PremiumToBba_PromotionListItem_PromotionModalPreamble_cashback_not_benefit: "Du kan få cashback om du aktiverar Bokio Företagskonto och uppgradera till Premium Plus.",
    Onboarding_PremiumToBba_PromotionListItem_PromotionModalPreamble_listheading: "Bokio Företagskonto och:",
    Onboarding_PremiumToBba_PromotionModal_CTA_button_bba: "Skaffa Bokio Företagskonto",
    Onboarding_PremiumToBba_PromotionModalHeading_PremiumToBba_cashback: "Som en traditionell bank - {0} {1} i cashback",
    Onboarding_PremiumToBba_PromotionModalHeading_PremiumToBba_noCashback: "Uppgradera ditt företagskonto utan extra kostnad",
    Onboarding_PremiumToBba_PromotionModalHeading_PremiumToBba_preamble: "Upplev fördelarna med att kombinera bokföring och bank. Öppna ditt Bokio Företagskonto idag och njut av att ha allt på ett och samma ställe. Du kan också undersöka om cashback genom Premium Plus vore bra för dig.",
    Onboarding_PromotionChecklist_bba: "Gratis företagskonto",
    Onboarding_PromotionChecklist_bookkeeping: "Premium bokföring",
    Onboarding_PromotionChecklist_cashback: "Cashback",
    Onboarding_PromotionChecklist_support: "Gratis bokföringssupport",
    Onboarding_PromotionListItem_Recommended: "Rekommenderad",
    Onboarding_PromotionModal_CTA_button: "Läs mer",
    Onboarding_PromotionModal_postpone_button: "Kanske senare",
    Onboarding_TrialToPaidPlan_PromotionItem_tagline_bba: "Alla funktioner, ett integrerat bankkonto, företagskort och mycket mer",
    Onboarding_TrialToPaidPlan_PromotionItem_tagline_plus: "Alla funktioner, ett integrerat företagskonto med {0} återbärning på kapital och {1} cashback på kortköp.",
    Onboarding_TrialToPaidPlan_PromotionItem_tagline_premium: "Alla funktioner i vår allt-i-ett-lösning",
    Onboarding_TrialToPaidPlan_PromotionListItem_with_bba: "med Bokio företagskonto",
    Onboarding_TrialToPaidPlan_PromotionModal_cancelButton: "Fortsätt med Free",
    Onboarding_TrialToPaidPlan_PromotionModal_CTA_button: "Läs mer",
    Onboarding_TrialToPaidPlan_PromotionModalHeading_noCashback: "Tack för att du testat Bokio, din provperiod är nu över",
    Onboarding_TrialToPaidPlan_PromotionModalPreamble: "Du kan fortsätta med upplevelsen från provperioden genom att skaffa Premium alternativt uppgradera Premium med ett företagskonto eller starta vår Premium Plus-plan om du vill aktivera cashback på ditt företagskonto. Om du väljer att inte uppgradera din prisplan forsätter du med Free.",
    Onboarding_TrialToPaidPlan_PromotionModalPreamble_cashback: "Baserat på dina kontoaktiviteter kan du få {0} genom cashback om du uppgraderar till Premium Plus för {1}. Om det inte passar dig kan du istället fortsätta med upplevelsen från provperioden genom att skaffa Premium. Du kan även nedgradera till Gratis om du är nöjd med ett minimalt erbjudande.",
    OnboardingCard_Accounting_ActionText: "Bokför verifikat",
    OnboardingCard_Accounting_Description: "Bokför regelbundet och få stenkoll på ditt företag.",
    OnboardingCard_Accounting_Header: "Smart bokföring",
    OnboardingCard_Accounting_LearnMoreLink: "https://www.bokio.se/hjalp/komma-igang/kom-igang-med-bokio/kom-igang-med-bokforingen-i-bokio/",
    OnboardingCard_AddCustomer_ActionText: "Skapa kund",
    OnboardingCard_AddCustomer_Description: "Lägg in dina kunders kontaktuppgifter så att de syns på fakturan",
    OnboardingCard_AddCustomer_Header: "Lägg till kunder",
    OnboardingCard_AddCustomer_LearnMoreLink: "https://www.bokio.se/hjalp/fakturera-kunder/kunder-artiklar/lagg-till-en-kund/",
    OnboardingCard_BankFeed_LearnMoreLink: "https://www.bokio.co.uk/help/bank-feeds/",
    OnboardingCard_Consultation_Action: "Boka gratis genomgång",
    OnboardingCard_Consultation_Description: "Just nu kan du träffa oss i ett videosamtal och få hjälp med det du behöver.",
    OnboardingCard_Consultation_Header: "Personlig genomgång",
    OnboardingCard_CustomiseInvoice_ActionText: "Anpassa fakturor",
    OnboardingCard_CustomiseInvoice_Description: "Lägg till logotyp, ändra design och välj färger",
    OnboardingCard_CustomiseInvoice_Header: "Anpassa dina fakturor",
    OnboardingCard_CustomiseInvoice_LearnMoreLink: "https://www.bokio.se/hjalp/fakturera-kunder/skapa-faktura/layout-logotyp-och-farg-pa-faktura/",
    OnboardingCard_Expense_ActionText: "Skapa utlägg",
    OnboardingCard_Expense_Description: "Håll koll på anställdas utlägg och hantera utbetalning smidigt.",
    OnboardingCard_Expense_Header: "Smidig utläggshantering",
    OnboardingCard_Expense_LearnMoreLink: "https://www.bokio.se/hjalp/utlaggshantering/komma-igang/sa-har-kommer-du-igang-med-utlaggshantering/",
    OnboardingCard_Help_Action: "Visa guiden",
    OnboardingCard_Help_Description: "5 enkla steg för att komma igång.",
    OnboardingCard_Help_Header: "Startguide",
    OnboardingCard_ImportSIE_LearnMoreLink: "https://www.bokio.se/hjalp/komma-igang/importera-bokforing/importera-bokforing-steg-for-steg/",
    OnboardingCard_InviteUser_Description: "Samarbeta med din affärskollega, bokföringskonsult eller anställda",
    OnboardingCard_InviteUser_Header: "Bjud in användare",
    OnboardingCard_InviteUser_LearnMoreLink: " https://www.bokio.se/hjalp/bokio-medlemskap/ditt-bokio-konto/anvandarrattigheter/",
    OnboardingCard_Invoice_LearnMoreLink: "https://www.bokio.se/hjalp/komma-igang/kom-igang-med-bokio/kom-igang-med-fakturering-i-bokio/",
    OnboardingCard_Invoices_ActionText: "Skapa faktura",
    OnboardingCard_Invoices_Description: "Skapa och skicka fakturor med bara några få klick.",
    OnboardingCard_Invoices_Header: "Optimera din försäljning",
    OnboardingCard_SIEImport_ActionText: "Importera bokföring",
    OnboardingCard_SIEImport_Description: "Importera din äldre bokföring med SIE-filer",
    OnboardingCard_SIEImport_Header: "Importera äldre bokföring",
    OnboardingCard_Supplier_ActionText: "Registrera leverantörsfaktura",
    OnboardingCard_Supplier_Description: "Registrera dina leverantörsfakturor snabbt och enkelt.",
    OnboardingCard_Supplier_Header: "Full koll på dina kostnader",
    OnboardingCard_Supplier_LearnMoreLink: "https://www.bokio.se/hjalp/leverantorsfakturor/rakningar/hur-fungerar-leverantorsfakturor/",
    OnboardingCard_Tour_Description: "Få tips och se hur du hittar i Bokio.",
    OnboardingCard_Tour_Header: "Snabbgenomgång",
    OnboardingCardBlock_Header: "Kom igång med Bokio",
    OnboardingChecklist_menu_hide_text: "Dölj checklistan",
    OnboardingChecklist_menu_show_text: "Visa checklistan",
    OnboardingInviteUser_Heading: "Bjud in ny användare",
    OnboardingInviteUser_SubText: "Bjud in en ny användare och samarbeta i Bokio. Oavsett om du bjuder in din redovisningskonsult, affärskollega, löneadministratör eller en anställd är det enkelt att samarbeta i Bokio.",
    OnboardingVideoCard_ActionText: "Visa mig runt (5 min)",
    OnboardingVideoCard_Description: "Vårt mål är att göra det enkelt att driva företag. Vi vill hjälpa dig att spara tid så att du kan fokusera på det som är värdeskapande i din verksamhet. Låt oss visa dig hur!",
    OnboardingVideoCard_Header: "Vi är här för att hjälpa ditt företag att växa",
    Premium_BuyPremium: "Köp premium",
    Premium_Cost: "299 kr/månad",
    Premium_DontNeed: "Jag har inte behov av det för tilllfället",
    Premium_Expectations: "Utbudet matchar inte mina förväntningar",
    Premium_Feature1: "Bokio synkar och bokför händelser på ditt skattekonto",
    Premium_Feature1Li1: "Bokio bokför automatiskt alla transaktioner på ditt skattekonto och stämmer av balanserna",
    Premium_Feature1Li2: "Lämna in dina arbetsgivardeklarationer (AGI) direkt till Skatteverket utan att behöva lämna Bokio",
    Premium_Feature2: "Bokio synkar och bokför alla dina Zettle transaktioner",
    Premium_Feature2Li1: "Spara tid och var säker på att det blir rätt. Alla transaktioner på ditt Zettle-konto importeras dagligen till Bokio och bokförs automatiskt utan att du behöver lyfta ett finger.",
    Premium_Feature2Li2: "Få en heltäckande överblick över ditt företags ekonomi genom att se dina Zettle-transaktioner tillsammans med din övriga bokföring i Bokio.",
    Premium_Feature3: "Få svar på bokföringsfrågor och snabb support via chatt",
    Premium_Feature3Li1: "Du är alltid först i kön. Dina frågor besvaras före alla gratisanvändare.",
    Premium_Feature3Li2: "Vi hjälper dig även med bokföringsfrågor, utöver tekniska frågor som ingår i vår gratissupport. Till exempel får du svar på frågor som vilket konto du ska bokföra ett specifikt verifikat på, eller om du själv har bokfört rätt.",
    Premium_Feature3Li3: "Få hjälp att få ut maximalt av Bokio. Varannan måndag så erbjuder Bokio Webinars där vi går igenom hur man bokför verifikat, skickar fakturor, skapar upp löner och andra viktiga funktioner i Bokio.",
    Premium_GetStarted: "Kom igång med Bokio Premium",
    Premium_HelpUsGetBetter: "Var med och påverka tjänsten",
    Premium_HelpUsGetBetterDescription: "Vi uppskattar feedback och om du har några tankar kring Bokio Premium får du väldigt gärna beskriva dina behov och tankar så att vi kan skapa ett bokföringsprogram i världsklass.",
    Premium_HelpUsImprove: "Help us improve",
    Premium_Included: "Inkluderat",
    Premium_Monthly: "Månadsvis",
    Premium_NotInterested: "Inte intresserad",
    Premium_Other: "Annat",
    Premium_Payment: "Betalning",
    Premium_PaymentDescription: "Välj om du vill betala för Bokio premium per år eller månad.",
    Premium_PaymentInformation: "Betalningsinformation anges i samband med att du slutför köpet.",
    Premium_Price: "Priset",
    Premium_ReadMore: "Läs mer",
    Premium_ReceivedFeedbackInterested: "Vi har tagit emot din feedback och vi kommer att göra vårt bästa för att förbättra Bokio Premium ytterligare. Vi hör av oss till dig när tjänsten släpps, så att du blir en av de första som får ta del av den.",
    Premium_ReceivedFeedbackNotInterested: "Vi har tagit emot din feedback och vi kommer att göra vårt bästa för att förbättra Bokio Premium och andra erbjudanden i framtiden.",
    Premium_Save10: "Spara 10%",
    Premium_SaveTimeAndSpeedUp: "Spara tid och förbättra arbetsflödet med integrationer och snabbare support.",
    Premium_ThankYouHelp: "Tack för att du hjälper oss att bli bättre",
    Premium_ThankYouImprove: "Tack för att du hjälper oss att förbättras",
    Premium_ThankYouInterest: "Tack för att du har visat intresse för Bokio Premium",
    Premium_ThankYouInterestDescription: "Funktionerna är inte tillgängliga riktigt än. För att veta hur vi ska prioritera vårt arbete är det ett måste för oss att göra den här typen av marknadsundersökningar. Tack vare din medverkan har vi fått in mer information kring hur vi bör utforma framtidens Bokio. Som tack lovar vi att höra av oss till dig när tjänsten släpps, så att du blir en av de första som får ta del av den.",
    Premium_Title: "Bokio Premium",
    Premium_Value: "Jag ser inte värdet av tjänsten",
    Premium_WhatIsMissing: "Tycker något saknas eller kan förbättras?",
    Premium_WhatsIncluded: "Vad som ingår",
    Premium_Yearly: "Årsvis",
    Premium1_MonthlyPrice: "199 kr/månad",
    Premium1_YearlyPrice: "2 149 kr/år",
    Premium2_MonthlyPrice: "299 kr/månad",
    Premium2_YearlyPrice: "3 229 kr/år",
    ProductTour_StepFour_FBLinkSrc: "https://www.facebook.com/groups/240735629672293/",
    ProductTour_StepFour_FBLinkText: "Gå med i Facebook-gruppen",
    ProductTour_StepFour_P1: "Överst på sidan hittar du vår hjälp-sektion. Använd den för att få svar på dina frågor.",
    ProductTour_StepFour_P2: "Eller varför inte gå med i vår community på Facebook för att få hjälp från andra företagare precis som du själv?",
    ProductTour_StepFour_Title: "Fortfarande osäker på något?",
    ProductTour_StepOne_AccountingText: "Här bor din bokföring med samtliga räkenskapsår och verifikat. Skapa ett nytt verifikat eller avsluta året genom att göra bokslut.",
    ProductTour_StepOne_ExpenseText: "Lägg till dina anställda och skapa löner. Har du köpt något åt företaget med dina privata pengar? Då kan du och dina anställda ladda upp det i utläggsfunktionen.",
    ProductTour_StepOne_InvoiceText: "Fakturera dina kunder och bokför automatiskt. Håll koll på kommande inbetalningar och skicka påminnelse direkt till kund.",
    ProductTour_StepOne_SalaryAndExpense: "Lön och utläggshantering",
    ProductTour_StepOne_SuppliersText: "Registrera dina leverantörsfakturor och få hjälp att hålla koll på kommande utbetalningar.",
    ProductTour_StepOne_Title: "Navigera enkelt i Bokio",
    ProductTour_StepThree_GuideLinkSrc: "https://www.bokio.se/hjalp/bokio-medlemskap/ditt-bokio-konto/flera-anvandare-som-kan-jobba-i-samma-foretag/",
    ProductTour_StepThree_GuideLinkText: "Den här guiden visar hur du bjuder in din redovisningskonsult.",
    ProductTour_StepThree_H1: "Bjud in användare",
    ProductTour_StepThree_InParaLinkText: "Inställningar \u003E Användare",
    ProductTour_StepThree_P1: "Under Inställningar i menyn kan du gå in och justera det mesta som rör ditt företag. Se till att kontrollera respektive räkenskapsårs start- och slutdatum, bokföringsmetod och momsperiod så att de stämmer med det du registrerat hos Skatteverket.",
    ProductTour_StepThree_P2: "Har du en delägare eller redovisningskonsult som du vill dela ditt företag med? Bjud in användare så de har tillgång till en del eller hela ditt företag i Bokio under",
    ProductTour_StepThree_Title: "Ställ in dina företagsinställningar",
    ProductTour_StepTwo_Li1Header: "Bokför med eller utan kvitto",
    ProductTour_StepTwo_Li1Text: "Ladda upp kvittot eller fakturan direkt eller bokför en transaktion utan underlag. Du kan alltid lägga till detta senare.",
    ProductTour_StepTwo_Li2Header: "Importera från banken",
    ProductTour_StepTwo_Li2Text: "Importera banktransaktioner och matcha med verifikat eller uppladdade underlag.",
    ProductTour_StepTwo_Li3Header: "Skapa en faktura",
    ProductTour_StepTwo_Li4Header: "Skapa löner",
    ProductTour_StepTwo_P1: "Med den stora gröna knappen uppe till vänster kan du direkt bokföra alla nya händelser.",
    ProductTour_StepTwo_P2: "Klicka på pilen på knappen för att få upp fler alternativ:",
    ProductTour_StepTwo_Title: "Hantera alla nya händelser från Ladda upp-knappen",
    ProductTourCard_CTA: "Visa mig runt",
    PromotionModal_HighDepositFree_boxHeader: "Vi rekommenderar Premium {0} - Ökad Flexibilitet med endast 3 månaders bindningstid",
    PromotionModal_HighDepositFree_boxText: "Du kan få {0}/månad i cashback om du aktiverar Bokio Företagskonto och uppgradera till Premium {1} för {2}/månad. {3}",
    PromotionModal_HighDepositFree_linkText: "Se hur vi räknat här",
    PromotionModal_HighDepositFree_listHeader: "Bokio Företagskonto och",
    PromotionModal_HightDepositFree_heading: "Få ett företagskonto - {0} ~ {1}/månad i cashback",
    PromotionModal_HightDepositFree_notice_content: "- Få Premium **Plus** till priset av Premium första månaden (spara 300 kr). Återbetalningen görs till ditt Bokio Företagskonto\n- Dubbel cashback på kortköp (2%) första månaden\n\nErbjudandet gäller t.o.m. 30 september.",
    PromotionModal_HightDepositFree_notice_title: "Tidsbegränsat erbjudande för Premium Plus",
    PromotionModal_HightDepositFree_preamble: "Allt för ditt företag på ett ställe, till ett fast pris utan dolda avgifter. Få ett integrerat bankkonto, bankgiro och ett företagskort gratis.",
    PromotionModal_LowDepositFree_cancel_button: "Ladda ner din data",
    PromotionModal_LowDepositFree_cta_button: "Sätt igång",
    PromotionModal_LowDepositFree_heading: "Dumpa din gamla bank, välj en plan och få bank utan extra kostnad",
    PromotionModal_LowDepositFree_Notice_header: "Tidsbegränsat erbjudande",
    PromotionModal_LowDepositFree_Notice_text: "Få en årsredovisning hos Årsredovisning Online eller ett presentkort på 1000 kr från vår samarbetspartner Bokadirekt.se när du uppgraderar till någon av våra planer senast 30 september. Vid ditt första dragningstillfälle får du mer information om erbjudandet per mejl.",
    PromotionModal_LowDepositFree_preamble: "Allt för ditt företag på ett ställe, till ett fast pris utan dolda avgifter. Välj den plan som passar ditt företags mål och få ett integrerat bankkonto, bankgiro och ett företagskort gratis.",
    PromotionModal_LowDepositFree_Premium_checklist: "Bokföring med mallar | Integrerad bankkonto, bankgiro och företagskort | Integrerad fakturering | Lönehantering | Automatisk bankkoppling",
    PromotionModal_LowDepositFree_Premium_price: "Från {0}/månad",
    PromotionModal_LowDepositFree_PremiumPlus_checklist: "{0} återbäring på insatt kapital | {1} cashback på kortköp | Livechatt och bokföringssupport",
    PromotionModal_LowDepositFree_PremiumPlus_price: "Från {0}/månad",
    Start_StartUsingBokioPopoverHeading: "Börja använda Bokio",
    Start_StartUsingBokioPopoverText: "Kom igång genom att ladda upp ditt första underlag, importera importera från bank/fil eller skapa din faktura eller lön.",
    Survey_Description: "Vi vill gärna lära känna dig bättre för att kunna skräddarsy Bokio efter dina behov",
    Survey_Header: "Häng kvar!",
    Survey_StepOne_Answer1: "Bokföra",
    Survey_StepOne_Answer2: "Fakturera",
    Survey_StepOne_Answer3: "Hantera löner",
    Survey_StepOne_Answer4: "Redovisa moms / bokslut",
    Survey_StepOne_Answer5: "-",
    Survey_StepOne_Answer6: "Jag kikar bara runt för tillfället",
    Survey_StepOne_CustomAnswer: "Något annat",
    Survey_StepOne_Option4: "Redovisa moms / bokslut",
    Survey_StepOne_Question: "Vad vill du göra i Bokio?",
    Survey_StepThree_Header: "Tack för att du hjälper Bokio!",
    Survey_StepThree_Text: "Vi strävar alltid efter att lära känna våra användare bättre,\nså vi kan leverera en bättre produkt till dig.",
    Survey_StepTwo: "Steg 2 av 2",
    Survey_StepTwo_AccountantOption: "Jag är redovisningskonsult",
    Survey_StepTwo_Question1: "Har du någon företagsverksamhet ännu?",
    Survey_StepTwo_Question2: "Flyttar du från ett annat bokföringsprogram?",
    Survey_StepTwo_Question3: "Tar du hjälp av en redovisningskonsult?",
    Survey_StepTwo_Question4: "Är du ny på bokföring?",
    Survey_StepTwo_Question5: "Har du några anställda förutom dig själv?",
    Survey_StepTwo_Question6: "Hanterar du din bokföring i ett annat bokföringsprogram än Bokio?",
    TestCompanyBumper_CTA: "Starta ditt riktiga företag",
    TestCompanyBumper_Message: "Du är en av dom mest aktiva testföretagsanvändarna den senaste perioden. Börjar du att känna dig bekväm med Bokio? Kanske är du redo nu att lägga till ditt riktiga företag?\n\nGlöm inte att du får tillgång till alla funktioner gratis i två månader och att du även får en gratisplan efteråt om du inte uppgraderar din plan.",
    TestCompanyBumper_Title: "Hej {0}!",
    TestCompanyPortal_CreateTestCompany: "Skapa ett testbolag",
    TestCompanyPortal_Default: "Testa bokföring och lär känna våra funktioner i ett testbolag.",
    TestCompanyPortal_InvoicesScene: "Vi skapar en kund och genererar en faktura åt dig så du kan lära känna och testa fakturahanteringen snabbt och enkelt.",
    TestCompanyPortal_Modal_Body: "I ett testbolag kan du till exempel prova att bokföra, skapa fakturor eller löner innan du gör det i ditt riktiga företag. I vissa fall kan vi också generera testdata åt dig för att du ännu enklare ska kunna lära känna funktionen.",
    TestCompanyPortal_Modal_Input_Cancel: "Avbryt",
    TestCompanyPortal_Modal_Input_CreateTestCompany: "Skapa testbolag",
    TestCompanyPortal_Modal_Input_Default: "{0} (test)",
    TestCompanyPortal_Modal_Input_Title: "Namnet på ditt testbolag",
    TestCompanyPortal_Modal_Title: "Vill du skapa ett testbolag?",
    TestCompanyPortal_Title: "Testa den här funktionen i ett testbolag",
    TestCompanyPortal_Transactions: "Vi genererar ett antal transaktioner åt dig så att du kan prova på att bokföra direkt.",
    TroubleLoadingVideo_message: "Problem med att se videon? Läs vår {0}",
    WelcomeMessage_Viktor_Message: "Jag heter Viktor och är medgrundare av Bokio. Du tycker förmodligen precis som vi att bokföring och annan företagsadmin är onödigt krångligt. Det är just därför Bokio finns, för att göra företagslivet enklare!\n\nNu är ditt företag igång och ett bra sätt att komma igång är att helt enkelt bokföra din första händelse.\n\nVälkommen till Bokio!",
    WelcomeMessageTestCompany_Viktor_Message: "Jag heter Viktor och är en av grundarna till Bokio. Med testbolaget du precis skapat kan du enkelt prova dig fram och lära känna systemet helt i din egen takt.\n\nTesta gärna att bokföra något med våra färdiga bokföringsmallar, skapa en faktura eller ta en titt i vår lönefunktion. Du kan läsa mer om [hur testbolag fungerar här.](https://www.bokio.se/hjalp/komma-igang/kom-igang-med-bokio/konvertera-testbolag-till-riktigt-foretag/)\n\nVälkommen till Bokio!",
    WelcomeModalBox_RequiresPlan: "Kräver en plan",
    Wizard_Done_Heading: "Viktiga datum är nu anpassat för ditt företag",
    Wizard_Done_Heading_P_1: "Vi har nu implementerat alla datum som är viktiga för ditt företags behov och exkluderat deadlines för sådant som inte är relevant. Om saker och ting förändras för ditt företag kan du alltid visa dolda datum och uppgifter där. {Läs mer i vårt hjälpcenter här}.",
    Wizard_Question_CustomersInOtherEUCountries_Heading: "Har företaget kunder i ett annat EU-land?",
    Wizard_Question_CustomersInOtherEUCountries_No: "Inga deadlines relaterat till detta ämne kommer importeras, du kan ändra dig senare på sidan för Viktiga datum om du skulle ångra dig.",
    Wizard_Question_CustomersInOtherEUCountries_Yes: "Detta alterantiv låter dig avgöra vilka specifika EU-skatter som är relevanta för det här företaget.",
    Wizard_Question_Employees_Heading: "Har detta företag anställda?",
    Wizard_Question_Employees_No: "Inga deadlines relaterat till detta ämne kommer importeras, du kan ändra dig senare på sidan för Viktiga datum om du skulle ångra dig.",
    Wizard_Question_Employees_Yes: "Detta kommer importera deadlines för  {arbetsgivardeklaration} samt {arbertsgivaravgift och avdragen skatt}. ",
    Wizard_Question_RealEstate_Heading: "Äger företaget en eller flera fastigheter?",
    Wizard_Question_RealEstate_No: "Inga deadlines relaterat till detta ämne kommer importeras, du kan ändra dig senare på sidan för Viktiga datum om du skulle ångra dig.",
    Wizard_Question_RealEstate_Yes: "Detta importerar deadline för {fastighetsdeklaration}.",
    Wizard_Question_SellGoodsFromEUToIndividuals_Heading: "Säljer företaget varor till privatpersoner inom EU som köpts in från ett land inom EU?",
    Wizard_Question_SellGoodsFromEUToIndividuals_No: "Inga deadlines relaterat till detta ämne kommer importeras, du kan ändra dig senare på sidan för Viktiga datum om du skulle ångra dig.",
    Wizard_Question_SellGoodsFromEUToIndividuals_Yes: "Detta importerar deadlines för {moms på OSS}.",
    Wizard_Question_SellGoodsFromOutsideEUToIndividuals_Heading: "Säljer företaget varor till privatpersoner inom EU som importerats från ett land utanför EU?",
    Wizard_Question_SellGoodsFromOutsideEUToIndividuals_No: "Inga deadlines relaterat till detta ämne kommer importeras, du kan ändra dig senare på sidan för Viktiga datum om du skulle ångra dig.",
    Wizard_Question_SellGoodsFromOutsideEUToIndividuals_Yes: "Detta importerar deadlines för {moms på IOSS} och {IOSS-deklaration}.",
    Wizard_Question_SkatteverketEService_Heading: "Planerar detta företag att använda Skatteverkets e-tjänst?",
    Wizard_Question_SkatteverketEService_No: "Detta kommer att utesluta deadlines för skatteverkets e-tjänst.",
    Wizard_Question_SkatteverketEService_Yes: "Detta kommer att utesluta deadlines för att skicka formulär med post.",
    Wizard_Question_TradeWithOtherCompaniesInEU_Heading: "Handlar företaget med företag i ett annat EU-land?",
    Wizard_Question_TradeWithOtherCompaniesInEU_No: "Inga deadlines relaterat till detta ämne kommer importeras, du kan ändra dig senare på sidan för Viktiga datum om du skulle ångra dig.",
    Wizard_Question_TradeWithOtherCompaniesInEU_Yes: "Detta importerar deadlines för {periodisk sammanställning via e-tjänst} och {blankett}.",
  };
}