/* eslint-disable */

import type { RecipesLang } from "../types/RecipesLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function RecipesLangDef(): RecipesLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    CarLeasing_Administrative: "varav Administrativa avgifter (exkl. moms)",
    CarLeasing_Administrative_Descr: "Administrativa avgifter (exkl. moms)",
    CarLeasing_Administrative_Description: "Administrativa avgifter (exkl. moms)",
    CarLeasing_AdministrativeVAT: "varav Moms Administrativa avgifter",
    CarLeasing_AdministrativeVAT_Descr: "Moms Administrativa avgifter",
    CarLeasing_AdministrativeVAT_Description: "Moms Administrativa avgifter",
    CarLeasing_High: "varav Förhöjd avgift (inkl. moms)",
    CarLeasing_High_Description: "Förhöjd avgift",
    CarLeasing_Insurance: "varav Försäkringspremier &amp; skatter",
    CarLeasing_Insurance_Description: "Försäkringspremier &amp; Skatter",
    CarLeasing_Leasing: "varav Leasingavgift (exkl. moms)",
    CarLeasing_Leasing_Description: "Moms leasing",
    CarLeasing_LeasingVAT: "varav Moms leasingavgift",
    CarLeasing_LeasingVAT_Description: "Moms leasingavgift",
    CarLeasing_Total: "Fakturans totalsumma (inkl. moms)",
    CarLeasing_Total_Description: "Totalsumma faktura (inkl. moms)",
    CarLeasing_TotalRule_FailMessage: "Värdet i beloppet måste vara totalt av alla övriga värden",
    Common_Account: "Konto",
    Common_Account_Description: "Det av dina bankkonton som har påverkats",
    Common_AmountWithNoVAT: "Summa",
    Common_AmountWithVAT: "Summa (inkl. moms)",
    Common_AmountWithVAT_Description: "Summa (inkl. moms)",
    Common_DueDate: "Förfallodatum",
    Common_DueDate_Description: "Fakturans förfallodatum eller datum när den betalades om den redan är betald på annat datum",
    Common_InvoiceDate: "Fakturadatum",
    Common_InvoiceDate_Description: "Datum då kostnaden/intäkten uppstod. Det är fakturadatum eller datum på kvittot.",
    Common_PaymentDate: "Betaldatum",
    Common_PaymentDate_Description: "Datum betalningen gjordes",
    Common_Title: "Titel",
    Common_Title_Description: "t.ex 'köpt dator'",
    Common_TotalVAT: "Summa Moms",
    Common_TotalVAT_Description: "Summan av enbart momsen",
    Common_TransactionDate: "Datum",
    Common_TransactionDate_Description: "Transaktionsdatum",
    Representation_NumberOfPeople: "Antal personer",
    Representation_NumberOfPeople_Description: "Antal personer",
    Representation_WasItMeal: "Var det en måltid?",
    Representation_WasItMeal_AnswerOptions: "Ja:0,Nej - enklare förtäring:1",
    Representation_WasItMeal_Description: "Kan förtäringen ersätta en måltid är enbart momsen avdragsgill",
  };
}