/* eslint-disable */

import type { SalaryLang } from "../types/SalaryLang";
import { translateTempImpl } from "../TranslatableLanguage";

export function SalaryLangDef(): SalaryLang {
  return {
    translateTemp: (key: string, neutral: string, svSE?: string, enGB?: string) => translateTempImpl(key, neutral, svSE, enGB),
    
    ABOUT_TO_DELETE_PAYROLL: "Du håller på att radera denna lönekörning",
    ABOUT_TO_DELETE_PAYSLIP: "Du håller på att radera denna lönespec",
    AboutToDeleteEmployeePayslip: "Du håller på att radera {0}s lönespec",
    Absences: "Frånvaro",
    AbsenceSettings: "Frånvaro",
    AbsenceSettingsAndOverridesDescription: "Ange semesterdagar för arbetstagaren. Det här steget blir upplåst så fort du är klar med det första steget.",
    AbsenceState: "Frånvarostatus",
    AbsenceType: "Typ av frånvaro",
    AccountsOfficeReference: "Accounts Office reference",
    AccountsOfficeRefTooltip: "You will find this on the letter that you received from HMRC when you first registered as an employer. It is in the format 123AB12345678.",
    ActivateEmployee: "Aktivera",
    ActivateEmployee_Validation_Information: "För att potentiellt kunna fakturera dig, behöver vi nedanstående information om ditt bolag innan du kan aktivera dina anställda",
    ActivateEmployee_Validation_MissingCompanyInformation: "Vi saknar bolagsuppgifter",
    ActivateEmployee_Validation_UpdateCompanyInformation: "Uppdatera information",
    ActivateEmployee_Validation_UpdateCompanyInformation_NoAccess: "Du har inte rättighet att uppdatera bolagsinformation. Kontakta bolagsadministratören och be denne uppdatera ovanstående info. ",
    ActiveAbsenceHeader: "Aktiv",
    ADD: "Lägg till",
    ADD_EMPLOYEE: "Ny anställd",
    ADD_EMPLOYEE2: "Lägg till anställd",
    ADD_FIRST_EMPLOYEE: "Lägg till din första anställda",
    ADD_NEW_ROW: "Lägg till ny rad",
    ADD_SALARY_ROW: "Lägg till lönealternativ",
    AddAbsence: "Lägg till frånvaro",
    AddADirector: "Add a Director",
    AddDays: "Lägg till dagar",
    AddEmployeesToPeriod: "Lägg till anställda i lönekörning",
    AddEmployerInformationFromHMRC: "Add employer information that you got when you registered as an Employer with the HMRC",
    AddJobTitle: "Lägg till jobbtitel",
    AddMoreDays: "Lägg till fler dagar",
    AddPayslips_InactiveEmployee_Tooltip: "En anställd behöver vara aktiv för att kunna användas i en lönekörning.",
    AddPayslips_InactiveEmployees_Header: "Ej aktiverade",
    ADDRESS: "Adress",
    Address_Line_1: "Address line 1",
    Address_Line_2: "Address line 2",
    Address_Line_3_Optional: "Address line 3 (optional)",
    AddStudentLoan: "Add student loan",
    Advance: "Förskott",
    AdvancedOverridesDescription: "Bokio gör det tunga arbetet för dig i alla uträkningar. Ibland så kanske du inte håller med våra uträkningar. Använd egna uträkningar för att skriva över våra värden så de passar dig. Egna uträkningar sparas på denna anställdas kontrakt, och vi kommer använde dem när vi gör löneuträkningar framöver.",
    AdvancedVacationReplacement_HelpText: "Resultatet kommer bli en semesterersättning enligt: Timmar x à x 0,12 där 0,12 är din semesterersättning i procent",
    AGI_Content_ElementName: "Elementnamn",
    AGI_Content_FieldCode: "Fältkod",
    AGI_Content_Heading: "Innehåll i filen",
    AGI_Content_HU: "Huvuduppgift",
    AGI_Content_IU: "Individuppgift",
    AGI_Content_Value: "Värde",
    AGI_Error_AGINotSupported: "AGI stöds inte för denna månaden",
    AGI_Error_MonthNotLocked: "Månaden måste låsas först",
    AGI_FileValidation_HowToFix: "Du kan antingen åtgärda dessa fel genom att först fixa problemen och sedan klarmarkera om månaden eller genom att deklarera manuellt",
    AGI_FileValidation_Title: "Skatteverket kommer troligen inte ta emot filen",
    AGI_HowTo_Heading: "Hur du lämnar in deklarationen",
    AGI_HowTo_Intro: "Du kan här ladda ner en fil som du sedan kan ladda upp till Skatteverket för att slippa fylla i hela deklarationerna manuellt.",
    AGI_HowTo_Update_Heading: "Om du behöver uppdatera informationen hos Skatteverket",
    AGI_HowTo_Update_Text: "Om du behöver uppdatera informationen är vår rekommendation att du gör detta manuellt hos Skatteverket. Vi hoppas att kunna hjälpa dig med detta framöver men i dagsläget är det inte säkert att det blir rätt om du laddar upp en ny fil från oss.",
    AGI_Info: "Du deklarerar enklast genom att ladda upp AGI-filen ([granska]({0})) vi har genererat för denna månaden till Skatteverket.",
    AGI_Link: "Inlämning av arbetsgivardeklaration (AGI)",
    AGI_Link_Short: "Inlämning av AGI",
    AGI_Title: "Arbetsgivardeklaration, AGI",
    AGI_UploadFileHere: "Du laddar upp filen till Skatteverket på {0}",
    All: "Alla",
    AllJobTitles: "Alla titlar",
    AMOUNT: "Antal",
    AMOUNT_TO_BE_PAID: "Att betala",
    Annual: "Annual",
    Arreas: "Efterskott",
    AT_AMOUNT: "à {0}",
    BANK_ACCOUNT: "Bankkonto",
    Bankgiro_ChooseFileFormat: "Välj filformat",
    Bankgiro_CustomerNumberForBankgiro: "Kundnummer för Bankgiro",
    Bankgiro_CustomerNumberHelpText: "Är du osäker på ditt kundnummer hittar du det hos din bank",
    Bankgiro_NoMoreThanXCharacters: "max {0} tecken",
    Bankgiro_Salaries: "Löner",
    Bankgiro_SupplierPayments: "Leverantörsbetalningar",
    Bankgironumber: "Bankgironummer",
    BasicDetails: "Personalinformation",
    BasicDetailsDescription: "Börja med att fylla i personalinformation! Det här steget tar ungefär 2 minuter om du har all information till hands.",
    BBA_Info: "Betala med Bokios företagskonto. Vi bokför lönekörningen automatiskt!",
    BBA_PaymentInfo: "Din lönekörning bokförs automatiskt när betalningen är genomförd.",
    BENEFIT: "Förmån",
    BENEFITS: "Förmåner",
    BillingItems_Description_FreeSeat: "Gratis aktiva anställda",
    BillingItems_Description_PaidSeats: "Extra aktiva anställda",
    BillingItems_Quantity: "Antal",
    BillingItems_Sum: "Summa",
    BillingItems_Type: "Typ",
    BokiosCalculation: "Bokios beräkning",
    Bookkeep_BookkeptAs: "Bokförd som",
    Bookkeep_CantBookkeepYet_Text: "Kan bokföras först när datumet har passerat.",
    Bookkeep_ChangeSalaries: "Lås upp lönekörning",
    Bookkeep_FinishSalaries: "Lås lönekörning",
    Bookkeep_FinishSalariesText: "Se till att du är klar med att skapa alla lönespecar innan du låser lönekörningen. Efter att du låst lönekörningen kan du fortsätta till att bokföra och betala löner och skatt.",
    Bookkeep_FromWhichAccountSalaries: "Från vilket konto betalades lönerna?",
    Bookkeep_FromWhichAccountTaxes: "Från vilket konto betalades skatterna?",
    Bookkeep_PayAndBookkeepSalaries: "Betala och bokför löner",
    Bookkeep_Preview: "Visa bokföring",
    Bookkeep_Salaries: "Löner",
    Bookkeep_SalariesBookkept: "Lönerna är bokförda",
    Bookkeep_ShowTaxReport: "Visa deklarationsunderlag",
    Bookkeep_TaxBookkept: "Skatterna är bokförda",
    Bookkeep_UndoBookkeepingOfSalariesFirst: "Bokföringen av löner måste ångras först",
    Bookkeep_UndoBookkeepingOfTaxesFirst: "Bokföringen av skatterna måste ångras först",
    Bookkeep_UnlockSalaryTaxPeriodFirst: "Löneperioden behöver låsas upp innan du kan ångra bokföringen",
    BookkeepSalaries: "Bokför löner",
    Button_LooksGood: "Ser bra ut!",
    CalculationDescription_DailySalary: "Vi baserar vår dagslönsuträkning på samma vis som de flesta fackförbund gör. 4,6% av månadslönen är ett medelvärde på dagslön om man tar alla arbetsdagar på ett år och delar upp dem.\n\nSå här ser uträkningen ut: 1 dag = 4,6% x månadslön\n\nOm vi tar värdena från {0}s kontrakt får vi följande: 1 x 4,6% x {1} = {2}",
    CalculationDescription_DailySalary_PartTimeEmployee: "Eftersom {0} jobbar deltid, måste vi ta med det i beräkningarna:\n{1} x {2} = {3}",
    CalculationDescription_HourlySalary: "Vi baserar vår timlönsuträkning på samma vis som de flesta fackförbund gör.\n{0} tjänar {1}/månad. Du har ställt in att de jobbar {2} h/vecka.\n\nSå här ser uträkningen ut: månadslön x 12 månader / (52 veckor på ett år x arbetstimmar per vecka)\n\nOm vi tar värdena från {0}s kontrakt får vi följande:\n{1} x 12 / (52 x {2}) = {4}",
    CalculationDescription_KarensDeduction: "Första gången en anställd är sjuk inom en 5-dagarsperiod ska ett karensavdrag göras från deras lön. Karensavdraget är 20% av en veckosjuklön.\n\nExempel:\n\nJanes veckolön är 5 750 kr. Sjuklönen är 80% av veckolönen (4 600kr). Karensavdraget är 20% av sjuklönen (4600 x 20% = 920 kr)\n\nMånadslön: 25 000 kr\n\nFrånvarande 2 dagar: -1150 x 2 (2 dagslöner) = - 2 300 kr\n\nKarensavdrag = -920 kr\n\nSjuklön = 2300 (2 dagslöner) x 80% = 1 840 kr\n\nLäs mer om hur karensavdraget fungerar på [verksamt.se](https://www.verksamt.se/driva/anstalla/sjukskrivning/nar-en-anstalld-blir-sjuk/rakna-ut-karensavdrag)",
    CalculationDescription_SickSalary: "När en anställd är sjuk får de en procentandel av lönen utbetald som sjuklön. Den procentandelen är som standard satt till 80%.\n\nExempel:\n\nJanes dagslön är 1 150 kr. Om Jane är sjuk två dagar i rad, får hon 80% av dagslönen i sjuklön för de två dagarna\n\nMånadslön: 25 000 kr\n\nFrånvarande 2 dagar: -1150 x 2 (2 dagslöner) = - 2 300 kr\n\nKarensavdrag = -920 kr\n\nSjuklön = 2300 (2 dagslöner) x 80% = 1 840 kr",
    CalculationDescription_VacationCompensation: "För timanställda använder vi “procentregeln”.\n\nSå här ser uträkningen ut: Timlön x 12%\n\nExempel:\n\nJohn är timanställd och har jobbat 100 timmar denna månad. Han tjänar 100 kr/h.\n\nLön: 10 000 kr\n\nSemesterersättning: 1 200 kr (10000 x 12%)",
    CalculationDescription_VacationSalary: "Vi använder \"sammalöneregeln\". Det betyder att en semesterdag är: 1 dagslön + semesterlön (0,43% av månadslönen)\n\nSå här ser uträkningen ut: Månadslönen x 0,43%\n\nOm vi tar värdena från {0}s kontrakt får vi följande:\n{1} x {2} %= {3}",
    CalculationDescription_VacationSalary_PartTimeEmployee: "Eftersom {0} jobbar deltid, måste vi ta med det i beräkningarna:\n{1} x {2} = {3}",
    CalculationDescription_WeeklySalary: "Veckolön är basen för hur man räknar ut sjuklön.\n\nSå här ser uträkningen ut: Årslön / veckor per år\n\nOm vi tar värdena från {0}s kontrakt får vi följande:\n({1} x 12) / 52 = {2}",
    CalculationDescription_WeeklySalary_PartTimeEmployee: "Eftersom {0} jobbar deltid, måste vi ta med det i beräkningarna:\n{1} x {2} = {3}",
    Calculations: "Beräkningar",
    CANCEL: "Avbryt",
    CashPayment_Info: "Om någon av dina anställda inte har ett svenskt bankkonto, så kan du fortfarande använda detta alternativ för att få en korrekt bokföring på din lönekörning.",
    CHANGE: "Förändring",
    CITY: "Ort",
    ClearingNumber: "Clearingnummer",
    Commission: "Provision",
    COMPANY_SETTINGS: "Företagsinställningar",
    CompanySettingsModal_UseSameContactPerson_CheckboxLabel: "Kontaktpersonen för lön är samma som företagets kontaktperson",
    CompenasationBalanceInfo: "Om den anställda har semesterersättning som inte har betalts ut ännu, ex. för timanställda, eller månadsanställda med provisionslön, så kan du fylla i beloppet här.",
    Compensation: "Kompensation",
    CompensationBalance: "Innestående semesterersättning",
    CompensationUnit: "Ersättning per",
    CompletedVerificationModal_Description: "Bra jobbat! Du kan se detaljer om din bokförda transaktion nedan.",
    ConfirmAndContinue: "Bekräfta och fortsätt",
    ConfirmChanges: "Konfirmera ändringar",
    ConnectOrInviteDirector: "Connect or invite this director",
    ContactPerson_Description: "Kontaktinformationen används när vi genererar filer till Skatteverket.",
    ContactPerson_Heading: "Kontaktperson för lön",
    Contract_DeleteConfirmation: "Vill du verkligen ta bort kontraktet?",
    Contract_SalaryPeriod_Description: "Om du är osäker på vilken löneperiod du ska välja, läs vår hjälpartikel ",
    Contract_TaxColumn_Short: "kolumn",
    ContractPeriod: "Period",
    ContractRenewsAt: "Förnya kontrakt",
    ContractSettings: "Kontrakt",
    ContractSettingsForm_AddPlaceOfEmployment: "Lägg till tjänsteställe",
    ContractSettingsForm_PlaceOfEmployment: "Tjänsteställe",
    CorporationTaxUniqueTaxpayerReference: "Corporation Tax Unique Taxpayer Reference",
    COST: "Kostnad",
    CotaxReference: "COTAX reference",
    CREATE_NEW_EMPLOYEE: "Skapa ny anställd",
    CreateNewPayroll: "Skapa ny lönekörning",
    CreatePayrollModal_PayDateWarning_FarFuture: "Det valda utbetalningsdatumet {0} är långt fram i tiden. Vänligen kontrollera att det stämmer.",
    CreatePayrollModal_PayDateWarning_FarPast: "Det valda utbetalningsdatumet {0} är långt bak i tiden. Vänligen kontrollera att det stämmer.",
    CreatePayrollModal_PaymentMethod_Description: "Notera att alla anställda i denna lönekörning behöver få sin lön med samma betalningsmetod. Om flera betalningsmetoder ska användas för dina anställda, skapa en lönekörning för varje betalningsmetod.",
    CreatePayrollModal_PickPaymentMethod: "Välj betalningsmetod",
    CreatePayrollModal_SetPayDate: "Sätt utbetalningsdatum",
    CreatePayrollModal_SetPayDate_Description: "Vilket datum kommer lönerna utbetalas?",
    CreatePayrollUk_Validation_Title: "After you fix these {0} errors you can create the payroll",
    CreatePayrollUKModal_CreatePayroll: "Create payroll",
    CreatePayrollUKModal_PayDate_Heading: "Set payment date",
    CreatePayrollUKModal_PayDate_Label: "Payment date",
    CreatePayrollUKModal_PayDate_SubHeading: "Which date will the directors be paid?",
    CurrentBalances: "Nuvarande balanser",
    CurrentContract: "Nuvarande kontrakt",
    CurrentContractInvalid: "Nuvarande kontrakt ogiltigt",
    CurrentEmployee: "Nuvarande anställd",
    CurrentlyHaveNoActiveDirectorsPayrolls: "You currently have no active Directors payrolls running.",
    CurrentlyOnlySupportUkResidents: "Currently we only support directors that are UK residents for Directors Payroll.",
    CUSTOM: "Egen",
    CUSTOMTAX: "Egen skattesats",
    DailySalary: "Daglön",
    DailySalaryRate: "Dagslön",
    DateOfBirth: "Födelsedatum",
    DAY: "Dag",
    DayPerYear: "Dagar per år",
    DAYS: "Dagar",
    DaysEarnedInForNextYear: "Dagar intjänade för nästa år",
    DaysLeft: "Kvarvarande dagar",
    DaysLeftThisPeriod: "Dagar kvar denna period",
    DaysPerYearInfo: "Detta värde är taget från den anställdas kontrakt. Om du behöver ändra detta värde måste du skapa ett nytt kontrakt med korrekt mängd semesterdagar per år.",
    DaysUsed: "Använda dagar",
    DaysUsedThisPeriod: "Dagar använda denna period",
    DELETE: "Ta bort",
    DeleteContract: "Ta bort kontrakt",
    DeletePayslip: "Ta bort lönespec",
    Director_PayrolIInterval_Monthly: "Månatligen",
    Director_PayrollInterval_Anually: "Årligen",
    DirectorDetails: "Director details",
    DirectorsAddress: "Director's address",
    DirectorSetup_AccountAlreadyLinkedToDirector: "This account ({0}) is already linked to a Director.",
    DirectorSetup_ConnectInviteEmployee_Button_Text: "Connect/Invite",
    DirectorSetup_ConnectInviteEmployee_Text: "Connect this Director to an existing Bokio user, or invite a new user. This will let them see their payslips when they log in.",
    DirectorSetup_ConnectToHmrc_ValidationError_Title: "Efter att du har åtgärdat dessa {0} problem kan du ansluta till HMRC",
    DirectorSetup_CreateDirector: "Create director",
    DirectorSetup_CreateDirector_Existing_Description: "Convert an already existing employee from expenses to a Director.",
    DirectorSetup_CreateDirector_Existing_Title: "An existing employee",
    DirectorSetup_CreateDirector_Myself_Description: "The director will be linked to this account. ({0})",
    DirectorSetup_CreateDirector_Myself_Title: "Myself",
    DirectorSetup_CreateDirector_New_Description: "Create a new Director ",
    DirectorSetup_CreateDirector_New_Title: "New Director",
    DirectorSetup_CreateDirector_Title: "Who are you creating a director for?",
    DirectorSetup_DirectorDetails_ValidationError_Title: "After you fix these {0} issues you can confirm the director details",
    DirectorSetup_EmployeeInviteSent: "Invite sent...",
    DirectorSetup_EmployementDetails_ValidationError_Title: "After you fix these {0} issues you can confirm the employement details",
    DirectorSetup_Employer_Save: "Save and continue",
    DirectorSetup_ExistingEmployee: "Existing employee",
    DirectorSetup_HmrcCredentials: "HMRC credentials",
    DirectorSetup_HmrcCredentials_AlreadyRegistered: "You have already registered your credentials with us",
    DirectorSetup_NewEmployeeWithoutP45: "New employee without P45",
    DirectorSetup_NewEmployeeWithP45: "New employee with P45",
    DirectorSetup_NoAccountConnected: "No account connected",
    DirectorSetup_Taxes_ValidationError_Title: "After you fix these {0} issues you can confirm the taxes",
    DirectorsPayroll_AddDirector: "Add Director",
    DirectorsPayroll_AddDirectorModal_Title: "Add directors to payroll",
    DirectorsPayroll_AddNewDirector: "Add new director",
    DirectorsPayroll_ChooseTaxPaydate: "Choose the date when you paid the taxes",
    DirectorsPayroll_CreateDirector: "New director",
    DirectorsPayroll_Deductions_Totals: "Deductions Totals",
    DirectorsPayroll_Director: "Director",
    DirectorsPayroll_DraftDirectors_Tooltip: "You need to convert these drafts to directors in order to use them in directors payroll",
    DirectorsPayroll_DueToHMRC: "**Due to HMRC** {0}",
    DirectorsPayroll_EmployeeNationalInsurance: "Employee National Insurance",
    DirectorsPayroll_EmployerNationalInsurance: "Employer National Insurance",
    DirectorsPayroll_FinalPayment_Status: "Final payment for the year",
    DirectorsPayroll_FPSsendingInProgressMessage: "Sending FPS to HMRC...\nThis might take a moment. We’ll notify you once it’s been sent.\nYou can leave this page and come back.",
    DirectorsPayroll_FPSSuccessfull: "FPS successfully sent! Here’s your receipt.",
    DirectorsPayroll_HasZeroGrossSalary_Notice: "You cannot submit a payroll with 0 gross salary. If you are not paying any employees in a tax month, you should instead submit an EPS {using another software}.",
    DirectorsPayroll_IncomeTax: "Income Tax",
    DirectorsPayroll_LastDateOfFPS_FinalPayment_SubmissionMessage: "Submit full payment submission (FPS) to HMRC by {0}. This is marked as the final FPS for the year. We will generate P60's to all directors that has been run in any payroll this year.",
    DirectorsPayroll_LastDateOfFPSSubmissionMessage: "Submit full payment submission (FPS) to HMRC by {0}",
    DirectorsPayroll_LateFPSSubmissionInfo: "You’re late with your FPS to HMRC. It was due {0}. When submitting a late FPS, you have to add\na reason, please select reason below:",
    DirectorsPayroll_LateReasonA: "A - Notional payment: Payment to expat by third party or overseas employer",
    DirectorsPayroll_LateReasonB: "B - Notional payment: Employment related security",
    DirectorsPayroll_LateReasonC: "C - Notional payment: Other",
    DirectorsPayroll_LateReasonD: "D - Payment subject to Class 1 NICs but P11D/P9D for tax",
    DirectorsPayroll_LateReasonE: "E - Micro Employer using temporary ‘on or before’ relaxation",
    DirectorsPayroll_LateReasonF: "F - Impractical to report work done on the day",
    DirectorsPayroll_LateReasonG: "G - Reasonable excuse",
    DirectorsPayroll_LateReasonH: "H - Correction to earlier submission",
    DirectorsPayroll_LateSubmissionReason: "Late submission reason",
    DirectorsPayroll_MarkAsFinalPayment_Button: "Mark as final payment for the year",
    DirectorsPayroll_NetPay_Totals: "Net Pay Totals",
    DirectorsPayroll_NoPayslips: "No directors added to this payroll yet.",
    DirectorsPayroll_Page_Title: "Directors payroll",
    DirectorsPayroll_Payroll_MarkAsDone: "Mark as done",
    DirectorsPayroll_Payroll_Unlock: "Unlock",
    DirectorsPayroll_PayrollDetails_Title_Annual: "Annual salary to directors",
    DirectorsPayroll_PayrollDetails_Title_Monthly: "Monthly salary to directors",
    DirectorsPayroll_PayrollInterval_Anually_Label: "Annual directors",
    DirectorsPayroll_PayrollInterval_Label: "Payroll interval",
    DirectorsPayroll_PayrollInterval_Monthly_Label: "Monthly directors",
    DirectorsPayroll_Record_Description: "Once you've paid out the salaries, and the payment date has passed, it's time to record the payment of the salaries.",
    DirectorsPayroll_RecordPayroll_NetWages: "Net wages",
    DirectorsPayroll_RecordPayroll_PaymentDateNotPassed: "You can record the salary payment once the payment date has passed.",
    DirectorsPayroll_RecordPayroll_Title: "Record payroll journal entry",
    DirectorsPayroll_RecordTaxesPaydateInfo: "Pay in everything due to HMRC by {0}.\nRead more on PAYE and how to pay it [here](https://www.gov.uk/pay-paye-tax).",
    DirectorsPayroll_SetPaydate_Label: "Payment date",
    DirectorsPayroll_StudentLoanInformation: "You cannot add an additional student loan as, HMRC only support one student loan being deducted from payroll per employee. You should only enter the loan with the lowest repayment threshold. For further clarification you should contact HMRC",
    DirectorsPayroll_SubmitRTIFPStoHMRC: "Submit RTI FPS to HMRC",
    DirectorsPayroll_SumTotals: "Sum totals",
    DirectorsPayroll_TaxesPaidToHMRC: "{0} Taxes paid to HMRC  {1}",
    DirectorsPayroll_UnavailableDirectors_Tooltip: "You can't use this type of directors for this payroll interval",
    DirectorsPayroll_Updated_LateFPS_SubmissionText: "Your FPS to HMRC was due {0}. When submitting an updated FPS after the due date you have to add a late reason, please select a reason below:",
    DirectorsPayrollAutoTaxPayComplete: "We’ve completed this automatically, since you didn’t have to pay any taxes",
    DirectorsPayrollFeedback_MessageSubject: "Feedback for directors payroll",
    DirectorsPayrollFeedback_Text: "To be able to improve and make the Directors payroll feature even better we would love to get your feedback.",
    DirectorsPayrollFeedback_Title: "Provide feedback on Directors payroll",
    DirectorsPayrolll_Deductions: "Deductions",
    DirectorsPayrollSetup: "Set up Directors payroll",
    DirectorsPayrollSetup_EmptyMessage: "Pay out salaries to Directors. Integrated to HMRC.",
    DiscardVacationDebt: "Semesterskulden försvinner",
    DONE: "Klar",
    Download_P45s_Action: "Download P45's",
    Download_P60s_Action: "Ladda ner filer för P60",
    Download_Payslips_Action: "Ladda ner filer för lönespecifikationer",
    DownloadP45: "",
    DRAFT: "Utkast",
    DraftDescription: "Du måste färdigställa utkast innan de kan användas i löneutbetalningar",
    DRAFTS: "Utkast",
    EDIT: "Redigera",
    EDIT_EMPLOYEE: "Redigera anställd",
    EditAbsence: "Redigera frånvaro",
    EditContract: "Redigera kontrakt",
    EditDirector: "Edit director",
    EditedBy: "Ändrad av",
    EditTaxModal_Deduction_Description: "Exempel på avdrag kan vara FoU-avdrag. Du skriver då in hur mycket avdrag du har fått. Avdragen måste du själv lägga till när du deklarerar till Skatteverket då Bokios AGI-fil inte tar med dessa. Däremot tar bokföringen hänsyn till det. ",
    EditTaxModal_SocialTaxReduction_Label: "Avdrag på sociala avgifterna",
    EditTaxModal_TaxAlternatives_Heading: "Alternativ för skatter",
    EligibleEmployeePrepaidVacation: "Rätt till förskottssemester",
    EMAIL: "Mail",
    EmailPayslips_ErrorMessage: "Det gick inte att skicka lönerna via e-post.",
    EmailPayslips_SendingText: "Vi skickar just nu ut lönespecarna till era anställda. Detta kan ta lite tid.",
    EmailPayslips_SentText: "Alla lönespecar är nu skickade. Har ni några nyanställda som inte tidigare fått email från Bokio kan det vara bra att meddela dessa.",
    EMAILS_DELIVERED: "Lönespecar skickade",
    Employee: "Anställd",
    Employee_Archive: "Arkivera anställd",
    Employee_ArchiveConfirmation: "Vill du verkligen arkivera {0}?",
    Employee_Archived_List: "Arkiverade anställda",
    Employee_ArchiveEmployeeTitle: "Arkivera en anställd",
    Employee_Reactivate: "Återaktivera anställd",
    Employee_RecordTransactions_Notice: "Du har semesterbalanstransaktioner att bokföra.",
    EMPLOYEE_USER_DISASSOCIATE: "Koppla bort från användare",
    EMPLOYEE_USER_INVITATION_PENDING: "Väntar på att inbjudan ska bli accepterad",
    EMPLOYEE_USER_PERMISSIONS: "Redigera rättigheter",
    Employee_WithUserArchiveConfirmation: "Är du säker på att du vill arkivera {0}?{1}Den anställde är också kopplad till en användare i Bokio och kommer fortfarande kunna logga in. Om du vill ta bort möjligheten för {0} att logga in på ditt företag, måste du också ta bort användaren under Inställningar.",
    EmployeeActivate: "Gå till aktivering",
    EmployeeAdded: "Anställd sparad!",
    EmployeeAddedDescription: "Härligt! Aktivera {0} för att lägga till dem i löneutbetalningar.",
    EmployeeChargeOnboard_ActivatedEmployees: "Aktiverat anställda",
    EmployeeChargeOnboard_Billing: "En avgift läggs till på nästa faktura. Du kan se din fakturering under {inställningar och fakturering}",
    EmployeeChargeOnboard_YouActivatedEmployees: "Du har aktiverat anställda för lön!",
    EmployeeDetails: "Personalinformation",
    EmployeeDetailsDescription: "När du har fyllt i för- och efternamn kan du spara den anställda som utkast.",
    EmployeeDetailsTitle: "Har du inte alla detaljerna?",
    EmployeeForm_CreatingEmployeeForUser: "Denna anställda kommer kopplas till användaren {0} med e-posten {1}",
    EmployeeImport_ShowEmployees: "Visa anställda",
    EmployeeImport_SuccessHeading: "{ok} av {total} anställda har importerats",
    EmployeeList_ActiveForPayroll_Header: "Aktiverad för lön",
    EmployeeNeedsToBeOnboardedToVacationTracking: "Du måste slå på automatiska semesterbalanser för den anställda, så att semesterskulden hålls uppdaterad och bokförs korrekt. Se till att gör detta snarast, då balanserna förändras över tid.",
    EmployeeNumber: "Employee number",
    EmployeeNumberTooltipMsg: "HMRC requires a unique number to be assigned to each employee. If this is an existing employee, ensure that you use the same employee number that was used in any previous software. Use a different number if you re-employ someone in the same tax year as they left, or if an employee has more than one job in the same PAYE scheme.",
    EMPLOYEES: "Anställda",
    EmployeeSaveAsDraft_Validation_Footer: "Notera: Efter att du åtgärdat detta kan du spara den anställda som ett utkast om du inte har all information just nu.",
    EmployeeSaveAsDraft_Validation_Title: "När du åtgärdat följande problem kan du fortsätta",
    EmployeesVacation_DraftDescription: "Du måste färdigställa utkast innan de kan läggas till för automatiska semesterbalanser",
    EmployeeUser_ConfirmRevokeUser: "Vill du ta bort kopplingen till denna användare?",
    EmployerDetails: "Employer details",
    EmployerInformation: "Employer information",
    EmployerPAYEReference: "Employer PAYE reference",
    EmployerSetup: "Employer setup",
    EMPLOYMENT_NUMBER: "Anställnings nr",
    EMPLOYMENT_TYPE: "Anställningstyp",
    EmploymentDetails: "Employment details",
    EmploymentLastDateLabel: "Last day of employment",
    EmploymentStartDate: "Employment start date",
    EndDate: "Slutdatum",
    Error_GroundsChangedBeforeSave: "Underlaget har ändrats sedan du sparade detta. Spara igen för att bli av med varningen.",
    Error_SetOurReferenceFirst: "Innan du kan publicera dina löner behöver du ställa in en kontaktperson. Det gör du {här}.",
    ErrorInYourPayroll: "Problem i lönekörning",
    EstimatedPaidAccrual_HelpText: "Från {0} to {1} har denna anställda tjänat in {2} dagar. Detta inkluderar inte eventuell frånvaro som ej är semesterberättigad.",
    ExportSieFile: "Exportera SIE fil",
    ExtraVacationDaysUse_Warning: "Det här leder till att den anställde kommer få negativa balanser i {0}. Vi rekommenderar att du antingen delar upp dessa dagar i obetald semester eller redigerar balanserna manuellt senare. ",
    Failed: "Misslyckades",
    Female: "Female",
    FillIn: "Fyll i",
    FinalizeDraftToEditVacationbalances: "Du måste färdigställa utkast innan du kan ändra semesterbalanserna",
    FinalPaymentTooltipHeading: "Final payment",
    FinalPaymentTooltipMessage: "Director's last working day: {0}",
    FIRSTNAME: "Förnamn",
    Fix: "Rätta",
    ForHourlyOrComissionEmployeePayoutCompensation: "För timanställda, och anställda med provisionslön betalar man ut semesterersättning till. Om denna anställd har innestående ersättning (som du inte betalat ut ännu), fyll i denna nedan.",
    FPSResubmitWarning: "Are you sure you want to resubmit the FPS? You should only do this if you have made some changes that need to be reflected on this submission.",
    FULLTIME: "Heltidsanställd",
    FULLTIME_EMPLOYEE: "Månadslön",
    FulltimePer: "Heltid (100%)",
    FutureContracts: "Kommande kontrakt",
    Gender: "Kön",
    GenerateReportPlaceholder_OpenFilters: "Öppna filter",
    GetStarted: "Starta",
    GetStartedByAddingFirstDirector: "Get started with Directors payroll by adding your first director to Bokio.",
    GiveUsFeedback: "Ge oss feedback",
    GoTo_Billing: "Gå till fakturering",
    GoToEmployees: "Gå till anställda",
    GoToPayroll: "Gå till lönekörning",
    GoToSalary: "Gå till löner",
    GoToTaxPeriod: "Gå till skatteperiod",
    GoToThisEmployeesContract: "Gå till den anställdas kontrakt",
    GoToVacationPage: "Gå till semestersidan",
    GROSS: "Brutto",
    GROSS_INCOME: "Bruttolön",
    GROSS_PAY: "Bruttolön",
    HaveBalancesAndNeedToSetToZero: "Du har balanser i dagar för denna anställd. Innan du slår på automatiska semesterbalanser måste du konvertera dessa dagliga värden till semesterersättning. Alla fält utom \"Semesterersättning\" ska vara satta till 0 innan du kan gå vidare.",
    Here: "här.",
    Hmrc_Employee_HoursWorked_16HoursTo24: "",
    Hmrc_Employee_HoursWorked_24HoursTo30: "",
    Hmrc_Employee_HoursWorked_30hoursOrMore: "",
    Hmrc_Employee_HoursWorked_FieldName: "",
    Hmrc_Employee_HoursWorked_LabelText: "",
    Hmrc_Employee_HoursWorked_LessThan16: "",
    Hmrc_Employee_HoursWorked_Other: "",
    HmrcPassword: "HMRC password",
    HmrcUserId: "HMRC User ID",
    HOUR: "Timma",
    HOURLY_EMPLOYEE: "Timlön",
    HOURLY_EMPLOYEES: "Timanställda",
    HourlyCommissionEmployeeVacationFieldInfo: "För timanställda och anställda som jobbar mot kommissionslöner betalar man ut semesterersättning i samband med löneutbetalning. Om de har innestående semesterersättning (som ännu inte är utbetalat), fyller du i det nedan.",
    HourlySalary: "Timlön",
    HourlyVacationCompensationRate: "Semesterersättning",
    HOURS: "Timmar",
    HowBokioCounts: "Hur Bokio räknar",
    HowOftenDoYouPayDirector: "How often do you pay this director?",
    Invalid: "Ogiltigt",
    IsThisUkAddress: "Is this a UK address?",
    JobTitle: "Jobbtitel",
    KarensDeduction: "Karensavdrag",
    KilometerCompensation: "Ersättning / km",
    Kilometers: "Kilometer",
    LastDateOfEmployment_CantBeMoreThan30DaysInFuture: "Last date of employment can't be set to a date more than 30 days in the future from today.",
    LASTNAME: "Efternamn",
    Left: "kvar",
    LimitOverride_LastUntil: "tills {0}",
    LoanType: "Loan type",
    Locked: "Låst",
    LoweredSocialFees: "Sänkta sociala avgifter",
    LoweredSocialFeesDueToCovid: "På grund av Covid-19 har regeringen sänkt de sociala avgifterna för upp till 30 anställda på löner upp till 25 000 kr. Detta gäller för löner från mars till juni. Vi sänker de sociala avgifterna på alla anställda som standard. Om du har mer än 30 anställda så kan du själv välja vilka 30 du vill applicera sänkningen på. Läs mer på",
    Male: "Male",
    ManageActiveEmployees_ApplyChanges_Button: "Spara ändringar",
    ManageActiveEmployees_BillingDetails: "Faktureringsuppgifter",
    ManageActiveEmployees_BillingDetails_IntroText: "Detta är en översikt över vad du kommer att faktureras för om antalet aktiva anställda inte ändras.",
    ManageActiveEmployees_Blocked_UsedInPayrolls: "Denna anställd används i en aktuell eller kommande lönekörning och kan inte avaktiveras förrän dessa passerat eller tagits bort.",
    ManageActiveEmployees_ConfirmExtraCosts: "Jag förstår att jag kommer att faktureras för anställda utöver de som ingår i prisplanen",
    ManageActiveEmployees_DisplayingXOutOfYEmployees: "Visar {1} av {2} anställda.",
    ManageActiveEmployees_DraftInfo: "Anställda listade under utkast saknar viss information. De kommer inte kunna aktiveras förrän detta är åtgärdat. Redigera dessa under respektive anställd i listan över anställda.",
    ManageActiveEmployees_Header: "Hantera aktiva anställda",
    ManageActiveEmployees_IntroText: "Här kan du ändra vilka anställda som kan användas i lönekörningar. En anställd behöver vara aktiv för att användas i en lönekörning.",
    ManageActiveEmployees_NoSalary: "Här kan du ändra vilka anställda som kan användas i lönekörningar. Din nuvarande prisplan är **{0}**, du behöver Balans eller Business för att kunna aktivera anställda.",
    ManageActiveEmployees_PageTitle: "Aktivera anställda",
    ManageActiveEmployees_ReachedLimitNotice_LinkText: "Läs mer under prisplaner",
    ManageActiveEmployees_ReachedLimitNotice_Text: "Varje aktiv anställd utöver dessa kostar **{1} kr/månad**.",
    ManageActiveEmployees_ReachedLimitNotice_Title: "Du har använt dina {1} gratis aktiva anställda",
    ManageActiveEmployees_ReadMoreOnHowItWorks: "Läs mer om hur det fungerar",
    ManualBankTransfer_Info: "Ladda ned Bankgiro-filen och ladda upp den till din internetbank. Bokför lönen när pengarna har dragits. ",
    MarkAsFinalPayment: "Mark as final payment",
    MarkAsRecorded: "Markera som bokförd",
    MarkManuallyRecordedConfirmationText: "Genom att markera denna betalning som manuellt bokförd betyder det att du har bokfört denna betalning via den manuella bokföringsfunktionen. Om du har aktiverat automatiska semesterbalanser behöver du även bokföra ändringar i semesterskulden manuellt. Är du säker på att du vill markera denna som manuellt bokförd?",
    milageCompensationOverLimit_Warning: "Ersättningen per kilometer som du lagt in är över gränsen för vad som är skattefritt. Den överskjutande delen kommer förmånsbeskattas.",
    Missing_Info: "Saknad information",
    MissingInfoNote: "Du kan fortfarande spara den anställda som utkast om du inte har all information just nu.",
    MissingInformationFields: "Efter att du har fyllt i dessa fält kan du använda den anställda i Lön",
    MissingSettingsToFinishSalary: "Det saknas några inställningar, fyll i följande värden för att klarmarkera lönerna.",
    MODEL: "Modell",
    MON_APR_SHORT: "Apr",
    MON_AUG_SHORT: "Aug",
    MON_DEC_SHORT: "Dec",
    MON_FEB_SHORT: "Feb",
    MON_JAN_SHORT: "Jan",
    MON_JUL_SHORT: "Jul",
    MON_JUN_SHORT: "Jun",
    MON_MAR_SHORT: "Mar",
    MON_MAY_SHORT: "Maj",
    MON_NOV_SHORT: "Nov",
    MON_OCT_SHORT: "Okt",
    MON_SEP_SHORT: "Sep",
    MONTH: "Månad",
    Monthly: "Monthly",
    MonthlyVacationRate: "Semesterlön",
    MONTHS: "Månader",
    MoveOverBalances: "Flytta över balanser",
    MUST_ADD_PAYSLIPS_BEFORE_BOOKKEEP: "Du måste lägga till minst en lön innan du bokför den här månaden",
    MustAddPayslipBeforeAddPayment: "Du behöver lägga till minst en lön för att kunna lägga till betalning",
    MyPayslips: "Mina löner",
    MyPayslips_noPayslips_message: "Du har inte fått några lönespecifikationer än. När du får din första lönespecifikation kan du hitta den här.",
    MyPayslips_NoUserText: "Du har ingen anställd kopplad till din inloggning i detta bolaget. Administratören kan koppla ihop din användare med en anställd.",
    NAME: "Namn",
    NationalInsuranceCategory: "National Insurance category",
    NationalInsuranceCategoryLetter: "NI category letter",
    NationalInsuranceNumber: "National Insurance number",
    NET: "Netto",
    NET_INCOME: "Nettolön",
    NET_PAY: "Nettolön",
    NetAdjustmentSettings_Heading: "Mappa nettolöneavdrag",
    NetAdjustmentSettings_MapAccount_Label: "Bokför på konto:",
    NEW_EMPLOYEE: "Ny anställd",
    NewContract: "Nytt kontrakt",
    NewHire: "Nyanställd",
    NextStep: "Nästa steg",
    NiCalculationMethodsTooltip: "",
    NiCategoryLetterAOnlySupported: "We currently only support NI category letter A. Users with other category letters will not be able to use this service.",
    NicDirectorsAnnualMethod: "",
    NicNationalInsurancePaymentMethod: "",
    NicStandardMonthlyMethod: "",
    NO_ARCHIVED_EMPLOYEES: "Du har för närvarande inga arkiverade anställda",
    NO_EMPLOYEES: "Du har inga anställda än",
    NO_EMPLOYEES_ACTION: "Vill du skapa din första anställd?",
    NO_EMPLOYEES_NOTICE_One: "Oops, det ser ut som att du inte har några anställda än. Lägg till din första anställda för",
    NO_EMPLOYEES_NOTICE_Two: "att starta med lönehantering, arbetsgivaravgifter, frånvaro och förmåner!",
    NoActivePayrolls: "Inga aktiva lönekörningar",
    NoEmployeesFound: "Hittade inga anställda",
    NoJobTitle: "Utan titel",
    NoP45Available: "",
    NoP60sAvailable: "",
    NoPayslipMessage: "Denna anställd saknar lönespecifikationer. När de får sin första lönespecifikation så kan du hitta den här.",
    NoStudentLoansAdded: "No student loans added",
    NotApplicable: "Ej applicerbar",
    NoTaxRulesAvailable: "Skatteregler saknas",
    NoTaxRulesAvailableDescription: "Skatteverket har ännu inte släppt alla regler för {0}. Vi har räknat på regler från {1}. Du kommer kunna klarmarkera lönerna så fort vi fått Skatteverkets senaste regler.",
    NOTE: "Kommentar",
    NOTHING_TO_BOOKKEEP: "Det finns inget att bokföra.",
    NotOnboardedToVacationTracking: "Har inte automatiska semesterbalanser. Ändra för att aktivera",
    NumberOfSavedDaysEmployeeHas: "Denna anställd har {0} sparade dagar.",
    OffboardingModalText: "This marks this payment to this director as a final payment. You do this if a director leaves, and you don’t\n   intend to pay this director in the future. This means that we’ll be reporting this to HMRC, and we’ll also create\n   a P45, which will be found under this directors payslips.",
    OnBoardEmployees: "Slå på automatiska balanser för anställda",
    OnboardEmployeesToVacationTracking: "Slå på automatiska semesterbalanser för anställda",
    OnboardEmployeesToVacationTrackingDescription: "Gå igenom dina anställda och ange nuvarande semesterbalans. Avsluta med att slå på automatisk semesterbalans för den anställda. Det är viktigt att detta blir korrekt, så Bokio kan räkna ut framtida balanser. När du slagit på funktionen för dina anställda, kommer Bokio räkna ut intjänade samt använda dagar.",
    OnBoardingEmployeeDescription: "Nu är det dags att se över och sätta balanser för dina anställda. När allt ser korrekt ut, slår du på automatiska semesterbalanser för varje anställd.",
    Overridden: "Egen uträkning",
    Overrides: "Egna uträkningar",
    OverridesAffectedDescription: "Egna uträkningar som påverkas av de nya ändringarna:",
    OverridesAvailibiltyInfo: "Egna uträkningar blir tillgängligt så fort du har fyllt i det fösta steget",
    OverridesAvailibiltyInfo_Commision: "Egna uträkningar är endast tillgängligt för anställda med en fast lön.",
    OverridesDisabledInfo: "Värdena är låsta för att du använder detta kontrakt i minst en lönespecifikation. Om du vill använda egna uträkningar måste du skapa ett nytt kontrakt.",
    Owner: "Ägare",
    P45: "",
    P60s: "",
    Pain_AccountNumber: "Kontonummer",
    Pain_Salaries: "Löneutbetalning med Handelsbanken",
    Pain_XCharacters: "{0} siffror",
    PartTime: "Deltid",
    PastContracts: "Tidigare kontrakt",
    PayDate: "Utbetalas",
    PaydateIsTooEarlyInTheMonth: "När lönedagen är för tidigt inpå månaden kan betalningen och bokföringen skapas i den föregående månaden.",
    PaydateNeedsToBeInOpenFisaclYear: "Betalningsdatum behöver vara i ett öppet räkenskapsår",
    PayeReferenceTooltip: "You will find this on the letter that you received from HMRC when you first registered as an employer. It is in the format 123/A456.",
    Payment_AddPayment_Button: "Lägg till betalning",
    Payment_CanNotSignImminent_Warning: "Betalningsdatumet är nära! Skicka in för godkännande och se till att Admin med signeringsrättigheter signerar så snart som möjligt för att betala ut lönekörningen.",
    Payment_CanNotSignOverdue_Warning: "Betalningsdatumet har passerat! Skicka in för godkännande och se till att Admin med signeringsrättigheter signerar så snart som möjligt för att betala ut lönekörningen.",
    Payment_CanSignImminent_Warning: "Betalningsdatumet är nära! Schemalägg och signera betalningen så snart som möjligt för att betala ut lönekörningen.",
    Payment_CanSignOverdue_Warning: "Betalningsdatumet har passerat! Schemalägg och signera betalningen så snart som möjligt för att betala ut lönekörningen",
    PaymentInterval: "Payment interval",
    PaymentStatus_AwaitingApproval: "Inväntar godkännande",
    PaymentStatus_ReadyToSign: "Redo att signeras",
    Payroll_BlockedByInitiatedPayment: "Du kan inte låsa upp eller lägga till en betalning om en betalning redan har påbörjats",
    Payroll_EmptyClearingNumber_Warning_Text: "Detta kommer fungera, men vi rekommenderar starkt att du flyttar clearingnumret till det avsedda fältet.",
    Payroll_EmptyClearingNumber_Warning_Title: "Clearingnummerfältet är tomt",
    Payroll_InvalidBankDetails_Error_Text: "För att kunna använda den anställda i denna lönekörning så krävs giltiga svenska bankuppgifter. Alternativt kan du byta betalningsmetod till Kontantbetalning.",
    Payroll_InvalidBankDetails_Error_Title: "Ogiltiga bankuppgifter",
    Payroll_NewPayroll_Button: "Ny lönekörning",
    Payroll_open: "Öppen",
    Payroll_Overdue: "Försenad",
    Payroll_Paid: "Betalad",
    Payroll_PayrollHistory: "Lönekörningshistorik",
    Payroll_RecordPayments: "Bokför betalningar",
    Payroll_SalaryPaymentMethod_Cash: "Kontantbetalning",
    Payroll_SalaryPaymentMethod_ManualBankTransfer: "Manuell banktransaktion",
    Payroll_ShowInPayments_Button: "Visa under betalningar",
    Payroll_SignInPayments_Button: "Signera under betalningar",
    Payroll_Sorting_Newest: "Visa nyaste först",
    Payroll_Sorting_Oldest: "Visa äldst först",
    Payroll_TaxesAndPayments_FillInEmployerDeclaration: "Gå till Skatteverket och fyll i Arbetsgivardeklaration.",
    Payroll_TaxesAndPayments_NetAdjustment_BlockedUntilMapped: "Du kan inte bokföra innan alla nettolönejusteringar är mappade.",
    Payroll_TaxesAndPayments_NetAdjustment_Mapping_Action: "Ändra mappning",
    Payroll_Title: "Lönekörning",
    Payroll_UndoBookkeeping_Action: "Ångra bokföring",
    PayrollAddPayslipsModal_NoEmployeesYet: "Du har inte några anställda ännu",
    PayrollHistoryUK_NetTotal: "Net total",
    PayrollHistoryUK_NoOfPayslips: "No. payslips",
    PayrollHistoryUK_PayrollStatus: "Payroll status",
    PayrollHistoryUK_TaxesTotal: "Taxes total",
    PayrollHistoryUK_XInterval: "{0} interval",
    PayrollInterval: "Lönekörningsfrekvens",
    PayrollListUK_AddDirectors_Action: "Add directors to pay run",
    PayrollListUK_DeductionTotals: "Deduction totals",
    PayrollListUK_EmployerNITotals: "Employers national insurance",
    PayrollListUK_FPSFailed_Action: "Submit FPS to HMRC",
    PayrollListUK_GrossPayTotals: "Gross pay totals",
    PayrollListUK_MarkAsReady_Action: "Mark pay run as ready",
    PayrollListUK_NetPayTotals: "Net pay totals",
    PayrollListUK_NoOfDirectors: "No. of directors",
    PayrollListUK_PaymentDate: "Payment date",
    PayrollListUK_RecordPayrun_Action: "Record pay run",
    PayrollListUK_RecordTaxes_Action: "Record payment of taxes",
    PayrollListUK_SendingFPS_Action: "Sending FPS...",
    PayrollListUK_Status: "Status",
    PayrollListUK_Status_Draft: "Draft",
    PayrollListUK_Status_FPSFailed: "FPS failed",
    PayrollListUK_Status_FpsTimeout: "FPS timeout",
    PayrollListUK_Status_Recorded: "Recorded",
    PayrollListUK_Status_RecordTaxes: "Record taxes",
    PayrollListUK_Status_ReportToHMRC: "Report to HMRC",
    PayrollListUK_XIntervalPayRunForY: "{0} interval pay run for {1}",
    Payrolls_ActivePayrolls: "Aktiva lönekörningar",
    PAYSLIP: "Lönespecifikation",
    Payslip_Logo_Customise_Text: "Anpassa layout",
    Payslip_Logo_Empty_Text: "Ingen logga",
    PAYSLIP_PDF_BACKUP: "BACKUP",
    PayslipList_AddPayslips: "Lägg till lönespec",
    PayslipRow_SetCustomCostPerUnit_Label: "Sätt egen kostnad per",
    PayslipRow_UnsupportedRowType: "Felaktig rad - klicka här för att ta bort den!",
    PayslipRow_WeBelieveCostIs_Pt1: "Bokio har räknat fram kostnaden till",
    PayslipRow_WillEarnVacationDays: "Semestergrundande",
    PayslipRow_Workload_Tooltip: "Arbetsbelastningen för den anställda när semestern tjänades in. Arbetsbelastningen används för att räkna ut semesterlönen. Notera att det kan resultera i negativ semesterlön om arbetsbelastningen är högre nu än när den tjänades in. {Läs mer i hjälpartikeln}.",
    PAYSLIPS: "Lönespecar",
    Payslips_Tooltip: "För att visa och redigera lönespecar, lägg till en lönespec och klicka på den anställdas namn",
    PayslipsMustHaveBookkeepableRow: "Det måste finnas åtminstone en lönespec med rader som ska bokföras för att fortsätta",
    PdfFileNamePrefix: "Lönespecifikation",
    PensureAdBodyEmployees: "Kika på Bokios erbjudande för tjänstepension till dina anställda, enkelt, prisvärd och digitalt!",
    PensureAdBodySoloWithoutPension: "Det ser inte ut som om du har någon tjänstepension som betalas av ditt bolag idag. Ta del av Bokios erbjudande för tjänstepension via Pensure!",
    PensureAdBodySoloWithPension: "Det ser ut som om att du har tjänstepension via ditt bolag idag. Det är bra, men vet du om att du kan få en högre pension med lägre försäkringsavgifter? Utmana och jämför din tjänstepension! Ta del av Bokios erbjudande här.",
    PensureAdButtonText: "Gå till erbjudandet",
    PensureAdHeadingEmployees: "Tjänstepension via Pensure",
    PERMANENT_EMPLOYEES: "Tillsvidareanställda",
    PERSONAL_NUMBER: "Personnummer",
    PHONE_NUMBER: "Telefonnummer",
    PlaceOfEmploymentInfo: "Huvudregeln är att en anställd har sitt tjänsteställe där huvuddelen av arbetet utförs. Till denna huvudregel finns ett antal undantag. Det är arbetsgivaren som ansvarar för att göra en bedömning av var den anställda har sitt tjänsteställe.",
    PlaceOfEmploymentInfoLink: "Läs mer om tjänsteställe på skatteverket.se",
    PlaceOfEmploymentInfoSummary: "En anställd kan bara ha ett tjänsteställe hos en och samma arbetsgivare.",
    Plan: "Plan",
    PostGraduate: "Postgraduate",
    PrepaidDaysLeft: "Kvarvarande förskottssemesterdagar",
    PrepaidDaysUsed: "Använda förskottssemesterdagar",
    PrepaidVacation: "Förskottssemester",
    PrepaidVacationDaysLeft: "Kvarvarande förskottssemesterdagar",
    PrepaidVacationDaysUsed: "Använda förskottssemesterdagar",
    PreviousEmployeeNICPaid: "Previous Employee NIC paid year to date",
    PreviousEmployerNICPaid: "Previous Employer NIC paid year to date",
    PreviousOutdatedReceipts: "Previous, outdated receipt(s):",
    PreviousTaxablePay: "Previous taxable pay year to date",
    PreviousTaxablePayTooltip: "Enter the total amount earned by this employee through payroll since the beginning of the tax year. If it is an existing employee you can take the information from any previous software, or if it is a new employee the details can be found on their P45. Do not enter income earned in prior years.",
    PreviousTaxPaid: "Previous tax paid year to date",
    PreviousTaxPaidTooltip: "Enter the total amount paid in income tax by this employee through payroll since the beginning of the tax year. If it is an existing employee you can take the information from any previous software, or if it is a new employee the details can be found on their P45. Do not enter tax paid in prior years.",
    PricePlans_ActiveEmployees_Title: "Aktiva anställda",
    PricePlans_ManageEmployeeActivation: "Hantera aktivering",
    PricePlans_Salary_Info: "Du behöver aktivera anställda för att kunna betala ut löner. Läs mer om prisplaner {0}.",
    ProprietorshipOwnerSalaryWarning: "Om du är ägare i en enskild firma räknas du rent juridiskt inte som en anställd. För att ta ut lön ska du göra ett eget uttag.",
    ReadMore: "Läs mer",
    ReadMoreHere: "Läs mer här",
    Reason: "Anledning",
    RecordTransactions: "Bokför transaktioner",
    RecordVacationTransactions: "Bokför semestertransaktioner",
    REGION: "Region",
    Renews: "Till:",
    ReplacedByOtherContract: "Ersatt av annat kontrakt",
    Reports_AllEmployees: "Alla anställda",
    ResetToDefaultValueDescription: "Genom att spara dessa nya ändringar återställer vi dina egna uträkningar till Bokios uträkningar.",
    RestoreTo: "Återställ till",
    ResubmitFPSToHMRC: "Resubmit RTI FPS to HMRC",
    RowLabel_HolidayPay: "Semestertillägg",
    RowLabel_OddHours: "OB-tillägg",
    RowLabel_Overtime: "Övertid",
    RowLabel_Salary: "Lön",
    RowLabel_VacationReplacement: "Semesterersättning",
    SALARY_COST: "Lönekostnad",
    Salary_FirstSalaryRating_Description: "Har du några tankar som kan hjälpa oss att förbättra din upplevelse av löneflödet?",
    Salary_FirstSalaryRating_Title: "Hur upplevde du löneflödet?",
    SALARY_FOR: "Lön för",
    Salary_NameUndefined: "Namnlös",
    Salary_PaymentDateMustBeInMonth: "Betalningsdatumet måste vara inom den valda månaden",
    SALARY_PERIOD: "Löneperiod",
    SalaryPayrollDetails_Feedback_Title: "Har du någon feedback på lönekörningar?",
    SalaryPayslipDetails_Feedback_Title: "Har du någon feedback på utformandet av lönespecifikationer?",
    SalaryReport_EditFilterAndTryAgain: "Ändra filter och försök generera rapporten igen",
    SalaryReport_HourlyPaid_Description: "Få insikt i hur mycket du betalar timanställda under en tidsperiod",
    SalaryReport_HourlyPaid_Placeholder: "Denna rapport analyserar alla rader på lönespecifikationer för timanställda för alla anställda du har valt.",
    SalaryReport_HourlyPaid_Title: "Timanställda",
    SalaryReport_NoRowsFound: "Oops, hittade inga rader",
    SalaryReport_Placeholder_GetStarted: "Kom igång genom att välja ett intervall du vill generera rapporten för, eller lämna tom för att generera för all tid.",
    SalaryReport_PlaceholderTitle: "Generera {titel}rapport",
    SalaryReport_ReportDateRange: "Spannet för rapporten är från {0} till {1}.",
    SalaryReport_SalaryTotal_Description: "Se hur mycket skatt, lön eller båda kombinerat du har betalat ut under en tidsperiod.",
    SalaryReport_SalaryTotal_Placeholder: "Denna rapport analyserar alla rader på lönespecifikationer med lön för alla anställda du har valt.",
    SalaryReport_SalaryTotal_Title: "Lönekostnader",
    SalaryReport_SickDays_Description: "Se hur mycket sjukfrånvaro dina anställda har haft under en tidsperiod.",
    SalaryReport_SickDays_Placeholder: "Denna rapport analyserar alla rader på lönespecifikationer med sjukfrånvaro för varje anställd du har valt.",
    SalaryReport_SickDays_Title: "Sjukfrånvaro",
    SalaryReport_SubTitle: "{0}rapport",
    SalaryReport_VacationDebt_Description: "Beräkna nuvarande semesterskuld",
    SalaryReport_VacationDebt_Title: "Semesterskuld",
    SalaryReport_VacationSummary_Description: "Se hur många semesterdagar dina anställda har tagit ut under en tidsperiod",
    SalaryReport_VacationSummary_Placeholder: "Denna rapport analyserar alla rader på lönespecifikationer med semester för varje anställd du har valt.",
    SalaryReport_VacationSummary_Title: "Semester",
    SalaryReports: "Lönerapporter",
    SalaryReports_Description: "VI har skapat några rapportmallar för att ge dig en överblick av ditt företag.",
    SalaryReports_GenerateReport: "Generera rapport",
    SalaryReports_PayslipDate: "Lönespec. datum",
    SalaryReports_PayslipLink: "Länk till lönespec",
    SalaryReports_PickOneToGetStarted: "Välj en och kör!",
    SalaryReports_RunReport: "Kör rapport",
    SalarySettings: "Lön",
    SalaryTaxPeriod_FeedbackTitle: "Har du någon feedback på det nya löneflödet?",
    SAVE: "Spara",
    SaveAbsence: "Spara frånvaro",
    SaveChanges: "Spara ändringar",
    SavedDays: "Sparade dagar",
    SavedDaysDescription: "Här kan du lägga till sparade dagar per år de tillkom. Om du använder en sparad dag på en lön så kommer Bokio använda den äldsta sparade dagen.",
    SavedDaysFromPreviousPeriod: "Sparade dagar från tidigare perioder",
    SaveDraft: "Spara utkast",
    SaveEmployee: "Spara anställd",
    SearchEmployee: "Sök anställd",
    SelectDate: "Välj datum",
    SelectEmployee: "Välj anställd",
    SelectEmployeesWithHighestSalaries: "Välj anställda med högst löner",
    SelectOneOfFollowingStatements: "Select one of the following statements",
    SelectXOrFewerEmployees: "Välj {0} eller färre anställda med sänkta sociala avgifter",
    Send_DownloadPdf_Action: "Ladda ner filer",
    Send_EmailLinks_Action: "Maila länkar",
    Send_EmailLinks_Description: "Du kommer att skicka länkar till de anställda för att ladda ner sina lönespecifikationer. Detta kräver att alla har ett Bokio-konto.",
    Send_EmailLinks_MissingUsers: "Anställda som inte har en inloggning",
    Send_EmailPdf_Action: "Maila filer",
    SEND_PAYSLIP_EMAIL: "Du är på väg att maila lönespecifikationerna som PDFer, det är inte rekommenderat att skicka personuppgifter via e-post. Vill du fortfarande göra det?",
    SetPeriod: "Sätt period",
    SetSalaryPayDay: "Välj lönedag",
    Settings_Archive: "Arkivera",
    Settings_ArchivePlaceOfEmployment: "Arkivera tjänsteställe",
    Settings_ConfirmArchivePlaceOfEmployment: "Vill du arkivera tjänsteställe: {0}, {1}?",
    Settings_ManagePlaceOfEmployment: "Hantera tjänsteställen",
    Settings_WhenRemovingPlaceOfEmployment: "Om du arkiverar ett tjänsteställe kommer du inte längre kunna välja det för anställningskontrakt. Tjänstestället kommer att finnas kvar på de kontrakt som redan har det valt.",
    ShouldNotBeEmpty: "måste anges",
    SICK_LEAVE: "Sjukfrånvaro",
    SickSalaryRate: "Sjuklön",
    SOCIAL_COSTS: "sociala avgifter",
    SOCIAL_TAX_TOTAL: "Total social skatt",
    StartDate: "Startdatum",
    StarterType: "Starter type",
    StartingDate: "Startdatum",
    Starts: "Från:",
    Step: "Steg",
    StudentLoan: "Student loan",
    StudentLoans: "Student loans",
    SubmissionFailed: "Inlämning misslyckades",
    SUM: "Summa",
    SURE_OF_DELETE_PAYROLL: "Lönekörningen raderas permanent. Är du säker på att du vill fortsätta?",
    SURE_OF_DELETE_PAYSLIP: "Lönespecifikationen raderas permanent. Är du säker på att du vill fortsätta?",
    SYS_ERROR_INVALID_SALARY_PERIOD: "Ogiltig löneperiod",
    SYS_LOADING: "Laddar",
    Table: "Tabell",
    TabTax_NumberOfPayslips: "Antal lönespecar",
    TAX: "Skatt",
    TAX_CALCULATED_ON: "Skatt beräknad på",
    TAX_COLUMN: "Skattekolumn",
    TAX_PERCENTAGE: "Skatt (%)",
    TAX_TABLE: "Skattetabell",
    TaxCode: "Tax code",
    TaxCodeTooltip: "A tax code usually contains several numbers and a letter, for example: 1006L. However, there are some two character tax codes such as BR or 0T, which may be used. You should use the tax code from any previous software if it is an existing employee or, if it is a new employee with a P45, use the tax code from the P45. If they are a new employee without a P45 use the tax code 1257L. If the employee is based in Scotland or Wales, add S or C respectively as prefix for tax purposes.",
    TAXES: "Skatt",
    TaxesDue: "Skatt senast",
    TaxPeriod_AGITitle: "Skicka AGI till Skatteverket",
    TaxPeriod_BBA_SumsNotMatching_Text: "Detta kan vara OK om du har betalat skatterna på ett annat sätt. I så fall kan du klarmarkera steget.",
    TaxPeriod_BBA_SumsNotMatching_Title: "De schemalagda betalning(arna) ({0}) matchar inte de förväntade skatterna ({1})",
    TaxPeriod_CantLockPeriodInfo: "För att kunna låsa månaden behöver du se till att alla lönekörningar är låsta och betalda",
    TaxPeriod_CompleteWithoutAnySalaries: "Klarmarkera månaden utan några löner",
    TaxPeriod_Deadline: "Deadline:",
    TaxPeriod_DeclarationInfo: "Som registrerad arbetsgivare måste du deklarera löner till Skatteverket varje månad. Även om du inte skulle ha några löner den månaden.",
    TaxPeriod_DeclareHere: "Öppna deklaration (Skatteverket)",
    TaxPeriod_DonwloadAGI: "Ladda ner AGI",
    TaxPeriod_HowToRecordPayments: "Hur du bokför skattebetalningen",
    TaxPeriod_LockPeriod: "Klarmarkera månaden",
    TaxPeriod_MarkDeclarationAsDone: "Markera som deklarerad till Skatteverket",
    TaxPeriod_MarkDeclarationAsNotDone: "Ångra markera som deklarerad",
    TaxPeriod_MarkPaymentsAsDone: "Markera skattebetalning som gjord",
    TaxPeriod_MarkPaymentsAsNotDone: "Ångra markera som betald",
    TaxPeriod_MissingNetAdjustmentsWarning: "Det finns nettolönejusteringar som måste mappas innan skatterna kan bokföras. Öppna lönekörningarna för perioden och fixa det där.",
    TaxPeriod_MustCompletePreviousStep: "Föregående steg måste göras klart först",
    TaxPeriod_PeriodTitle: "Löner och skatter {0}",
    TaxPeriod_RecordTaxesInfo: "När dragningen/återbäringen av skatter syns på ditt skattekonto kan du bokföra dessa här. Du kan inte bokföra det tidigare för du kan inte bokföra i framtiden.",
    TaxPeriod_Step1Title: "Gör klart alla lönekörningar",
    TaxPeriod_Step2Title: "Deklarera AGI till Skatteverket",
    TaxPeriod_Step3Title: "Betala skatterna till Skatteverket",
    TaxPeriod_Step4Title: "Bokför skatterna",
    TaxPeriod_TaxPaymentTitle: "Skattebetalning - löner {0}",
    TaxPeriod_UnpaidPayslips: "Det finns lönekörningar där betalningen inte är bokförd. Du kan fortsätta med deklarationen nu men kom ihåg att betala dessa. Se också till att bokföra betalningen när den är klar.",
    TaxPeriod_V1RecordingInfo: "Denna månadens skatter är bokförda på det gamla sättet",
    TaxPeriod_V1RecordingInfo_Long: "Bokio brukade bokföra skattedragningen tillsammans med betalningen. Detta kan göra det svårare att stämma av ditt skattekonto. Om du vill dela upp dem för denna månaden kan du:\n1) Ångra bokföringen här\n2) Bokför betalningen manuellt eller via din bank-sync\n3) Bokför detta steg igen",
    TaxPeriods_CurrentStep: "Nuvarande steg",
    TaxPeriods_NotStarted: "Ej påbörjad",
    TaxPeriods_WaitingForTaxAccount: "Väntar på Skattekontosynk",
    TaxSettings: "Skatt",
    TaxSettingsDescription: "Om du inte vet vad du ska fylla i, ta hjälp av Skatteverkets tabeller som du hittar ",
    TaxTab_NotRecorded: "Ej bokförd",
    TaxTab_PayrollStatus: "Lönekörningstatus",
    TaxYear: "",
    TestCompanyQuickPayrollNotice_Completed_EmployeesCTA: "Gå till lönekörningar",
    TestCompanyQuickPayrollNotice_Completed_Text: "Vi har nu lagt till en anställd och satt upp en lönekörning som som du kan redigera och bokföra. Du hittar testlönen under aktiva lönekörningar.",
    TestCompanyQuickPayrollNotice_Completed_Title: "Testlön skapad",
    TestCompanyQuickPayrollNotice_CTA: "Skapa en testlön",
    TestCompanyQuickPayrollNotice_Error: "Misslyckades att skapa en testlön",
    TestCompanyQuickPayrollNotice_MissingCompanyInfoWarning: "Saknar nödvändig företagsinformation",
    TestCompanyQuickPayrollNotice_Text: "Om du på ett snabbt sätt vill testa hur löner fungerar i Bokio så kan du antingen testa att själv skapa en anställd och ett lönebesked eller generera ett testlönebesked som skapas automatiskt. När ett lönebesked genereras så genereras även en anställd för detta testföretag.",
    TestCompanyQuickPayrollNotice_Title: "Utforska lönehanteringen enkelt med ett klick",
    ThereAreNoAbsencesToHandle: "Det finns ingen frånvaro att hantera just nu",
    TheyHaveOtherJobOrPension: "They have another job or pension",
    ThisIsCurrentlyOnlyJob: "This is currently their only job",
    ThisIsFirstJobSinceTaxYearStart: "This is their first job since the start of the tax year (April 6th)",
    TITLE: "Benämning",
    Total: "Totalt",
    TOTAL_THIS_YEAR: "Totalt detta år",
    TotalOfCurrentPrepaidDebt: "Förskottssemesterskuld, belopp",
    TrackedVacationBalances: "Automatiska semesterbalanser",
    TrackedVacationBalancesDescription: "Här visas dina anställdas automatiska semesterbalanser. Behöver du ändra några balanser manuellt kan du göra det här. Alla manuella ändringar skapar ett bokföringsverifikat som du måste bokföra.",
    TrackedVacationBusinessDaysInfo: "Välj datum för att spara. Antal arbetsdagar beräknas automatiskt från det valda datumintervallet. Antalet dagar kan justeras manuellt om detta inte är korrekt.",
    TurnOnTracking: "Slå på automatiska semesterbalanser",
    TurnOnTrackingDescription: "Så fort balanserna ovan stämmer, är det dags att slå på automatiska semesterbalanser för denna anställda.\nOm du inte har koll på alla värden just nu, kan du fortfarande spara dem och fortsätta senare, så länge du inte slår på automatiska balanser.",
    TurnOnTrackingInfo: "Så fort du har slagit på automatiska balanser för en anställd, kan man inte stänga av dem, men du kan alltid ändra balanserna.",
    TYPE: "Typ",
    UndoBookkeepingForSalaries: "Ångra bokföring av löner",
    UndoManuallyRecordedConfirmationText: "Detta ångrar att du markerat att denna betalning har blivit manuellt bokförd, och kommer flytta tillbaka detta steg till att vara aktivt. Det kommer INTE ångra någon manuell bokföring som du utfört.",
    UndoRecordingOfSalaryMessage: "You’re about to undo the recording of salaries. If you need to edit the salaries, this will require you to resubmit the FPS in step 2.",
    UndoSalaryBookkeepingModalNotice: "Om du behöver göra en extra betalning efter du har ändrat lönerna behöver du troligen bokföra det manuellt.",
    UNIT: "Enhet",
    Unit_Day_P: "Dagar",
    Unit_Day_S: "Dag",
    Unit_Hour_P: "Timmar",
    Unit_Hour_S: "Timme",
    Unit_Meal_P: "Mål",
    Unit_Meal_S: "Mål",
    Unit_Month_P: "Månader",
    Unit_Month_S: "Månad",
    Unit_Pcs_P: "st",
    Unit_Pcs_S: "st",
    UNLOCK_MONTH: "Lås upp månad",
    UNLOCK_PAY_PERIOD: "Lås upp löneperiod",
    UNLOCK_PERIOD: "Lås upp",
    UNLOCK_PERIOD_DOWNLOAD_PDF_TEXT: "Ladda ner alla lönespecifikationer när jag låser upp perioden.",
    UNLOCK_PERIOD_DOWNLOAD_PDF_TITLE: "### Ladda ned PDF för lönespecifikationer",
    UNLOCK_PERIOD_NO_PAYSLIPS_TEXT: "Du har inga lönespecifikationer som behöver laddas ner",
    UNLOCK_PERIOD_WARNING: "### Observera!\nNär man låser upp en löneperiod kommer Bokio lägga till de senaste reglerna för hur vi räknar ut skatter och löner för alla lönespecifikationer i perioden. Detta betyder att vissa lönespecifikationer kan beräknas om och ändra värden.\n\nDet är alltså bra att ladda ned alla lönespecifikationer som PDF och behålla som referens, för att se om något har förändrats när man låst upp en löneperiod.",
    UNLOCK_PERIOD_WARNING_LOCKNOTALLOWED: "### Varning!\nDu kommer inte kunna låsa denna lönekörning igen utan att uppgradera din prisplan.\n",
    UnlockPayrollWarning: "You’re about to unlock this payroll. If you do this you are required to resubmit the FPS in step 2, which may result in a late submission.",
    UnmarkAsFinalPayment: "Unmark as final payment",
    UnpaidBalanceInfo: "Om bolaget inte har förskottssemester första året, är det vanligt med obetald semester. Fyll i kvarvarande obetalda semesterdagar här.",
    UnpaidVacation: "Obetald semester",
    UnrecordedTransactions: "Ej bokförda transaktioner",
    UnrecordedTransactionsDescription: "Varje gång du ändrar semesterbalanser manuellt för en anställd skapas transaktioner. Dessa kan du bokföra här. Vi rekommenderar att du gör ändringar för alla anställda vid samma tillfälle. Detta gör att vi kan skapa ett verifikat för alla transaktioner, istället för flera verifikat. Verifikaten kan inte tas bort efter att de skapats, bokföringen kan endast ångras genom att ändra tillbaka semesterbalansen.",
    UpdateOverridesWarningDescription: "Se till att du uppdaterar dina egna uträkningar så de passar de nya ändringarna.",
    VACATION_DAYS: "Semesterdagar",
    VacationBalance: "Semestersaldo",
    VacationBalanceTracking: "Automatisk semesterbalans",
    VacationDate: "Datum",
    VacationDaysYearly: "Semesterdagar per år",
    VacationDebt: "Semesterskuld",
    VacationDebtDescription: "Om den anställda har förskottssemesterskuld, fyll i den här. Bokio sätter automatiskt datumet för när skulden avskrivs till 5 år efter anställningsdatum i enlighet med svensk lag.",
    VacationDebtReport_Filter_Date: "Skuld vid",
    VacationDebtReportFooter_AccrualPeriod: "Intjäningsperiod",
    VacationDebtReportFooter_AccrualYearPreviousYear: "Intjänandeår: Föregående år",
    VacationDebtReportFooter_SocialFeesForBorn: "Arbetsgivaravgift för födda",
    VacationDebtReportFooter_UsagePeriod: "Uttagsperiod",
    VacationDebtReportFooter_VacationDebt: "Semesterskuld",
    VacationDebtReportFooter_VacationDebtIncludingSocialFees: "Semesterskuld inklusive arbetsgivaravgift",
    VacationDebtReportHeader_PrintDate: "Utskriftsdatum",
    VacationDebtReportRowHeader_AccruedThisYearDays: "Intjänat i år dagar",
    VacationDebtReportRowHeader_Amount: "Belopp",
    VacationDebtReportRowHeader_Claim: "Fordran",
    VacationDebtReportRowHeader_PaidDays: "Betalda dagar",
    VacationDebtReportRowHeader_PrepaidDays: "Förskottsdagar",
    VacationDebtReportRowHeader_SavedDays: "Sparade dagar",
    VacationDebtReportRowHeader_UnpaidCompensation: "Ej utbetald semesterersätting",
    VacationDetails_AccuralTransactions_Title: "Transaktioner för intjänande",
    VacationDetails_Description_Header: "Beskrivning",
    VacationDetails_From_Header: "Från",
    VacationDetails_FromYearEnd_Description: "Från semesterårsslut",
    VacationDetails_OnlyRecorded_CheckboxLabel: "Inkludera endast bokfört?",
    VacationDetails_To_Header: "Till",
    VacationDetails_TransactionDate_Header: "Datum",
    VacationDetails_Transactions_Title: "Transaktioner",
    VacationList_Accrued_ShortHeader: "Intjänat",
    VacationList_Compensation_ShortHeader: "Ersättning",
    VacationList_Paid_ShortHeader: "Betalda",
    VacationList_Prepaid_ShortHeader: "Förskott",
    VacationList_Saved_ShortHeader: "Sparade",
    VacationList_Unpaid_ShortHeader: "Obetald",
    VacationReplacementRate: "Semesterersättning",
    VacationSettings: "Semester",
    VacationTermExplanationAccrued: "Intjänade semesterdagar inför nästkommande semesterår",
    VacationTermExplanationCompensation: "Kompensationsdagar (kompdagar), vanligtvis som en andel av provisionsarbete eller från timanställning",
    VacationTermExplanationPaid: "Tillgängliga betalda semesterdagar",
    VacationTermExplanationPrepaid: "Betalda semesterdagar som delats ut i förskott till den anställde; skulden skrivs vanligtvis av efter 5 års anställning",
    VacationTermExplanationSaved: "Oanvända betalda semesterdagar från föregående intjänadeår",
    VacationTermExplanationUnpaid: "Obetalda semesterdagar som vanligtvis används för att fylla ut det första semesteråret för en anställd som inte har tjänat in tillräckligt många betalda semesterdagar ännu",
    VacationTracking_Change_VacationYearDate: "Ändra datum",
    VacationTracking_Change_VacationYearDate_Warning: "Ändrar du startdatum för ditt semesterår finns risken att semesterbalanserna kommer vara felaktiga när året tar slut. Du kan behöva justera dessa när du semesterårsavslutet.",
    VacationTracking_ChangeVacationYearStartDate_Current: "Ditt semesterår startar {0} {1}",
    VacationTracking_Disable_Text: "Stäng av",
    VacationTracking_Disable_Title: "Stäng av automatisk semesterbalans",
    VacationTracking_Disable_Warning: "Stänger du av den automatiska semesterbalansen finns det en risk att du kommer behöva göra manuella justeringar. All bokföring av semesterskuld och intjäning behöver göras om.\n\nÄr du säker att du vill stänga av automatisk semesterbalans?",
    VacationTracking_Onboarding_Notice: "Den här funktionen är till för företag med anställda. Om du är ensam i ditt företag och inte tar ut mycket, eller någon, semester så rekommenderar vi att du fortsätter använda den manuella semesterbalans vi tillhandahåller.",
    VacationTracking_Onboarding_Setup: "Aktivera automatisk semesterbalans",
    VacationTracking_Onboarding_Text: "Håll koll på dina anställdas semesterdagar, intjäning och mer! Genom att slå på automatisk semesterbalans kommer vi automatiskt bokföra semesterskulder och hålla koll på dina anställdas semesterbalans.",
    VacationTracking_Onboarding_Title: "Slå på automatisk semesterbalans",
    VacationTrackingFeedbackText: "Du använder automatiska semesterbalanser, vilket är en ny funktion. Vi vill gärna ha din feedback för att göra den ännu bättre!",
    VacationTrackingForm_PaidAccrual_HelpText: "Intjänade betalda dagar hanteras automatiskt och borde inte kräva manuella justeringar. Om det behövs kan du ändra det här. Notera att det behöver vara ett exakt tal, se mer ",
    VacationTransactions: "Semestertransaktioner",
    VacationTransactionsModal_Transactions_Heading: "Du bokför semestertransaktioner för {0} anställd(a)",
    VacationYearEnd_BeforeYouStart_ConfirmToStartVacationYearEnd: "Jag förstår ovanstående och är redo att börja semesterårsslut.",
    VacationYearEnd_BeforeYouStart_Heading: "Innan du börjar",
    VacationYearEnd_BeforeYouStart_Text: "### Vad är semesterårsslut?\n\nUnder det senaste semesteråret, {0} till {1}, har dina anställda tjänat in och använt semester. Under varje månad du har gjort lönekörningar så har vi bokfört intjänad semester för det kommande året (om de har månadslön) och hur många dagar de har använt under det senaste semesteråret.\n\nFör att bokföra allt och gå in i det kommande året med rätta balanser så ska vi flytta över dem till nästa år. Innan vi sätter igång så är det viktigt att du har gjort ett par saker:\n\n### Vad behöver jag göra?\n\n**1. Se till att du har skapat och bokfört alla lönekörningar fram till {2}.**\n\nAlla intjänade dagar bokförs samma månad som de händer. Exempel: John jobbar heltid i Mars. På Mars lönekörning har vi registrerat hans intjäning för nästa år, medans all frånvaro registreras månaden efter det hände.Exempel: Anna jobbar heltid i Mars och tog fyra dagar semester, vilket läggs till på Aprils lönekörning. Det betyder att du behöver bokföra April-lönen innan du går vidare.\n\n**2. När du har startat semesterårsslutet så ska du INTE låsa upp gamla lönekörningar.**\n\nEftersom balanserna är knutna till lönespecifikationerna så kommer en upplåsning leda till att balanserna uppdateras igen, vilket leder till att de blir fel.",
    VacationYearEnd_ConfirmAndContinue: "Bekräfta och fortsätt",
    VacationYearEnd_EBD_CompensationHourly_Description: "För timanställda håller vi koll på allt som ligger bas för semesterersättning och du inte betalt ut ännu, och sammanställer det här.",
    VacationYearEnd_EBD_CompensationHourly_Percentage_Text: "Semesterersättning i procent (satt på kontrakt)",
    VacationYearEnd_EBD_CompensationHourly_Salary_Text: "Lön",
    VacationYearEnd_EBD_CompensationMonthly_Commission: "Provision",
    VacationYearEnd_EBD_CompensationMonthly_Description: "Om du flyttade några dagar från sparade dagar i förra steget, så flyttas ersättningen hit. Annars är det ovanligt att ha några balanser här för månadsanställda, då detta fält främst är till för anställda som jobbar som timanställda eller provisionsavlönade.",
    VacationYearEnd_EBD_CompensationMonthly_ManuallyAdded: "Manuellt tillagt",
    VacationYearEnd_EBD_CompensationMonthly_Saved_Text: "Flyttat från sparade dagar",
    VacationYearEnd_EBD_CompensationMonthly_Title: "Semesterersättning",
    VacationYearEnd_EBD_PrepaidDays_Accrued_Text: "Intjänade dagar för kommande period",
    VacationYearEnd_EBD_PrepaidDays_DaysLeft_Text: "Förskottsdagar kvar (från föregående period)",
    VacationYearEnd_EBD_PrepaidDays_DaysUsed_Text: "Använda förskottsdagar (under föregående period)",
    VacationYearEnd_EBD_PrepaidDays_Description: "Om dina anställda har förskottssemester och detta är deras första semesterår, flyttar vi över kvarvarande förskottsdagar för att fylla upp så de får alla semesterdagar (förskott+ intjänade) de är berättigade år ",
    VacationYearEnd_EBD_PrepaidDays_Example: "Exempel:\nAnna har rätt till 25 semesterdagar per år. Under hennes första år hade hon 25 förskottsdagar.\nHon började mitt i semesteråret, så hon tjänade in 13 semesterdagar för det kommande året.\nHon använde 10 av sina förskottssemesterdagar under det första året och börjar därför det kommande året med:\n\n13 betalda dagar\n12 förbetalda dagar (de 3 återstående dagarna försvinner)\nTotalt: 25 dagar",
    VacationYearEnd_EBD_PrepaidDays_Title: "Förskottsdagar som kan användas nästa period",
    VacationYearEnd_EBD_PrepaidDays_Vacation_Text: "Semesterdagar per år (från kontraktet)",
    VacationYearEnd_EBD_Workload_ContractPeriod_Text: "Kontrakt från {0} till {1}",
    VacationYearEnd_EBD_Workload_Description: "Om den anställda har haft olika arbetsbelastning under året är värdet för en semesterdag lägre i det kommande året. Vi räknar ut den genomsnittliga arbetsbelastningen för föregående år och sparar värdet på semesterdagarna baserat på den.",
    VacationYearEnd_EBD_WorkLoad_Example: "Exempel:\nAnna jobbade 80% i 3 månader förra semesteråret. Resterande 9 månade jobbade hon 100%. Den genomsnittliga arbetsbelastningen blir 95% (3 månader x 0,8) + (9 månader x 1,0) / 12.\n\nEn semesterdag in nästa år räknas ut så här:\nMånadslön: 25 000 kr\nFrånvaro: 1 dag  -1 150 Kr¹\nSemesterlön: 1 dag 1092,50 Kr²\nSemesterertillägg: 1st 190 Kr³\nTotalt: 25 132,50\n\n¹ Absence = 25 000 x 4,6%\n² Semesterlön = 25 000 x 95% x 4,6% (daglön i procent)\n³ Semestertillägg = 25 000 x 95% x 0,43% (semestertillägg i procent)",
    VacationYearEnd_EBD_Workload_Title: "Arbetsbelastning i föregående år",
    VacationYearEnd_Employee_Intro: "I det här steget ser du till att dina anställdas semesterbalanser är korrekta. Du kommer sedan flytta över semesterbalanserna till ett nytt semesterår.\n\nDe nya balanserna blir inte aktiva förrän du är klar med alla anställda och har bokfört förändringarna i steg 3.",
    VacationYearEnd_Employee_MultipleContracts: "Flera kontrakt",
    VacationYearEnd_Employee_NoContracts: "Inga kontrakt",
    VacationYearEnd_EmployeeConfirmation_Summary: "Balanserna för {0} kommer att se ut så här",
    VacationYearEnd_EmployeeDetailScene_BalanceSummary: "Balansöversikt",
    VacationYearEnd_EmployeeDetailScene_CheckAndEditBalances: "Kontrollera och redigera balanser",
    VacationYearEnd_EmployeeStatus_Done: "Klar",
    VacationYearEnd_EmployeeStatus_InProgress: "Pågående",
    VacationYearEnd_EmployeeStatus_NotStarted: "Ej startat",
    VacationYearEnd_Finished_GoodJob: "Bra jobbat!",
    VacationYearEnd_Finished_Text: "Alla dina anställdas balanser är nu aktuella.\nDu har även en bättre översikt över ditt företags semesterskuld!",
    VacationYearEnd_MoveOverBalances: "Flytta över semesterbalanser ({0}/{1} anställda klara)",
    VacationYearEnd_MoveOverBalances_NotReady: "Flytta över semesterbalanser",
    VacationYearEnd_RecordTransactions_Description: "I det här steget kommer vi skapa två stycken separata verifikat. Det första verifikatet nollar din nuvarande semesterskuld fram till den senaste dagen i det föregående semesteråret. Det andra verifikatet kommer innehålla din nuvarande semesterskuld från första dagen i det nya semesteråret. Det här kommer leda till att din semesterskuld är uppdaterad och prydlig.",
    VacationYearEnd_RecordTransactions_Example: "Så här kommer verifikaten se ut",
    VacationYearEnd_Step_NotReady: "Detta steg blir tillgängligt när du gjort klart föregående steg",
    VacationYearEnd_Title: "Semesterårsslut",
    VacationYearEndBalanceEdit_Compensation_Description: "Semesterersättning",
    VacationYearEndBalanceEdit_Compensation_Title: "Ändra semesterersättning",
    VacationYearEndBalanceEdit_Day: "dag",
    VacationYearEndBalanceEdit_Days: "dagar",
    VacationYearEndBalanceEdit_Paid_Description: "Intjänade betalda semesterdagar för nästa år",
    VacationYearEndBalanceEdit_Paid_Title: "Ändra betalda semesterdagar för nästa år",
    VacationYearEndBalanceEdit_Prepaid_Description: "Förskottssemesterdagar för nästa år",
    VacationYearEndBalanceEdit_Prepaid_Title: "Ändra förskottssemesterdagar",
    VacationYearEndBalanceEdit_RestoreToDefault: "Återställ till {0}",
    VacationYearEndBalanceEdit_Saved_Description: "Oanvända betalda dagar från förra året som flyttas till sparade dagar",
    VacationYearEndBalanceEdit_Saved_Example: "En betald semesterdag för {0} är värd {1} kr. Om du tar bort en (eller fler) av de sparade dagarna här, flytttar vi automatiskt det värdet till semesterersättning, så du kan betala ut det till den anställda på nästa lönespec.",
    VacationYearEndBalanceEdit_Saved_Title: "Ändra sparade semesterdagar för nästa år",
    VacationYearEndBalanceEdit_Unpaid_Description: "Obetalda semesterdagar för nästa år",
    VacationYearEndBalanceEdit_Unpaid_Title: "Ändra obetalda semesterdagar för nästa år",
    VacationYearEndBalanceEdit_Workload_Description: "Arbetsbelastning föregående år",
    VacationYearEndBalanceEdit_Workload_Title: "Ändra arbetsbelastning",
    VacationYearEndBalanceHistory_Compensation_Description: "Här är en lista av saker som påverkade din semesterersättning under semesteråret.",
    VacationYearEndBalanceHistory_Compensation_Title: "Historik - Semesterersättning",
    VacationYearEndBalanceHistory_Paid_Description: "Här är en lista av saker som påverkade din intjäning av semesterdagar under semesteråret.",
    VacationYearEndBalanceHistory_Paid_Title: "Historik - Intjänade dagar",
    VacationYearEndBalanceHistory_Quantity: "Antal",
    VacationYearEndBalanceHistory_Saved_Description: "Här är en lista av saker som påverkade dina sparade semesterdagar under semesteråret.",
    VacationYearEndBalanceHistory_Saved_Title: "Historik - Sparade dagar",
    VacationYearEndBanner_Tooltip: "Du behöver bokföra alla semestertransaktioner för att kunna fortsätta",
    VacationYearEndBannerDescription: "Ditt semesterår tar slut {0}. Det betyder att det är dags att se över dina anställdas semesterbalanser för kommande semesterår. Vi har satt upp ett flöde som hjälper dig att flytta över balanserna till det nya året, samt bokföra semesterskulden korrekt.",
    VacationYearEndBannerEmployeeDone: "{0}/{1} anställda klara",
    VacationYearEndBannerInfo: "Gör detta innan du förbereder lönerna. Om du gör det senare kommer semesterbalanserna på dina anställdas lönespecifikationer att vara felaktiga, och du riskerar att använda fel dagar i fel semesterperiod.",
    VacationYearEndBannerInSalary: "Det är dags att göra semesterårsslut! Flytta över dina anställdas balanser till det nya året nu.",
    VacationYearEndBannerTitle: "Det är dags att avsluta semesteråret!",
    VacationYearEndEBD_Paid_Accrued_Text: "Intjänade dagar under året (avrundat uppåt)",
    VacationYearEndEBD_Paid_Title: "Betalda dagar att använda under nästa semesterår",
    VacationYearEndEBD_Saved_Description: "Vi flyttar alla oanvända betalda dagar till sparade dagar som standard. Om du istället vill att några/alla dagar ska betalas ut till den anställde, ändra detta värde så flyttar vi automatiskt över resterande dagar till \"Semesterersättning\" när du sparar det.",
    VacationYearEndEBD_Saved_Title: "Oanvända dagar från förra året flyttade till sparade dagar",
    VacationYearEndEBD_Unpaid_Description: "Om ditt företag har en gräns på obetalda semesterdagar kan du lägga till den här. Detta är vanligt om ni inte använder förbetalda semesterdagar.",
    VacationYearEndEBD_Unpaid_Title: "Obetalda dagar att använda under nästa semesterår",
    VacationYearEndEBED_Adjusted_Status: "Manuellt justerad",
    VacationYearEndEBED_Mobile_Adjusted_Status: "Justerad",
    VacationYearEndEBED_WhatWeBaseOur_Header: "Var våra värden kommer ifrån",
    VacationYearEndEmployeeBalanceDetails_Heading: "Gå igenom balanser",
    VacationYearEndEmployeeBalanceDetails_Text: "Granska alla värden och försäkra dig om att de stämmer. Du kan ändra på de värden för nästa semesterår som du inte anser stämmer. Om du vill veta mer om var värdena kommer ifrån så kan du klicka på ett värde för att visa mer information.",
    VacationYearEndEmployeeBalanceOverview_Heading: "Hur vi flyttar över balanser",
    VacationYearEndEmployeeBalanceOverview_LastYear: "Förra året ({0} till {1})",
    VacationYearEndEmployeeBalanceOverview_NextYear: "Nya balanser ({0} till {1})",
    VacationYearEndEmployeeBalanceOverview_Text: "Nedan kan du se hur vi flyttar över balanser. Till vänster kan du se hur den anställda har utnyttjat sin semester under semesteråret och hur många dagar de har kvar. Till höger kan du se hur dessa värden vanligtvis flyttas över.",
    VacationYearEndEmployeeBalanceTable_Accrued: "Intjänade dagar (avrundat uppåt)",
    VacationYearEndEmployeeBalanceTable_Accrued_Tablet: "Intjänade (avrundat uppåt)",
    VacationYearEndEmployeeBalanceTable_Balances: "Balanser",
    VacationYearEndEmployeeBalanceTable_Compensation: "Semesterersättning",
    VacationYearEndEmployeeBalanceTable_Debt: "Skuld",
    VacationYearEndEmployeeBalanceTable_Paid: "Betalda dagar kvar",
    VacationYearEndEmployeeBalanceTable_PrepaidDebt: "Förskottsskuld, belopp",
    VacationYearEndEmployeeBalanceTable_PrepaidLeft: "Förskottsdagar kvar",
    VacationYearEndEmployeeBalanceTable_PrepaidUsed: "Använda förskottsdagar",
    VacationYearEndEmployeeBalanceTable_Saved: "Sparade dagar kvar",
    VacationYearEndEmployeeBalanceTable_Unpaid: "Obetalda dagar kvar",
    VacationYearEndEmployeeBalanceTablesMobile_Accrued: "Intjänade",
    VacationYearEndEmployeeBalanceTablesMobile_Compensation: "Ersättning",
    VacationYearEndEmployeeBalanceTablesMobile_Last: "Förra",
    VacationYearEndEmployeeBalanceTablesMobile_New: "Nya",
    VacationYearEndEmployeeBalanceTablesMobile_Paid: "Betalda",
    VacationYearEndEmployeeBalanceTablesMobile_PrepaidDebt: "Skuld",
    VacationYearEndEmployeeBalanceTablesMobile_PrepaidLeft: "Kvar",
    VacationYearEndEmployeeBalanceTablesMobile_PrepaidSectionHeader: "Förskott",
    VacationYearEndEmployeeBalanceTablesMobile_PrepaidUsed: "Använda",
    VacationYearEndEmployeeBalanceTablesMobile_Saved: "Sparade",
    VacationYearEndEmployeeBalanceTablesMobile_Unpaid: "Obetalda",
    VacationYearEndRating_Feedback_Description: "Har du någon feedback som kan hjälpa oss att förbättra din semesteravräkningsupplevelse?",
    VacationYearEndRating_Rate_Title: "Hur var din upplevelse att göra semesteravräkning?",
    ValidFrom: "Giltigt från",
    ValidTo: "Giltigt till",
    ValuesNeedToBeRecalculatedAndSetDaysFieldsToZero: "Värdena ovan måste konverteras till semesterersättning. Du måste också sätta alla fält till 0 för att gå vidare.",
    VaxaSupport: "VÄXA-stöd",
    VaxaSupportDescription: "Om du använder VÄXA-stöd, se till att du ändrar denna kontraktsinställning så fort din stödtid löper ut. Läs mer om VÄXA-stödsbehörighet hos [Skatteverket](https://www.skatteverket.se/foretagochorganisationer/arbetsgivare/arbetsgivaravgifterochskatteavdrag/vaxastodtillfalligtsanktarbetsgivaravgift.4.361dc8c15312eff6fd37447.html?q=växa+stöd)",
    VaxaSupportEligible: "Berättigad till VÄXA-stöd",
    verification_preview_text: "Så här kommer ditt verifikat att se ut.",
    VerificationRecorded: "Verifikation bokförd",
    ViewPayslip: "Visa lönespec",
    Week1Month1: "Week 1/Month 1 (Non cumulative)",
    Week1Month1Tooltip: "Ticking this box results in non-cumulative tax calculations. This should be ticked if the P45 for the employee shows an entry in the Week 1/Month 1 box. Or, if they are a new employee without another job, but they have had a job since beginning of the tax year. Or, if they are an existing employee that has a tax code with the suffix M1.",
    WeeklySalary: "Veckolön",
    WhatsGoodToKnow: "Bra att ha till hands:",
    WHEREOF: "varav",
    Workload: "Arbetsbelastning",
    WorkloadDescription: "Vid deltidsanställning, ange värden för kompensation, arbetsvecka, o.s.v. som om den anställde arbetar heltid, så räknar vi ut resten.",
    Workweek: "Arbetsvecka",
    Year: "År",
    Yearly_VacationDays_Warning: "Enligt svensk lag har varje anställd rätt till 25 semesterdagar per år. Vi tillåter dig att spara färre dagar per år men det är upp till dig att ha koll på vad som gäller.",
    YearlyDays: "Semesterdagar per år",
    YES_DELETE: "Ja, radera!",
    YesResubmit: "Yes, resubmit",
    YOU_ARE_ABOUT_TO_EMAIL_PAYSLIP: "Du håller på att skicka ut lönespecarna för denna månaden",
    YouAreAboutToUndoBookkeepingForTaxes: "Du håller på att ångra bokföringen av skatter.",
    YouAreAboutToUndoBookkeepingForTheSalaryPayment: "Du håller på att ångra bokföringen av lönebetalningen.",
    YouAreOnlyAllowedToPayOutUpToTheAvailableBalance: "Du får bara betala ut upp till den tillgängliga balansen",
    YouCantInputMoreDaysThanYouveSelected: "Du kan inte ange fler dagar än du valt",
    YouNeedToInputMoreThan0Days: "Du måste ange fler än 0 dagar",
    ZIP_CODE: "Postnummer",
  };
}